/**
 * @file globals.js
 * @author Pavel Shabardin (<bigbn@mail.ru>) Monday, 8th July 2019 10:54:40 am
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @private
 * @flow
 */
/* global HTMLElement */
import type { UnsetType } from 'skat-js/types'
import type { CancellablePromiseWrapper, Dimensions, TestAttributes, CellTestAttributes, asyncVoid } from 'web-panel/types'

import { genUnset } from 'skat-js/constants'
import i18n, { __ as translate, __mf as translateFormat } from 'i18n-for-browser'
import set from 'set-value'
import isString from 'lodash/isString'
import moize from 'moize'
import { OperationCancelled } from 'web-panel-essentials/errors'
import { ru, hy, az, cs, ka, es, da, uz, fr, kk, be } from 'date-fns/locale'
import _formatDistanceToNow from 'date-fns/formatDistanceToNow'

const dateLocales = { ru, hy, az, cs, ka, es, da, uz, fr, kk, be }

i18n.configure({
  ...global.I18N || { locales: { en: {} } },
  unknownPhraseListener: (locales, phrase, value) => {
    // console.warn('UNTRANSLATED_STRING', phrase)
  }
})

/**
* Notification type
* @enum {SUCCESS | ERROR | WARNING | DEFAULT}
*/
const NOTIFICATION = Object.freeze({
  /* Some success operation has finished */
  SUCCESS: 'success',
  /* An error has occurred */
  ERROR: 'error',
  /* Warning */
  WARNING: 'warning',
  /* Something blacklisted */
  BLACK: 'black',
  /* Common message, not very inviting */
  DEFAULT: 'default'
})

const NOTIFICATION_GROUP = Object.freeze({
  DEFAULT: 'default'
})

/**
* Button type
* @enum {POSITIVE | NEGATIVE | WARN | DEFAULT}
*/
const BUTTON = Object.freeze({
  POSITIVE: 'success',
  NEGATIVE: 'error',
  WARN: 'warning',
  DEFAULT: 'default'
})

function getCurrentDateTimeLocaleCatalog () : * {
  return dateLocales[getCurrentLocale()]
}

function formatDistanceToNow (date: Date, options: {}) : string {
  return _formatDistanceToNow(date, { ...options, locale: getCurrentDateTimeLocaleCatalog() })
}

/**
 * Get translation string for specified key
 * @param key translation string key
 * @example
 * console.log(__('CUSTOM_WINDOW_TITLE'))
 * // Prints: Произвольный заголовок окна
 */
const __ = (key: string | number) : string => translate(key)

/**
 * Get translation string for specified key with variable substitutions
 * @param key translation string key
 * @example
 * // Original translation: { "TOTAL": "Всего: {amount}" }
 * console.log(__mf('TOTAL'), {amount: 10})
 * // Prints: Всего: 10
 */
const __mf = (key: string | number, substitutions: {[string]: string| number}) : string => translateFormat(key, substitutions)

/**
 * Run function with timeout using requestIdleCallback(to prevent heavy UI-overload) if possible
 * Uses setTimeout if requestIdleCallback is not available (some iOS devices for example)
 * @private
 * @param {*} callback function to be called
 * @param {*} timeout in ms
 */
function callLater (callback: () => asyncVoid, timeout: number) : () => void {
  if (global.requestIdleCallback) {
    const callbackId = global.requestIdleCallback(callback, { timeout })
    return () => global.cancelIdleCallback(callbackId)
  } else {
    const timerId = setTimeout(callback, 100)
    return () => clearTimeout(timerId)
  }
}

/**
 * Returns the ISO code of currently used locale
 */
function getCurrentLocale () : string {
  return global.I18N.defaultLocale || 'en'
}

/**
 * Determines if current environment is `mobile`.
 * Should be fast enough to be able to use it with React `render` method
 */
const isMobileMode : () => boolean = moize(() : boolean => {
  return Boolean(window.matchMedia('only screen and (max-width: 479px)').matches)
})

/**
 * Determines if current environment is `testing`.
 * `testing` mode can be enabled by appending `#testing` hash to url path.
 * `testing` mode adds special unique `data-test` attributes to output layout
 * to help to identificate elements by name.
 * @private
 */
const isTestingMode : () => boolean = moize(() : boolean => {
  const { hash } : { hash: string } = window.location
  return hash === '#testing'
})

/**
 * Returns an object with 'data-test' attribute when `name` is specified
 * and current mode is `testing`, otherwise returns `null`.
 * This will prevent resulting `html` render pollution with unnecessary props when running in normal mode.
 * @see {@link isTestingMode} for more details
 * @private
 * @example
 * <Button key={key} {...genTestName('my-bytton', 'my-panel')} />
 */
const genTestName : (name: ?string, parent?: string) => TestAttributes = moize((name: string, parent?: string) => {
  if (!name) return null
  if (isTestingMode()) {
    let attributes = { 'data-test': name }
    if (parent) attributes = { ...attributes, 'data-parent': parent }
    return attributes
  }
  return null
})

/**
 * Returns an object that can be spread as set of `React` props to set
 * identity marks for an every grid cell
 * @private
 * @see {@link genTestName} for examples and usage principles
 */
const genCellTestMarks : (row: number, col:number) => CellTestAttributes = moize((row: number, col:number) => {
  if (isTestingMode()) {
    const attributes = { 'data-test-row': row, 'data-test-col': col }
    return attributes
  }
  return null
})

/**
 * Syntactic sugar to use setTimeout with async/await functions
 * @param ms timeout in milliseconds (thousandths of a second)
 * @example
 * async function doSomeAsync () {
 *  console.log('Pending...')
 *  await delay(1000) // one second delay
 *  console.log('Finished')
 * }
 */
function delay (ms: number) : Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * Determines approximate text width with given size and font
 * @private
 * @example
 * const FONT_DEFINITION = 'Roboto 12px'
 * const width = getTextWidth('Some text', FONT_DEFINITION)
 */
function getTextWidth (text: string, font: string) : number {
  // re-use canvas object for better performance
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
  const context = canvas.getContext('2d')
  context.font = font
  const metrics = context.measureText(text)
  return metrics.width
}

/**
 * Returns current viewport width and height
 */
function getViewportSize () : Dimensions {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  }
}

/**
 * @private
 */
function getRemUnitSize () : number {
  const root = document.documentElement
  if (root) return parseInt(root.style.fontSize || 16)
  return 16
}

/**
 * Helper function to get text representation of any Error object
 * @param {*} error JavaScript Error object
 * @example
 * try {
 *  throw new Error('Error is here!')
 * } catch(e) {
 *  console.log(extractErrorMessage(e))
 * }
 * // Prints : Error is here!
 */
const extractErrorMessage = (error: Object) : string => {
  if (isString(error)) return error

  if (error.body) {
    if (isString(error.body)) return error.body
    else return error.body.message
  }

  return error.message
}

const UNSET: UnsetType = genUnset(__)
const UNSET_FIELD = {
  value: '',
  displayValue: ''
}

/**
 * @private
 * Helper function to check if localStorage is available and ready to use
 */
const localStorageAvailable : () => boolean = moize(() => {
  const storage = window.localStorage
  try {
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return e instanceof window.DOMException && (
    // everything except Firefox
      e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
          // acknowledge QuotaExceededError only if there's something already stored
          storage && storage.length !== 0
  }
})

/**
 * Attach hostname and port to endpoint path
 * turns `/api` into `http://localhost:1337/api`
 * @param {*} path endpoint path
 * @returns {sting} full url
 */
function getFullURL (path: string) : string {
  const url = new URL(path, global.document.baseURI)
  return url.toString()
}

function getCurrentActiveElement () : ?HTMLElement {
  return document.activeElement
}

function makeCancelable<T> (promise: Promise<T>) : CancellablePromiseWrapper<T> {
  let hasCanceled_ = false
  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then((val) => hasCanceled_ ? reject(new OperationCancelled(__('CANCELLED'))) : resolve(val))
    promise.catch((error) => hasCanceled_ ? reject(new OperationCancelled(__('CANCELLED'))) : reject(error))
  })

  return {
    promise: wrappedPromise,
    cancel () {
      hasCanceled_ = true
    }
  }
}

/**
 * The assert() method writes an error message to the console if the assertion is false.
 * If the assertion is true, nothing happens.
 * */
function assert (...args: mixed[]) : void {
  console.assert(...args)
}

/**
 * Return `true` if page was opened in Right-to-left mode.
 * This is specific to arabic languages
 * @returns boolean
 */
function isRTL () : boolean {
  return global.document.documentElement.classList.contains('rtl')
}

/**
 * Copy specified string to clipboard
 */
async function copyTextToClipboard (text: string) : Promise<void> {
  const MIME_TEXT = 'text/plain'
  const clipboardData = new window.Blob([text], { type: MIME_TEXT })
  const item = new window.ClipboardItem({ [MIME_TEXT]: clipboardData })
  // $FlowFixMe[incompatible-call]
  await navigator.clipboard.write([item])
}

export {
  isRTL,
  makeCancelable,
  extractErrorMessage,
  NOTIFICATION,
  NOTIFICATION_GROUP,
  BUTTON,
  UNSET,
  UNSET_FIELD,
  __,
  __mf,
  getViewportSize,
  getTextWidth,
  set as dotNotationSetter,
  delay,
  isMobileMode,
  genTestName,
  genCellTestMarks,
  isTestingMode,
  getCurrentLocale,
  getCurrentDateTimeLocaleCatalog,
  callLater,
  localStorageAvailable,
  getFullURL,
  getCurrentActiveElement,
  getRemUnitSize,
  assert,
  copyTextToClipboard,
  formatDistanceToNow
}
