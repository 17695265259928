/**
 * @file Slider.js
 * @ignore
 * @project Web-panel
 * @author Andrey Nikulin (<nikulin@scat.su>) Monday, 8th February 2021 1:50:47 pm
 * @copyright 2019 - 2021 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { CombinedValue } from 'web-panel/types'

import * as React from 'react'
import SliderComponent from 'rc-slider'
import autoBind from 'react-autobind'
import cn from 'classnames'

type Props = {|
  value: number,
  step?: number,
  className?: string,
  containerClassName?: string,
  onChange: (value: CombinedValue) => void | Promise<void>
|}

export default class Slider extends React.PureComponent<Props> {
  constructor (props: Props) {
    super(props)
    autoBind(this)
  }

  handleChange (value: number) {
    this.props.onChange({ value, displayValue: String(value) })
  }

  render (): React.Node {
    const { value, step, containerClassName, className } = this.props
    return (
      <div className={cn('rc-slider-container', containerClassName)}>
        <SliderComponent
          step={step}
          value={value}
          containerClassName={className}
          onChange={this.handleChange}
        />
      </div>
    )
  }
}
