/**
 * @file WindowManager.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 22nd August 2019 4:24:37 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow
 */
import type { Window, iWindowManager, iShortcutsManager, iLogger, iGlobalEventBus } from '../../types'
import type { ID } from 'web-panel-essentials/types'
import type { LocationPoint } from 'skat-js/types'

import { __, getViewportSize, assert, isRTL } from '../../globals'
import * as React from 'react'

import Modal from '../../views/modal/Modal'
import { Inject, Injectable } from '../../serviceLocator'

import Delegate from '../../utils/Delegate'
import ModalList from '../../views/modal/ModalList'
import LocationView from '../../views/map/LocationView'
import Prompt from '../../views/Prompt'
import autoBind from 'react-autobind'
import { CAST } from 'web-panel-essentials/misc'

import { OperationCancelled } from 'web-panel-essentials/errors'
import Msg from './Msg'
import ExtraActions from './ExtraActions'
import { getRemUnitSize } from 'web-panel/globals'

import { Cached } from 'web-panel-essentials/decorators'
import windowRegistry from './WindowRegistry'

const DEFAULT_OFFSET = 40
const ROTATION_LIMIT_X = 20
const ROTATION_LIMIT_Y = 10

export type DialogOptions = any

export type LocationOptions = {
  center: [number, number],
  point?: LocationPoint,
  dialogOptions: DialogOptions,
  cityId: number,
  orderCityId: number,
  serviceId: number
}

type windowPosition = {| x: number, y: number |}

type Props = {}
type State = {|
  active: ID,
  syncId: number,
  positions: {[ID]: windowPosition },
  errors: {[ID]: ?string },
  contextView: ?React.Node
|}

/**
 * React view for fast rendering button collection changes
 * @private
 * @class _ToolBar
 * @extends {React.Component}
*/
class WindowManagerView extends React.Component<Props, State> implements iWindowManager {
  @Inject shortcutsManager : iShortcutsManager
  @Inject logger : iLogger
  @Inject globalEventBus : iGlobalEventBus

  ready: Promise<void>

  constructor (props) {
    super(props)
    autoBind(this)

    this.logger.namespace = 'modal-mgr'
    this.logger.info('Initializing modal manager')

    this.state = {
      active: null,
      syncId: 0,
      contextView: null,
      positions: {},
      errors: {}
    }

    this.registerKeybindings()
    this.registerEvents()
  }

  /**
   * @ignore
   */
  @Cached({ isPromise: false }) // Enshure this method to be called only once
  registerKeybindings () {
    this.shortcutsManager.register({
      name: 'COLLAPSE_ALL',
      title: __('COLLAPSE_ALL_OPENED_WINDOWS'),
      defaultShortcut: 'alt+end',
      action: this.minimizeAll
    })

    this.shortcutsManager.register({
      name: 'NEXT_WINDOW',
      title: __('SWITCH_TO_NEXT_WINDOW'),
      defaultShortcut: 'alt+pageup',
      action: this.activateNext
    })

    this.shortcutsManager.register({
      name: 'PREV_WINDOW',
      title: __('SWITCH_TO_PREVIOUS_WINDOW'),
      defaultShortcut: 'alt+pagedown',
      action: this.activatePrev
    })

    this.shortcutsManager.register({
      name: 'Close actieve window',
      title: __('CLOSE_CURRENT_ACTIVE_WINDOW'),
      defaultShortcut: 'esc',
      action: () => {
        const id = this.getActive()
        if (id) this.close(id)
      }
    })

    this.shortcutsManager.register({
      defaultShortcut: 'enter',
      name: 'primaryAction',
      title: __('PRIMARY_ACTION'),
      action: () => {
        const id = this.getActive()
        const model = windowRegistry.findModel(id)
        if (model && model.primaryButtonRef.current) {
          model.primaryButtonRef.current.simulateClick()
        }
      }
    })
  }

  /**
   * @ignore
   */
  @Cached({ isPromise: false })
  registerEvents () {
    this.globalEventBus.registerEvent('active-window-changed')
    this.globalEventBus.registerEvent('window-action-complete')
  }

  sync () {
    return new Promise((resolve) => {
      global.requestAnimationFrame(() => {
        this.setState({ syncId: this.state.syncId + 1 }, resolve)
      })
    })
  }

  /**
   * Create modal window
   * @async
   * @param {Object} modal
   * @param {Symbol} modal.[id]
   * @param {Symbol} modal.name
   * @param {Symbol} modal.label
   * @param {Symbol} modal.title
   * @returns [ModalView, ModalChildView]
   */
  async show (modalWindow : Window) : Promise<void> {
    this.logger.info('Rendering new modal window')
    this.logger.debug(modalWindow)

    const { id, parent, helpVisible } = modalWindow
    const link = windowRegistry.makeLink(id, parent)

    windowRegistry.setLink(id, link)
    if (helpVisible) windowRegistry.setHelpVisible(id)

    const position = await this.allocatePosition({
      id,
      width: modalWindow.width || 500,
      widthUnit: modalWindow.widthUnit || 'px'
    })

    windowRegistry.bindModel(link, {
      ...modalWindow,
      key: global.performance.now(),
      primaryButtonRef: React.createRef()
    })

    const changes = {
      positions: {
        ...this.state.positions,
        // $FlowFixMe[invalid-computed-prop]
        [id]: position
      }
    }

    const onDone = () => Promise.resolve()
    global.requestAnimationFrame(() => this.setState(changes, onDone))
    await onDone
  }

  invokeLocationSelect (options: LocationOptions) : Promise<?LocationPoint> {
    const { center, point, dialogOptions, cityId, orderCityId, serviceId } = options

    return new Promise(async (resolve, reject) => { // eslint-disable-line
      const id = Symbol('any')
      const dialogDefaults = {
        id,
        title: __('SET_LOCATION'),
        label: __('SET_LOCATION'),
        details: __('SET_LOCATION'),
        icon: 'map-marker',
        width: 600,
        expandable: true,
        buttons: [{
          name: 'cancel',
          label: __('CANCEL'),
          title: __('CANCEL'),
          action: async () => {
            this.close(id)
            reject(new OperationCancelled(__('CANCELLED')))
          }
        }, {
          name: 'set',
          label: __('SET'),
          title: __('SET'),
          isPrimary: true,
          action: async (locationView) => {
            const point : ?LocationPoint = await locationView.data()
            resolve(point)
            this.close(id)
          }
        }]
      }

      await this.show({
        ...{ ...dialogDefaults, ...dialogOptions },
        view: (<LocationView center={center} point={point} cityId={cityId} orderCityId={orderCityId} serviceId={serviceId} />)
      })
      await this.activate(id)
    })
  }

  alert (options: { message: string, title?: string, icon?: string, parent?: ID, positiveButtonText?: string }) : Promise<true> {
    return new Promise((resolve, reject) => {
      const id = Symbol('id')
      this.show({
        id,
        parent: options.parent,
        name: 'alert',
        testName: 'alert-window',
        label: options.title || __('WARNING'),
        title: options.title || __('WARNING'),
        width: 360,
        icon: options.icon || 'exclamation-triangle',
        view: <Msg text={options.message} testName='alert-window' />,
        dispose: () => resolve(true),
        buttons: [{
          name: 'ok',
          label: options.positiveButtonText || 'OK',
          testName: 'ok-alert-button',
          title: options.positiveButtonText || 'OK',
          isPrimary: true,
          action: async () => {
            this.close(id)
            resolve(true)
          }
        }]
      }).then(() => {
        this.activate(id)
      })
    })
  }

  confirm (options: { message: string, title?: string, icon?: string, parent?: ID, positiveButtonText?: string, negativeButtonText?: string }) : Promise<boolean> {
    return new Promise((resolve, reject) => {
      const id = Symbol('id')
      this.show({
        id,
        parent: options.parent,
        name: 'confirm',
        testName: 'confirm-window',
        label: options.title || __('CONFIRM'),
        title: options.title || __('CONFIRM'),
        width: 360,
        icon: options.icon || 'question-circle',
        view: <Msg text={options.message} testName='confirm-window' />,
        dispose: () => resolve(false),
        buttons: [{
          name: 'cancel',
          testName: 'cancel-confirm-button',
          label: options.negativeButtonText || __('CANCEL'),
          title: options.negativeButtonText || __('CANCEL'),
          action: async () => {
            resolve(false)
            this.close(id)
          }
        }, {
          name: 'ok',
          testName: 'ok-confirm-button',
          label: options.positiveButtonText || __('OK'),
          title: options.positiveButtonText || __('OK'),
          isPrimary: true,
          action: async () => {
            resolve(true)
            this.close(id)
          }
        }]
      }).then(() => {
        this.activate(id)
      })
    })
  }

  prompt (options: { message: string, title?: string, icon?: string, parent?: ID, positiveButtonText?: string, negativeButtonText?: string, value?: string }) : Promise<string | null> {
    return new Promise((resolve, reject) => {
      const id = Symbol('id')
      this.show({
        id,
        parent: options.parent,
        name: 'confirm',
        testName: 'prompt-window',
        label: options.title || __('OK'),
        title: options.title || __('OK'),
        width: 360,
        icon: options.icon || 'question-circle',
        view: <Prompt message={CAST.String(options.message)} value={options.value || ''} />,
        dispose: () => resolve(null),
        buttons: [{
          name: 'cancel',
          testName: 'cancel-confirm-button',
          label: options.negativeButtonText || __('CANCEL'),
          title: options.negativeButtonText || __('CANCEL'),
          action: async (form) => {
            resolve(null)
            this.close(id)
          }
        }, {
          name: 'ok',
          testName: 'ok-confirm-button',
          label: options.positiveButtonText || __('OK'),
          title: options.positiveButtonText || __('OK'),
          isPrimary: true,
          action: async (form) => {
            const value = form.data()
            resolve(value)
            this.close(id)
          }
        }]
      }).then(() => {
        this.activate(id)
      })
    })
  }

  isActive (id: ID) : boolean {
    return this.state.active === id
  }

  getActive () : ID {
    return this.state.active
  }

  async close (windowId: ID) : Promise<void> {
    const modal = windowRegistry.findModel(windowId)
    if (!modal) return

    windowRegistry.deleteLink(windowId)
    if (modal && modal.parent) await this.activate(modal.parent)
    else {
      const last = windowRegistry.getLastLink()
      if (last) await this.activate(last.id)
      else this.deactivate(windowId)
    }

    const { [windowId]: omitted, ...positions } = this.state.positions

    const onDone = () => Promise.resolve()
    this.setState({ positions }, onDone)
    await onDone
  }

  async toggleHelp (id) : Promise<void> {
    if (windowRegistry.isHelpVisible(id)) windowRegistry.setHelpInvisible(id)
    else windowRegistry.setHelpVisible(id)
    this.sync()
  }

  hasWindow (id: ID) : boolean {
    return windowRegistry.hasLink(id)
  }

  async activate (id) : Promise<void> {
    this.unflash(id)

    if (this.isActive(id)) return
    if (this.isMinimized(id)) return await this.flash(id)

    const { active } = this.state
    if (active !== id) this.globalEventBus.emit('active-window-changed', { id: id })

    if (this.hasChildren(id)) {
      const [child] = this.getChildren(id)
      if (child) {
        await this.unMinimize(child.id)
        await this.activate(child.id)
        return
      }
    }

    const model = windowRegistry.findModel(id)
    if (!model) return

    const onDone = () => Promise.resolve()

    global.requestAnimationFrame(async () => {
      // if (this.isMinimized(id)) await this.unMinimize(id)
      this.setState({ active: id }, onDone)
    })
    await onDone
  }

  async deactivate (id) {
    if (this.state.active === id) {
      this.setState({ active: null })
      this.globalEventBus.emit('active-window-changed', { id: null })
    }
  }

  async activateNext () {
    const { active } = this.state
    const iterator = windowRegistry.linksIterator

    let result = iterator.next()

    while (!result.done) {
      const [id] = result.value
      if (id === active) {
        while (!result.done) {
          result = iterator.next()
          if (result.value) {
            const [nextLinkId] = result.value
            if (!windowRegistry.isMinimized(nextLinkId)) {
              await this.activate(nextLinkId)
              return
            }
          }
        }
      }

      result = iterator.next()
    }
  }

  async activatePrev () {
    const { active } = this.state
    const iterator = windowRegistry.linksIterator

    let result = iterator.next()
    let previewsId = null
    while (!result.done) {
      const [id] = result.value

      if (id === active && previewsId) {
        await this.activate(previewsId)
        return
      }

      if (!this.isMinimized(id)) previewsId = id
      result = iterator.next()
    }
  }

  async updateButton (windowId: ID, buttonId: ID, changes: Object) {
    const model = windowRegistry.findModel(windowId)
    if (model) {
      model.buttons = model.buttons.map((button) => {
        if (button.id && button.id === buttonId) {
          return { ...button, ...changes }
        } else return button
      })
      this.sync()
    }
  }

  async minimize (id: ID) : Promise<void> {
    this.logger.debug('Minimizing window', id)

    windowRegistry.setMinimized(id)
    await this.sync()
  }

  async unMinimize (id: ID) : Promise<void> {
    this.logger.debug('Unminimizing window', id)

    windowRegistry.setUnMinimized(id)
    await this.sync()
  }

  isMinimized (id: ID) : boolean {
    return windowRegistry.isMinimized(id)
  }

  async flash (id: ID) : Promise<void> {
    windowRegistry.setFlashing(id)
    await this.sync()
  }

  async unflash (id: ID) : Promise<void> {
    windowRegistry.setUnflashing(id)
    await this.sync()
  }

  async minimizeAll () {
    for (const link of windowRegistry.linkList) {
      await this.minimize(link.id)
    }
    await this.deactivate(this.state.active)
  }

  isMaximized (id: ID) : boolean {
    assert(Boolean(id), id, 'Window ID is specified')
    return windowRegistry.isMaximized(id)
  }

  async maximize (id: ID) {
    windowRegistry.setMaximized(id)
    await this.sync()
  }

  async unMaximize (id: ID) {
    windowRegistry.setUnMaximized(id)
    await this.sync()
  }

  async showError (id: ID, error: string) {
    assert(id, { id }, 'The target window id to show error is specified')
    const { errors } = this.state
    // $FlowFixMe[invalid-computed-prop]
    this.setState({ errors: { ...errors, [id]: error } })
  }

  async hideError (id: ID) {
    assert(id, { id }, 'The target window id to hide error is specified')
    const { errors } = this.state
    // $FlowFixMe[invalid-computed-prop]
    this.setState({ errors: { ...errors, [id]: null } })
  }

  handleCollapseAll () {
    this.minimizeAll()
  }

  @Cached({ isDeepEqual: true }) // Preventing useless rerendering
  _updateModalPosition ({ id, x, y }) {
    const { positions } = this.state
    this.setState({ positions: { ...positions, [id]: { x, y } } })
  }

  async allocatePosition ({ id, width, widthUnit }) {
    const winSize = getViewportSize()
    const positions = this.state.positions
    if (widthUnit === 'rem') width = width * getRemUnitSize()

    const RTLMirroringOffset = isRTL() ? width : 0
    const initialPosition = {
      x: Math.ceil(winSize.width / 2) - width / 2 + RTLMirroringOffset,
      y: Math.max(Math.ceil(winSize.height / 2) - Math.ceil((width * 0.75) / 2), 0)
    }

    const positionsList = Object.getOwnPropertySymbols(positions).map((key) => positions[key])
    let newPostition = initialPosition
    let increment = 0
    while (newPostition === initialPosition && increment < ROTATION_LIMIT_X) {
      const attempt = {
        x: Math.ceil(initialPosition.x + increment % ROTATION_LIMIT_X * DEFAULT_OFFSET),
        y: Math.ceil(initialPosition.y + increment % ROTATION_LIMIT_Y * DEFAULT_OFFSET)
      }
      if (positionsList.some((position) => position.x === attempt.x && position.y === attempt.y)) {
        increment++
        continue
      }
      newPostition = attempt
    }

    return newPostition
  }

  async handleIndicatorClick (id) {
    if (this.isActive(id)) {
      await this.deactivate(id)
      await this.minimize(id)
      // await this.activate()
      return
    }

    if (this.isMinimized(id)) {
      await this.unMinimize(id)
      await this.activate(id)
    } else {
      await this.activate(id)
    }
  }

  async updateContextView (contextView: React.Node) {
    this.setState({ contextView })
  }

  hasChildren = (id: ID) : boolean => {
    return windowRegistry.linkList.some(({ parent }) => id === parent)
  }

  getChildren (id: ID) {
    return windowRegistry.linkList.filter(({ parent }) => id === parent)
  }

  async handleCloseButtonPressed (id, view) {
    const model = windowRegistry.findModel(id)
    if (model && model.onCloseButtonClick) await model.onCloseButtonClick(view)
    else await this.close(id)
  }

  async handleMinimizeButtonPressed (id, view) {
    const model = windowRegistry.findModel(id)
    if (model && model.onMinimizeButtonClick) await model.onMinimizeButtonClick(view)
    await this.deactivate(id)
    await this.minimize(id)
  }

  async handleMaximizeButtonPressed (id, view) {
    const model = windowRegistry.findModel(id)
    if (model && model.onMaximizeButtonClick) await model.onMaximizeButtonClick(view)
    else {
      if (this.isMaximized(id)) await this.unMaximize(id)
      else await this.maximize(id)
    }
  }

  async handleHelpButtonPressed (id, view) {
    const model = windowRegistry.findModel(id)
    if (model && model.onHelpButtonClick) await model.onHelpButtonClick(view)
    else await this.toggleHelp(id)
  }

  handleMouseDown = (id) => {
    this.activate(id)
  }

  handleDragStop = (a, { lastX, lastY }, id) => {
    this._updateModalPosition({ id: id, x: lastX, y: lastY })
  }

  render () : React.Node {
    const { active, positions, errors, contextView, syncId } = this.state
    const links = windowRegistry.linkList

    return (
      <div className='modals-controls' data-sync={syncId}>
        <div className='modal-layer'>
          {links.map((link, idx) => {
            const model = windowRegistry.getModel(link)
            if (!model) return null

            const position = positions[link.id]
            if (!position) return null

            const error = errors[link.id]

            const isMinimized = windowRegistry.isMinimized(link.id)
            const isMaximized = windowRegistry.isMaximized(link.id)
            const helpVisible = windowRegistry.isHelpVisible(link.id)

            return (
              <Modal
                id={model.id}
                key={model.key}
                primaryButtonRef={model.primaryButtonRef} // грустно, но ничего лучше не придумалось
                name={model.name}
                error={error}
                testName={model.testName}
                label={model.label}
                title=''
                icon={model.icon}
                width={model.width}
                widthUnit={model.widthUnit}
                expandable={model.expandable}
                height={model.height}
                className={model.className}
                hasChildren={this.hasChildren(model.id)}
                buttons={model.buttons}
                onDispose={model.dispose} // eslint-disable-line
                isActive={model.id === active}
                isMinimized={isMinimized}
                isMaximized={isMaximized}
                onMouseDown={this.handleMouseDown}
                onMinimizeButtonPressed={this.handleMinimizeButtonPressed}
                onMaximizeButtonPressed={this.handleMaximizeButtonPressed}
                onCloseButtonPressed={this.handleCloseButtonPressed}
                onHelpButtonPressed={this.handleHelpButtonPressed}
                position={position}
                onDragStop={this.handleDragStop}
                help={model.help}
                helpVisible={helpVisible}
                extraView={model.extraView}
                view={model.view}
              />
            )
          }
          )}
        </div>
        <ModalList
          syncId={syncId}
          links={links}
          active={active}
          onIndicatorClick={this.handleIndicatorClick}
        />
        <ExtraActions
          onCollapseAll={this.handleCollapseAll}
          contextView={contextView || null}
        />
      </div>
    )
  }
}

/**
 * Прокси обвязка вокруг WindowManagerView
 */
@Injectable('windowManager', true)
class WindowManager extends Delegate implements iWindowManager {
  @Inject layout : any
  ready: Promise<void>

  alert: Function
  confirm: Function
  prompt: Function
  activate: Function
  deactivate: Function
  show: Function
  getActive: Function
  updateButton: Function
  close: Function
  hasWindow: Function
  updateContextView: Function
  hideError: Function
  showError: Function

  constructor () {
    super()
    this.ready = new Promise(async (resolve) => { //eslint-disable-line
      const view = await this.layout.renderView(<WindowManagerView />, 'footer-region')
      this.becomeProxyOf(view)
      resolve()
    })
  }
}

export default WindowManager
