/**
 * @file errors.js
 * @ignore
 * @project Web-panel
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow
 */
import { __, __mf } from './globals'
import ApplicationError from 'web-panel-essentials/errors'

/**
 * Requested dependency could not be provided
 * @example <caption>Import</caption>
 * import { UnresolvedDependencyError } from 'web-panel/errors'
 */
export class UnresolvedDependencyError extends ApplicationError {}

export class TransportError extends ApplicationError {}
export class EndpointLookupError extends ApplicationError {}

/**
 * @example <caption>Import</caption>
 * import { RequestFailed } from 'web-panel/errors'
 */
export class RequestFailed extends ApplicationError {}
export class DocumentIsEmptyError extends ApplicationError {}
export class DuplicateKeyError extends ApplicationError {}
export class InvalidStateError extends ApplicationError {}

export class ServerUnreachableError extends ApplicationError {
  get level () : 'critical' {
    return 'critical'
  }
}

export class TransferableProtocolError extends ApplicationError {
  code: string
  args: Array<any>
  constructor (message:string, code: string, args: Array<any>) {
    super(message)
    this.code = code
    this.args = args || []
  }

  serialize () : string {
    return JSON.stringify({
      message: this.message,
      stack: this.stack,
      code: this.code,
      args: this.args
    })
  }
}

export class ProtocolError extends ApplicationError {
  code: string
  args: Array<any>

  constructor (message: string, code: string, args: Array<any>, stack: string) {
    super(message)
    this.code = code
    this.args = args || []
    this.stack = stack
  }

  static deserialize (payload: string) : ProtocolError {
    const data = JSON.parse(payload)
    const [baseCode, code] = data.code.split(':')
    if (baseCode === RequestTimoutError.baseCode) return new RequestTimoutError(data.message, code, data.args, data.stack)
    if (baseCode === LoginError.baseCode) return new LoginError(data.message, code, data.args, data.stack)
    if (baseCode === LicenseError.baseCode) return new LicenseError(data.message, code, data.args, data.stack)
    if (baseCode === VersionError.baseCode) return new VersionError(data.message, code, data.args, data.stack)
    if (baseCode === ObjectCreateError.baseCode) return new ObjectCreateError(data.message, code, data.args, data.stack)
    if (baseCode === OrderCreateError.baseCode) return new OrderCreateError(data.message, code, data.args, data.stack)
    if (baseCode === OrderBindError.baseCode) return new OrderBindError(data.message, code, data.args, data.stack)
    if (baseCode === DriverError.baseCode) return new DriverError(data.message, code, data.args, data.stack)
    return new ProtocolError(data.message, data.code, data.args, data.stack)
  }
}

export class RequestTimoutError extends ProtocolError {
  static get baseCode () : string {
    return 'timeout'
  }

  get message () : string {
    return __('REQUEST_TIMEOUT')
  }
}

export class LoginError extends ProtocolError {
  static get baseCode () : string {
    return 'lgnerr'
  }

  get message () : string {
    const keys = {
      throtl: 'LOGIN_ERROR_SLOW',
      unsclt: 'LOGIN_ERROR_UNSUPPORTED',
      exists: 'LOGIN_ERROR_EXISTS',
      passwd: 'LOGIN_ERROR_AUTH',
      unknwn: 'LOGIN_ERROR_ERROR'
    }

    return __(keys[this.code] || keys.unknwn)
  }
}

export class LicenseError extends ProtocolError {
  static get baseCode () : string {
    return 'licerr'
  }

  get message () : string {
    const keys = {
      conlmt: 'LICENSE_ERROR_LICLIMIT',
      expire: 'LICENSE_ERROR_EXPIRED',
      unknwn: 'LICENSE_ERROR_UNKNOWN'
    }
    return __(keys[this.code] || keys.unknwn)
  }
}

export class VersionError extends ProtocolError {
  static get baseCode () : string {
    return 'vererr'
  }

  get message () : string {
    const keys = {
      unknwn: 'VERSION_ERROR_VER_INV',
      tooold: 'VERSION_ERROR_VER_OLD',
      toonew: 'VERSION_ERROR_VER_NEW'
    }

    return __(keys[this.code] || keys.unknwn)
  }
}

export class ObjectCreateError extends ProtocolError {
  static get baseCode () : string {
    return 'obcerr'
  }

  get message () : string {
    const keys = {
      exists: 'ERROR_RECORD_ALREADY_EXISTS'
    }

    return __(keys[this.code] || 'ERROR_CANNOT_CREATE_OBJECT')
  }
}

export class OrderCreateError extends ProtocolError {
  static get baseCode () : string {
    return 'ocrerr'
  }

  get message () : string {
    const keys = {
      clientreqd: 'ERROR_CLIENT_REQUIRED',
      regionrqd: 'ERROR_REGION_REQUIRED',
      wptreqd: 'ERROR_WAYPOINT_REQUIRED',
      srcempty: 'ERROR_WAYPOINT_REQUIRED',
      invalidclient: 'ERROR_INVALID_CLIENT',
      banned: 'ERROR_ORDER_CREATION_BANNED',
      inactivecustomer: 'ERROR_INACTIVE_CUSTOMER',
      deliverytimeviolation: 'PICKUP_TIME_CANT_LESS_THAN_MIN',
      pickuptimeviolation: 'PICKUP_TIME_CANT_BE_GREATER_THAN_DELIVERY_TIME',
      deliverytimeshort: 'DELIVERY_TIME_IS_TOO_SHORT'
    }

    if (this.code === 'deliverytimeviolation') {
      return __mf(keys[this.code], { offset: this.args[0][0] })
    }

    return __(keys[this.code] || 'ERROR_CANNOT_CREATE_ORDER')
  }
}

export class OrderBindError extends ProtocolError {
  static get baseCode () : string {
    return 'bnderr'
  }

  get message () : string {
    const keys = {
      banned: 'ERROR_ORDER_BIND_BANNED',
      notact: 'ERROR_ORDER_INACTIVE',
      invost: 'ERROR_ORDER_STATUS_INVALID',
      hasdrv: 'ERROR_ORDER_HAS_DRIVER',
      drvopt: 'ERROR_DRIVER_OPTIONS_MISMATCH',
      drvcls: 'ERROR_DRIVER_CLASS_MISMATCH',
      drvcst: 'ERROR_DRIVER_CUSTOMER_NOT_ALLOWED',
      drvinv: 'DRIVER_IS_NOT_ALLOWED'
    }

    if (this.code === 'drvinv') {
      return __mf(keys[this.code], { reason: this.args[0][0] })
    }

    return __(keys[this.code] || 'ERROR_ORDER_BIND_FAILED')
  }
}

export class DriverError extends ProtocolError {
  static get baseCode () : string {
    return 'drverr'
  }

  get message () : string {
    const keys = {
      banned: 'ERROR_DRIVER_IS_BANNED',
      noexst: 'NO_DRIVER_IN_DATABASE',
      maxord: 'ERROR_DRIVER_HAS_MAXIMUM_ORDERS',
      hasord: 'ERROR_DRIVER_ALREADY_HAS_ORDER',
      noconn: 'ERROR_DRIVER_NOT_CONNECTED'
    }

    if (this.code === 'banned') {
      const banTypes = {
        balance: 'DRIVER_BALANCE_TOO_LOW',
        admin: 'DRIVER_BANNED_BY_ADMIN',
        admin_temporary: 'DRIVER_BANNED_BY_ADMIN',
        preorder_reject: 'DRIVER_BANNED_BY_REJECTS',
        order_reject: 'DRIVER_BANNED_BY_REJECTS',
        photo_report: 'DRIVER_NOT_PASSED_PHOTO_REPORT',
        rating: 'DRIVER_BANNED_BY_RATING',
        work_time: 'DRIVER_IS_NOT_ON_SHIFT'
      }

      return `${__(keys[this.code])}: ${this.args[0].map(v => __(banTypes[v])).join(', ')}`
    }

    return __(keys[this.code] || 'ERROR_DRIVER_IS_UNABLE_TO_TAKE_ORDER')
  }
}
