/**
 * @file WidgetFactory.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Wednesday, 17th February 2021 10:15:48 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { iStockExtension, iWindowManager, iPageManager, iShortcutsManager, iPreferencesManager, Window } from '../types'

import * as React from 'react'
import { __, isTestingMode } from '../globals'
import WidgetFactory from './../views/WidgetFactory'
import { Inject } from '../serviceLocator'

class WidgetFactoryExtension implements iStockExtension {
  @Inject windowManager : iWindowManager
  @Inject pageManager : iPageManager
  @Inject shortcutsManager : iShortcutsManager
  @Inject preferencesManager : iPreferencesManager

  windowId: symbol
  window: Window
  async activate () : Promise<void> {
    this.windowId = Symbol.for('widget-factory-id')
    if (!isTestingMode()) return
    this.window = {
      id: this.windowId,
      name: 'widgets',
      label: __('WIDGET_FACTORY'),
      title: 'widgets',
      icon: 'info-circle',
      testName: 'widget-factory',
      className: 'widget-factory',
      width: 45,
      widthUnit: 'rem',
      // $FlowFixMe[incompatible-type]
      view: <WidgetFactory />,
      buttons: [{
        id: Symbol.for('widget-factory-close-id'),
        testName: 'about-window-close-button',
        name: 'close',
        label: __('CLOSE'),
        title: __('CLOSE_TOOLTIP'),
        action: async () => this.windowManager.close(this.windowId)
      }]
    }
    this.shortcutsManager.register({
      name: 'WidgetFactory',
      title: __('SHOW_WIDGET_FACTORY'),
      defaultShortcut: 'home',
      action: this.invokeWidgetFactoryWindow.bind(this)
    })
    global.testWindowPointer = this.window
  }

  async invokeWidgetFactoryWindow () {
    await this.windowManager.show(this.window)
    this.windowManager.activate(this.windowId)
  }
}

export default WidgetFactoryExtension
