/**
 * @file VirtualList.js
 * @project web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 28th November 2023 11:17:06 am
 * @copyright 2015 - 2023 SKAT LLC, Delive LLC
 * @flow strict
 */
/* global SyntheticWheelEvent, HTMLElement */
import * as React from 'react'
import autoBind from 'react-autobind'
import cn from 'classnames'

type Props = {|
  pageSize: number,
  itemsCount: number,
  itemRenderer: (index: number) => React.Node,
  emptyItemRenderer: (index: number) => React.Node
|}

type State = {|
  cursor: number
|}

class VirtualList extends React.PureComponent<Props, State> {
  constructor (props: Props) {
    super(props)
    autoBind(this)
    this.state = {
      cursor: 0
    }
  }

  handleScroll (event: SyntheticWheelEvent<HTMLElement>) {
    const { pageSize, itemsCount } = this.props
    let { cursor } = this.state
    if (event.deltaY > 0) cursor += 1
    if (event.deltaY < 0) cursor -= 1

    cursor = Math.min(itemsCount - pageSize, cursor)
    cursor = Math.max(0, cursor)

    this.setState({ cursor })
  }

  render () : React.Node {
    const { pageSize, itemRenderer, emptyItemRenderer, itemsCount } = this.props
    const { cursor } = this.state

    const limitReached = cursor + pageSize >= itemsCount
    const hasAbove = cursor > 0

    const className = cn('virtual-list', {
      'has-below': !limitReached,
      'has-above': hasAbove,
      'has-both': hasAbove && !limitReached
    })

    const children = Array.from({ length: pageSize }, (_, i) => {
      const index = cursor + i
      if (index >= itemsCount) return emptyItemRenderer(index)
      else return itemRenderer(index)
    })

    return (
      <div className='relative'>
        <div className={className} onWheel={this.handleScroll}>
          {children}
        </div>
      </div>
    )
  }
}

export default VirtualList
