/**
 * @file PointMarker.js
 * @ignore
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 7th July 2022 8:33:46 am
 * @copyright 2015 - 2022 SKAT LLC, Delive LLC
 * @flow strict
 */

import * as React from 'react'
import type { PointMarkerProps } from 'web-panel/components/types'

import { pointIsOutOfBounds } from './utils'
import { If } from 'web-panel/components'
import { assert } from 'web-panel/globals'

function PointMarker ({ latLngToPixel, point, name, details, bounds }: PointMarkerProps) : React.Node {
  assert(point && point.length, point, 'Point is a list with non-zero elements inside')

  if (pointIsOutOfBounds(point, bounds)) return null
  const [left, top] = latLngToPixel(point)
  return (
    <div className='point-marker' style={{ left, top }}>
      <div className='pin' data-name={name} />
      <If condition={Boolean(details)}>
        <div className='details'>
          <div className='vertical-cut' />
          {details}
        </div>
      </If>
      <div className='pulse' />
    </div>
  )
}

export default PointMarker
