
/**
 * @file Modal.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 9th July 2019 2:22:27 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow
 */
import type { Window, iExposedView, iEventEmitter, asyncVoid, ReactRef } from 'web-panel/types'
import type { ID } from 'web-panel-essentials/types'

import * as React from 'react'
import cn from 'classnames'
import Draggable from 'react-draggable'
import Header from './Header'
import Footer from './Footer'
import Title from './Title'

import autoBind from 'react-autobind'
import { If } from 'web-panel/components'
import { genTestName, getViewportSize } from 'web-panel/globals'
import { Inject } from 'web-panel/serviceLocator'
import Action from 'web-panel/components/Action'

type Position = {| x: number, y: number |}

export type Props = {|
  ...Window,
  error: ?string,

  isActive: boolean,
  isMinimized: boolean,
  isMaximized?: boolean,

  hasChildren: boolean,
  onDispose: Function,
  onMouseDown: Function,
  onDragStop: Function,

  onCloseButtonPressed: (id: ID, view: iExposedView<*>) => asyncVoid,
  onMinimizeButtonPressed: (id: ID, view: iExposedView<*>) => asyncVoid,
  onHelpButtonPressed: (id: ID, view: iExposedView<*>) => asyncVoid,
  onMaximizeButtonPressed: (id: ID, view: iExposedView<*>) => asyncVoid,

  primaryButtonRef: ReactRef<*>,
  position: Position
|}

type LastPositionPoint = {|
  lastX: number,
  lastY: number
|}

class Modal extends React.PureComponent<Props> {
  childView: React.Node
  ref: ReactRef<typeof Modal>
  childRef: ReactRef<*>
  container: ReactRef<'div'>
  focusFirst: ReactRef<'span'>
  focusLast: ReactRef<'span'>
  defaultPosition: Position

  @Inject globalEventBus : iEventEmitter

  get ChildView () : React.Node {
    if (this.childView) return this.childView
    this.childView = React.cloneElement(this.props.view, { ref: this.childRef })
    return this.childView
  }

  constructor (props: Object) {
    super(props)
    autoBind(this)
    this.ref = React.createRef()
    this.childRef = React.createRef()
    this.container = React.createRef()
    this.focusFirst = React.createRef()
    this.focusLast = React.createRef()
    this.defaultPosition = props.position
  }

  handleClose () : void {
    this.props.onCloseButtonPressed(this.props.id, this.exposedView)
  }

  handleHelpClick () : void {
    this.props.onHelpButtonPressed(this.props.id, this.exposedView)
  }

  handleMinimize () : void {
    this.props.onMinimizeButtonPressed(this.props.id, this.exposedView)
  }

  handleMaximize () : void {
    this.props.onMaximizeButtonPressed(this.props.id, this.exposedView)
  }

  handleDragStop (a: any, { lastX, lastY }: LastPositionPoint) : void {
    if (this.props.isMaximized) return
    const position = {
      // We don't need fractional digits for pixels
      // as it would be the reason of a blurry lines
      lastX: Math.ceil(lastX),
      lastY: Math.ceil(lastY)
    }
    this.props.onDragStop(a, position, this.props.id)
  }

  handleMouseDown () : void {
    this.props.onMouseDown(this.props.id)
  }

  componentWillUnmount () {
    this.props.onDispose && this.props.onDispose()
  }

  // UNSAFE_componentWillReceiveProps (nextProps) {
  //   Object.keys(nextProps)
  //     .filter(key => {
  //       return nextProps[key] !== this.props[key]
  //     })
  //     .map(key => {
  //       console.log(
  //         'changed property:',
  //         key,
  //         'from',
  //         this.props[key],
  //         'to',
  //         nextProps[key]
  //       )
  //     })
  // }

  handleFocusToFirst (e: any) {
    this.focusFirst.current?.focus()
  }

  handleFocusToLast (e: any) {
    this.focusLast.current?.focus()
  }

  get exposedView () : iExposedView<*> {
    const { current } = this.childRef
    if (!current) {
      return {
        data: async () => null,
        command: async () => {}
      }
    }

    const { data, command } = current
    return { //eslint-disable-line
      data: data.bind(current),
      command: command.bind(current)
    }
  }

  async handleButtonClick (buttonName: ?string, cb: (iExposedView<*>) => asyncVoid) : Promise<void> {
    const { name } = this.props
    const { current } = this.childRef
    if (!current) return

    const actionResult = await cb(this.exposedView)

    if (name) {
      this.globalEventBus.emit('window-action-complete', {
        modalWindowName: name,
        buttonName,
        actionResult
      })
    }
  }

  componentDidUpdate (props: Props) : void {
    if (props.isActive === false && this.props.isActive) {
      if (this.childRef.current?.activate) {
        this.childRef.current?.activate()
      }
    }
  }

  render () : React.Node {
    const {
      error,
      label,
      title = '',
      icon = 'circle',
      width = 500,
      widthUnit = 'px',
      height,
      extraView,
      buttons = [],
      isActive = false,
      isMinimized = false,
      isMaximized = false,
      hasChildren = false,
      position,
      helpVisible = false,
      expandable,
      className,
      primaryButtonRef,
      testName,
      help
    } = this.props

    const viewport = getViewportSize()
    const bounds = {
      left: -width * 0.75,
      right: viewport.width - width * 0.25,
      top: 0,
      bottom: viewport.height - 40
    }
    const widthInUnits = [width, widthUnit].join('')

    return (
      <Draggable
        defaultPosition={this.defaultPosition}
        enableUserSelectHack={false}
        position={position}
        onStop={this.handleDragStop}
        onMouseDown={this.handleMouseDown}
        bounds={bounds}
        handle='.title'
      >
        <div
          {...genTestName(testName)}
          tabIndex={1}
          ref={this.container}
          className={cn('modal', className, { isActive, isMinimized, isMaximized })}
          style={{ width: widthInUnits }}
        >
          <span tabIndex={0} onFocus={this.handleFocusToLast} />
          <span ref={this.focusFirst} tabIndex={0} />

          <If condition={helpVisible}>
            <div className='help-content'>
              <div className='title'>
                <Title
                  title={title}
                  icon={icon}
                  label={label}
                  testName={testName}
                />
              </div>
              <div className='full-padding h100 content-body'>
                {help}
              </div>
              <div className='footer'>
                <Action icon='check-circle' onClick={this.handleHelpClick} />
              </div>
            </div>
          </If>

          <div className='modal-content'>
            <Header
              closeDisabled={hasChildren}
              expandable={Boolean(expandable)}
              isMaximized={isMaximized}
              helpAvaialable={Boolean(help)}
              onMinimizeClick={this.handleMinimize}
              onExpandClick={this.handleMaximize}
              onCloseClick={this.handleClose}
              onHelpClick={this.handleHelpClick}
            >
              <Title
                title={title}
                icon={icon}
                label={label}
                testName={testName}
                invisible={helpVisible}
              />
            </Header>

            <div className='body' style={{ height }}>
              {this.ChildView}
            </div>

            <div className={cn('error', { active: Boolean(error) })} {...genTestName('modal-error-container')}>
              <span className='error-text' {...genTestName('modal-error-text')}>{error}</span>
            </div>

            <Footer
              buttonsDisabled={hasChildren}
              buttons={buttons}
              onButtonClick={this.handleButtonClick}
              primaryButtonRef={primaryButtonRef}
            >
              {extraView || null}
            </Footer>
          </div>
          <span ref={this.focusLast} tabIndex={0} />
          <span tabIndex={0} onFocus={this.handleFocusToFirst} />
        </div>
      </Draggable>
    )
  }
}

export default Modal
