// TODO: Move it from web-panel away!
/**
 * @file AddressInput.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 24th October 2019 7:38:43 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow strict
 */
/* global SyntheticEvent, HTMLElement, SyntheticKeyboardEvent, HTMLInputElement, $Values */

import type { ID, iDataProvider, iDataManager, WaterlineQuery, iNullObjectFactory } from 'web-panel-essentials/types'
import type { City, Region } from 'skat-js/types'
import type { WindowButton, CombinedValue, iWindowManager, asyncVoid } from 'web-panel/types'

import * as React from 'react'
import AutocompleteField from '../../../components/AutocompleteField'
import ReferenceSelect from '../../../components/ReferenceSelect'
import Select from './../../../components/Select'
import { FIELD } from 'skat-js/constants'
import cn from 'classnames'
import { __, UNSET, genTestName } from 'web-panel/globals'
import FieldIcon from './FieldIcon'
import { CAST, KEY } from 'web-panel-essentials/misc'
import { Cached } from 'web-panel-essentials/decorators'

import { Inject } from 'web-panel/serviceLocator'
import autoBind from 'react-autobind'

type Street = {
  id: ID,
  name: ?string
}

type Props = {
  id: ID,
  latitude: ?number,
  longitude: ?number,
  house: ?string,
  apartment: ?string,
  entrance: ?string,
  floor: ?string,

  name?: string, //

  city: City,
  parentCity: City,
  serviceId: number,
  street: Street,
  region: Region,

  position: number,
  catchFocus?: boolean,
  disabled?: boolean,
  cityProvider: iDataProvider<City> & iNullObjectFactory<City>,
  streetProvider: iDataProvider<*>,
  houseProvider: iDataProvider<*>,
  regionProvider: iDataProvider<Region>,
  cityDataManager: iDataManager<City>,

  testName: string,
  placeholder: string,
  noAppartment?: boolean,
  noEntrance?: boolean,
  noFloor?: boolean,
  noCity?: boolean,
  noRegion?: boolean,
  noControls?: boolean,
  removalDenied?: boolean,
  regionsFilter?: WaterlineQuery,
  regionDetected: boolean,

  onNavigate: ({ id: ID, latitude: ?number, longitude: ?number, street: Street, house: ?string, city: City, serviceId: number }) => asyncVoid,
  onChange: (fieldName: string, changes: mixed, id: ID) => asyncVoid,
  onSubFieldBlur: (fieldName: string, id: ID) => asyncVoid,
  onAddButtonPress: (poistion: number) => asyncVoid,
  onRemoveButtonPress: (id: ID) => asyncVoid,
  onPositionShift: (id: ID, direction: 'up' | 'down') => asyncVoid,
  onFocus?: (SyntheticEvent<HTMLElement>) => asyncVoid,
  onBlur?: (SyntheticEvent<HTMLElement>) => asyncVoid,
  _lockedFields: {[ID]: boolean},
}

type State = {
  focused: boolean
}

class AddressInput extends React.Component<Props, State> {
  @Inject cityWizard : {}
  @Inject windowManager : iWindowManager

  constructor (props: Props) {
    super(props)
    autoBind(this)
    this.state = {
      focused: this.props.catchFocus || false
    }
  }

  isLocked (field: $Values<typeof FIELD>) : boolean {
    return Boolean(this.props._lockedFields[field])
  }

  updateIfNecessary (props: Props, attr: string) : boolean {
    if (props[attr] === undefined) return false
    if (props[attr] !== this.state[attr]) {
      const changes = {}
      changes[attr] = props[attr]
      this.setState(changes)
      return true
    }
    return false
  }

  handleKeyDown (event: SyntheticKeyboardEvent<HTMLInputElement>) {
    const shift = this.props.onPositionShift
    if (!shift) return
    if (event.altKey) {
      if (event.keyCode === KEY.DOWN) shift(this.props.id, 'down')
      if (event.keyCode === KEY.UP) shift(this.props.id, 'up')
    }
  }

  handleFocus (event: SyntheticEvent<HTMLElement>) {
    this.setState({ focused: true })
    this.props.onFocus && this.props.onFocus(event)
  }

  handleBlur (event: SyntheticEvent<HTMLElement>) {
    this.setState({ focused: false })
    this.props.onBlur && this.props.onBlur(event)
  }

  handleSubFieldBlur (fieldName: string) {
    this.props.onSubFieldBlur && this.props.onSubFieldBlur(fieldName, this.props.id)
  }

  async handleToggleCity () {
    const { onChange, city, parentCity } = this.props
    const hasCity = Boolean(city.id)

    if (hasCity) onChange('city', this.props.cityProvider.nullObject, this.props.id)
    else onChange('city', parentCity, this.props.id)

    this.handleSubFieldBlur('city')
  }

  async handleCityChange ({ value, displayValue }: CombinedValue) : asyncVoid {
    let city = { ...this.props.cityProvider.nullObject, name: displayValue }
    if (value) [city] = await this.props.cityProvider.get({ where: { id: value }, limit: 1 })
    this.props.onChange('city', city, this.props.id)
  }

  async handleRegionChange ({ value }: CombinedValue) {
    const [region] = await this.props.regionProvider.get({ where: { id: value } })
    this.props.onChange('region', region, this.props.id)
    this.props.onSubFieldBlur('region', this.props.id)
  }

  handleStreetChange ({ value, displayValue }: CombinedValue) {
    this.props.onChange('street', {
      id: value,
      name: displayValue
    }, this.props.id)
  }

  handleHouseChange ({ displayValue }: CombinedValue) {
    this.props.onChange('house', displayValue, this.props.id)
  }

  handleApartmentChange (event: SyntheticEvent<HTMLInputElement>) {
    const displayValue = event.currentTarget.value
    this.props.onChange('apartment', displayValue, this.props.id)
  }

  handleEntranceChange (event: SyntheticEvent<HTMLInputElement>) {
    const displayValue = event.currentTarget.value
    this.props.onChange('entrance', displayValue, this.props.id)
  }

  handleFloorChange (event: SyntheticEvent<HTMLInputElement>) {
    const displayValue = event.currentTarget.value
    this.props.onChange('floor', displayValue, this.props.id)
  }

  handleRemoveButtonPress () {
    const { onRemoveButtonPress, removalDenied } = this.props
    if (onRemoveButtonPress && !removalDenied) {
      onRemoveButtonPress(this.props.id)
    }
  }

  handleCityBlur (event: SyntheticEvent<HTMLElement>) {
    this.handleBlur(event)
  }

  @Cached({ isDeepEqual: false })
  cityActions () : WindowButton[] {
    return [{
      name: 'add-city',
      icon: 'globe',
      title: __('ADD_CITY'),
      label: __('ADD_CITY'),
      // $FlowFixMe
      action: async () => this.cityWizard.invoke()
    }]
  }

  @Cached({ isDeepEqual: false })
  cityFilter () : WaterlineQuery {
    return {
      where: {
        id: { '!=': UNSET.CITY.id },
        deletionDate: null
      }
    }
  }

  _renderCity () : React.Node {
    const { city, testName, noCity, disabled, cityProvider, cityDataManager } = this.props
    if (noCity) return null

    // moved to static property to prevent rerenders
    const actions : WindowButton[] = this.cityActions()
    const filter = this.cityFilter()

    return (
      <>
        <FieldIcon testName={`${testName}-glob`} icon='globe' active={Boolean(city.id)} onClick={this.handleToggleCity} disabled={disabled} />
        <ReferenceSelect
          testName={`${testName}-city`}
          windowManager={this.windowManager}
          value={city.id}
          actions={actions}
          displayValue={CAST.String(city.name)}
          onChange={this.handleCityChange}
          onFocus={this.handleFocus}
          onBlur={this.handleCityBlur}
          containerClassName={cn('city', { collapsed: !city.id })}
          disabled={!city.id}
          placeholder={__('CITY')}
          filter={filter}
          readOnly={this.isLocked(FIELD.CITY) || this.isLocked(FIELD.WAYPOINT_CITY)}
          dataManager={cityDataManager}
          dataProvider={cityProvider}
        />
      </>
    )
  }

  _renderRegion () : React.Node {
    const { focused } = this.state
    const { testName, region, regionsFilter, regionDetected, noRegion, disabled, regionProvider } = this.props
    if (noRegion) return null

    return (
      <div className={cn('field', 'l3', 'field-address', { focused })}>
        <Select
          testName={`${testName}-region`}
          filter={regionsFilter}
          invalid={!regionDetected}
          value={region.id}
          displayValue={CAST.String(region.name)}
          containerClassName='district'
          placeholder={__('REGION')}
          onChange={this.handleRegionChange}
          readOnly={this.isLocked(FIELD.REGION) || this.isLocked(FIELD.WAYPOINT_REGION)}
          dataProvider={regionProvider}
          disabled={disabled}
        />
      </div>
    )
  }

  handleAddButtonPress () : void {
    this.props.onAddButtonPress(this.props.position)
  }

  _renderControls () :React.Node {
    const { removalDenied, testName, noControls, disabled } = this.props
    if (noControls) return null

    return (
      <div className='address-controls field l4'>
        <FieldIcon testName={`${testName}-remove`} icon='minus' disabled={removalDenied || this.isLocked(FIELD.ROUTE) || disabled} onClick={this.handleRemoveButtonPress} />
        <FieldIcon testName={`${testName}-add`} icon='plus' disabled={this.isLocked(FIELD.ROUTE) || disabled} onClick={this.handleAddButtonPress} />
      </div>
    )
  }

  handleMarkerClick () {
    const { id, latitude, longitude, street, house, city, parentCity, serviceId } = this.props
    let targetCity = city
    if (city.id === null) targetCity = parentCity
    this.props.onNavigate && this.props.onNavigate({ id, latitude, longitude, street, house, city: targetCity, serviceId })
  }

  handleHouseBlur (e: SyntheticEvent<HTMLElement>) {
    this.handleBlur(e)
    this.handleSubFieldBlur('house')
  }

  handleStreetBlur (e: SyntheticEvent<HTMLElement>) : void {
    this.handleBlur(e)
    this.handleSubFieldBlur('street')
  }

  @Cached({ isDeepEqual: false })
  houseFilter (streetName: ?string, cityId: ?ID) : WaterlineQuery {
    let filter = {}
    if (streetName) filter = { where: { street: { ilike: streetName } } }
    if (cityId) filter.where = { ...filter.where, cityId }
    return filter
  }

  render () : React.Node {
    const { focused } = this.state
    const {
      testName, catchFocus, city, parentCity, name, street, house, apartment, entrance, floor,
      latitude, longitude, noAppartment, noEntrance, noFloor, disabled, streetProvider,
      houseProvider
    } = this.props
    const { placeholder } = this.props

    let streetFilter

    let targetCityId = parentCity.id
    if (city.id && CAST.Number(city.id) !== UNSET.CITY.id) targetCityId = city.id

    const houseFilter = this.houseFilter(street?.name, targetCityId)
    if (targetCityId) streetFilter = { where: { cityId: targetCityId } }

    return (
      <div className='address-box'>
        {name ? <div className='tip'>{name}</div> : null}
        <div className={cn('field', 'l1', 'field-address', { focused })}>
          {this._renderCity()}

          <AutocompleteField
            valuesBasedBlur
            testName={`${testName}-street`}
            value={street.id}
            displayValue={CAST.String(street.name)}
            onFocus={this.handleFocus}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleStreetChange}
            readOnly={this.isLocked(FIELD.WAYPOINT_STREET)}
            onBlur={this.handleStreetBlur}
            containerClassName='street'
            filter={streetFilter}
            catchFocusOnMount={catchFocus}
            placeholder={placeholder || __('STREET')}
            dataProvider={streetProvider}
            disabled={disabled}
          />

          <AutocompleteField
            testName={`${testName}-house`}
            displayValue={CAST.String(house)}
            onFocus={this.handleFocus}
            onKeyDown={this.handleKeyDown}
            readOnly={this.isLocked(FIELD.WAYPOINT_HOUSE)}
            onBlur={this.handleHouseBlur}
            onChange={this.handleHouseChange}
            containerClassName='house'
            filter={houseFilter}
            placeholder={__('HOUSE')}
            dataProvider={houseProvider}
            disabled={disabled}
          />

          <FieldIcon testName={`${testName}-marker`} className={cn({ positive: (latitude && longitude) })} icon='map-marker-alt' onClick={this.handleMarkerClick} disabled={disabled} />
        </div>

        {/*
        Здесь есть небольшой костыль связанный с вёрсткой: ПОЛЕ ENTRANCE НИКОГДА НЕ NULL, оно только скрывается, чтобы
        строка с адресом не схлопывалась
        */}
        <div className={cn('field', 'l2', 'field-address', { focused, 'hidden-visibility': noEntrance })}>

          {noAppartment
            ? null
            : (
              <input
                {...genTestName(`${testName}-apartment`)}
                value={apartment}
                onKeyDown={this.handleKeyDown}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                onChange={this.handleApartmentChange}
                readOnly={this.isLocked(FIELD.WAYPOINT_APARTMENT)}
                className='apartment'
                placeholder={__('APARTMENT')}
                type='text'
                disabled={disabled}
              />
              )}

          <input
            {...genTestName(`${testName}-entrance`)}
            value={entrance}
            onKeyDown={this.handleKeyDown}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            readOnly={this.isLocked(FIELD.WAYPOINT_ENTRANCE)}
            onChange={this.handleEntranceChange}
            className={cn('entrance', { 'hidden-visibility': noEntrance })}
            placeholder={__('ENTRANCE')}
            type='text'
            disabled={disabled}
          />

          {noFloor
            ? null
            : (
              <input
                {...genTestName(`${testName}-floor`)}
                onKeyDown={this.handleKeyDown}
                value={floor}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                readOnly={FIELD.WAYPOINT_FLOOR}
                onChange={this.handleFloorChange}
                className='floor'
                placeholder={__('FLOOR')}
                type='text'
                disabled={disabled}
              />
              )}
        </div>

        {this._renderRegion()}
        {this._renderControls()}
      </div>
    )
  }
}

export default AddressInput
