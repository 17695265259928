/**
 * @file ContextMenu.js
 * @ignore
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Monday, 7th November 2022 8:20:43 am
 * @copyright 2015 - 2022 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { iContextMenuBuilder } from 'web-panel/types'
import type { Node, ComponentType } from 'react'
import type { ContextMenuProps } from './MenuBuilder'

import { assert } from 'web-panel/globals'
import React, { PureComponent, Suspense, lazy } from 'react'
import { connectMenu } from 'react-contextmenu'

/**
 * Context menu component
 */
class ContextMenu extends PureComponent<ContextMenuProps> {
  getPreparedMenu (builder: iContextMenuBuilder<*, *>, context: *) : ComponentType<ContextMenuProps> {
    return lazy(async () => {
      await builder.prepare(context)
      const module = import('./MenuBuilder.js')
      return module
    })
  }

  componentWillUnmount () {
    this.props.builder.dispose()
  }

  render () : Node {
    const { id, builder, context } = this.props
    assert(Boolean(builder), builder, 'Menu builder is specified')

    const MenuBuilder = this.getPreparedMenu(builder, context)
    const MenuComponent = connectMenu(id)(MenuBuilder)

    return (
      <Suspense fallback={null}>
        <MenuComponent builder={builder} />
      </Suspense>
    )
  }
}

export default ContextMenu
