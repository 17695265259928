/**
 * @file ColumnsManagerView.js
 * @project Web-panel
 * @ignore
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 2nd July 2020 5:10:46 pm
 * @copyright 2015 - 2020 SKAT LLC, Delive LLC
 */
import type { iLogger, iExposedView } from 'web-panel/types'
import type { ColumnDefinition, WaterlineQuery, iDataProvider } from 'web-panel-essentials/types'
import type { ExtendedColumnDefinition } from './ColumnDefinitionProvider'

import * as React from 'react'
import CheckBox from 'web-panel/views/forms/components/CheckBox'
import { Col, Row } from 'web-panel/components'
import { Inject } from 'web-panel/serviceLocator'
import autoBind from 'react-autobind'
import Grid, { SORT_MODE } from 'web-panel/views/grid/Grid'
import { Button } from 'web-panel/views/forms/components'
import { CAST } from 'web-panel-essentials/misc'
import ColumnsDataManager, { GRID_MANAGER_EVENT } from './ColumnDataManager'
import ColumnDefinitionProvider from './ColumnDefinitionProvider'
import { __ } from 'web-panel/globals'
import sortBy from 'lodash/sortBy'

type Props = {
  columns: Object,
  initialFilter: ?WaterlineQuery
}

type State = {
  disabled: boolean,
  selected: Object,
  visible: boolean,
  alias: string,
  width: number
}

class ColumnsManagerView extends React.Component<Props, State> implements iExposedView<ColumnDefinition[]> {
  @Inject logger: iLogger

  dataProvider: iDataProvider<ExtendedColumnDefinition>
  columns: Array<ColumnDefinition>
  grid: Object
  dataManager: Object
  constructor (props: any) {
    super(props)
    this.state = {
      disabled: true,
      selected: null,
      visible: false,
      alias: '',
      width: 0
    }

    autoBind(this)
    this.grid = React.createRef()
    const { columns, initialFilter } = this.props

    this.dataProvider = new ColumnDefinitionProvider(columns, initialFilter)
    this.dataManager = new ColumnsDataManager(this.dataProvider, columns)
  }

  async componentDidMount () {
    this.dataManager.on(this.dataManager.EVENT.UPDATE_SELECTION, this.handleSelectionChanged.bind(this))
  }

  async data () {
    let columns = await this.dataProvider.get()
    columns = sortBy(columns, 'position')
    return columns
  }

  async command () {

  }

  async onMoveUp () {
    const record = this.state.selected
    if (!record) return
    this.dataManager.emit(GRID_MANAGER_EVENT.UP, { record })
  }

  async onMoveDown () {
    const record = this.state.selected
    if (!record) return
    this.dataManager.emit(GRID_MANAGER_EVENT.DOWN, { record })
  }

  async handleOnChangeAlias ({ target }: Object) {
    const { selected, visible, width } = this.state
    if (!selected) return

    const alias = CAST.String(target.value)
    this.setState({ alias })
    this.dataManager.emit(GRID_MANAGER_EVENT.ALTER_NAME, {
      record: {
        ...selected,
        visible,
        width
      },
      value: alias
    })
  }

  async handleOnChangeWidth ({ target }: Object) {
    const { selected, visible, alias } = this.state

    if (!selected) return
    let { value } = target

    if (isNaN(value)) return
    value = CAST.Number(value)
    if (value > 10000) return
    if (value < 0) return

    this.setState({ width: value })
    this.dataManager.emit(GRID_MANAGER_EVENT.WIDTH, {
      record: {
        ...selected,
        visible,
        alias
      },
      value
    })
  }

  async handleChangeVisible () {
    let { selected, alias, visible, width } = this.state
    if (!selected) return

    visible = !visible
    this.setState({ visible })
    this.dataManager.emit(GRID_MANAGER_EVENT.VISIBLE, {
      record: {
        ...selected,
        alias,
        width
      },
      value: visible
    })
  }

  async handleSelectionChanged (records: Array<Object>) {
    const [record] = records
    if (record) {
      this.setState({
        disabled: records.length !== 1,
        selected: record,
        visible: record.visible,
        alias: record.alias,
        width: record.width
      })
    } else { this.setState({ disabled: true, selected: null }) }
  }

  render () : React.Node {
    const { visible, disabled, width, alias } = this.state

    return (
      <div className='h100 half-padding'>
        <Row>
          <Col size={12}>
            {__('SELECT_COLUMNS_FOR_CHANGE')}
          </Col>
        </Row>
        <Row>
          <Col size={9}>
            <div className='w100' style={{ height: '395px', paddingTop: '4px' }}>
              <Grid
                autofetch
                noSettings
                ref={this.grid}
                name='grid-columns'
                dataManager={this.dataManager}
                dataProvider={this.dataProvider}
                sorting={{ position: SORT_MODE.DESC }}
                onSelectionChanged={this.handleSelectionChanged}
              />
            </div>
          </Col>
          <Col size={3}>
            <Row>
              <Col size={12}>
                <Button disabled={disabled} action={this.onMoveUp} label={__('MOVE_UP')} icon='caret-up' />
              </Col>
            </Row>
            <Row>
              <Col size={12}>
                <Button disabled={disabled} action={this.onMoveDown} label={__('MOVE_DOWN')} icon='caret-down' />
              </Col>
            </Row>
            <Row />
            <Row>
              <Col size={12}>
                <label>
                  {__('COLUMN_WIDTH')}:<br />
                  <input disabled={disabled} type='text' value={width} onChange={this.handleOnChangeWidth} />
                </label>
              </Col>
            </Row>
            <Row>
              <Col size={12}>
                <label>
                  {__('COLUMN_ALTER_NAME')}:<br />
                  <input type='text' disabled={disabled} value={alias} onChange={this.handleOnChangeAlias} />
                </label>
              </Col>
            </Row>
            <Row>
              <Col size={12}>
                <div className='half-left-padding'>
                  <CheckBox disabled={disabled} label={__('COLUMN_VISIBLE')} value={visible !== null ? visible : false} onChange={this.handleChangeVisible} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
}

export default ColumnsManagerView
