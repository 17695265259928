/**
 * @file PhoneField.js
 * @project Web-panel
 * @ignore
 * @author Pavel Shabardin (<bigbn@mail.ru>) Wednesday, 3rd November 2021 6:55:08 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow strict
 */

import type { ReactRef, iEventEmitter } from '../types'
import type { PhoneFieldProps } from './types'

import * as React from 'react'
import autoBind from 'react-autobind'
import Field from './Field'
import Action from './Action'
import { Inject } from './../serviceLocator'
import { CAST } from 'web-panel-essentials/misc'

/**
 * Input widget to work with phone numbers.
 */
class PhoneField extends React.PureComponent<PhoneFieldProps> {
  @Inject globalEventBus : iEventEmitter

  field: ReactRef<typeof Field>

  constructor (props: PhoneFieldProps) {
    super(props)
    autoBind(this)
    this.field = React.createRef()
  }

  focus () {
    this.field.current?.focus()
  }

  handleCall () : void {
    const { displayValue, dialplanHeaders } = this.props
    const number = CAST.String(displayValue).trim()
    if (!number) return
    this.globalEventBus.emit('call-requested', { number, headers: dialplanHeaders })
  }

  render () : React.Node {
    const icon = 'phone'
    const { displayValue } = this.props
    const phone = CAST.String(displayValue)
    const { dialplanHeaders, children = null, ...fieldProps } = this.props
    return (
      <Field {...fieldProps} ref={this.field}>
        {children}
        <Action disabled={!phone.trim()} icon={icon} onClick={this.handleCall} />
      </Field>
    )
  }
}

export default PhoneField
