/**
 * @file GridManager.js
 * @ignore
 * @project Web-panel
 * @author Andrey Nikulin (<nikulin@scat.su>) Friday, 26th June 2020 4:23:22 pm
 * @copyright 2019 - 2020 SKAT LLC, Delive LLC
 * @flow
 */
import type { iWindowManager, iLogger, iEventEmitter, iCRUD, UserPreferences, EmitterEvents } from 'web-panel/types'
import type { ID, WaterlineQuery, iDataProvider, ProviderMeta } from 'web-panel-essentials/types'

import { Inject, Injectable } from 'web-panel/serviceLocator'
import * as React from 'react'
import { __ } from 'web-panel/globals'

import ColumnsManagerView from './manager/ColumnsManagerView'
import EventEmitter from 'web-panel/utils/EventEmitter'
import moize from 'moize'
import isArray from 'lodash/isArray'

const EVENT = {
  DATA_CHANGED: 'data-changed'
}
const storageKey = moize((gridName) => gridName ? ['columns', gridName].join('-') : null)

@Injectable('gridManager')
class GridManager<T: {}> extends EventEmitter implements iDataProvider<T>, iEventEmitter {
  @Inject windowManager: iWindowManager
  @Inject logger : iLogger
  @Inject preferencesManager : iCRUD<UserPreferences>

  gridName: string
  dataProvider: iDataProvider<T>
  localCachedMeta: ProviderMeta<T>
  indexedColumns: Object
  initialFilter: ?WaterlineQuery

  static get EVENT () : EmitterEvents {
    return EVENT
  }

  get EVENT () : EmitterEvents {
    return GridManager.EVENT
  }

  constructor () {
    super()
    this.logger.namespace = 'grid-manager'
  }

  proxy (gridName: string, dataProvider: iDataProvider<T>, initialFilter?: WaterlineQuery) {
    this.logger.info('Proxying provider meta for grid', gridName, 'with initial filter', initialFilter)

    this.gridName = gridName
    this.dataProvider = dataProvider
    this.initialFilter = initialFilter
  }

  extractIndexedColumns (originalMeta: ProviderMeta<T>) : {} | null {
    if (this.indexedColumns) return this.indexedColumns

    const columns = originalMeta.columns
    if (!columns) return null

    this.indexedColumns = columns.reduce((acc, column) => ({
      ...acc,
      [column.name]: {
        title: column.title,
        format: column.format,
        // $FlowFixMe[prop-missing]
        cellType: column.cellType
      }
    }), {})
    return this.indexedColumns
  }

  async meta (reset?: boolean) : Promise<ProviderMeta<T>> {
    if (this.localCachedMeta && !reset) {
      this.logger.info('Using local cached meta')
      return this.localCachedMeta
    }

    const originalMeta : ProviderMeta<T> = await this.dataProvider.meta()
    // We must always restore format and cell types from original meta, because it's not serializable
    const indexedColumns = this.extractIndexedColumns(originalMeta)

    if (reset) {
      await this.preferencesManager.delete(storageKey(this.gridName))
      this.localCachedMeta = originalMeta
    } else {
      const metaPrefs = await this.preferencesManager.read(storageKey(this.gridName))
      if (metaPrefs && metaPrefs.value) {
        const meta = metaPrefs.value
        if (isArray(meta.columns)) {
          this.localCachedMeta = meta
          this.logger.info('Loaded custom user settings for current grid')
        } else {
          this.logger.warn('Unable to load custom stored meta columns')
          this.localCachedMeta = originalMeta
        }
      } else this.localCachedMeta = originalMeta
    }

    this.localCachedMeta.columns = this.localCachedMeta.columns.map((column, index) => {
      let columnExtended = {
        ...column,
        id: column.name,
        position: index
      }

      if (indexedColumns && indexedColumns[column.name]) {
        columnExtended = {
          ...columnExtended,
          title: indexedColumns[column.name].title,
          cellType: indexedColumns[column.name].cellType,
          format: indexedColumns[column.name].format
        }
      } else {
        this.logger.error('Unable to restore original column', column.name)
      }
      return columnExtended
    })

    return this.localCachedMeta
  }

  async get (query: ?WaterlineQuery): Promise<Array<T>> {
    return this.dataProvider.get(query)
  }

  async getOne (id: ID) : Promise<?T> {
    const [record] = await this.get({ where: { id }, limit: 1 })
    return record
  }

  async show () {
    const id = Symbol('grid-manager')
    const buttons = [{
      name: 'cancel',
      title: __('CANCEL'),
      label: __('CANCEL'),
      action: async () => this.windowManager.close(id)
    }, {
      name: 'reset',
      title: __('RESET'),
      label: __('RESET'),
      action: async () => {
        const meta = await this.meta(true)
        this.emit(this.EVENT.DATA_CHANGED, meta)
        this.windowManager.close(id)
      }
    }, {
      name: 'save',
      title: __('SAVE'),
      label: __('SAVE'),
      isPrimary: true,
      action: async (view) => {
        await this.windowManager.hideError(id)
        const columns = [...await view.data()]
        const visible = columns.filter(({ visible }) => visible === true || visible !== false)
        if (!visible.length) {
          await this.windowManager.showError(id, __('AT_LEAST_ONE_COLUMN_MUST_BE_VISIBLE'))
          return
        }

        this.localCachedMeta = { ...this.localCachedMeta, columns }
        await this.preferencesManager.update({ name: storageKey(this.gridName), value: this.localCachedMeta })

        this.windowManager.close(id)
        this.emit(this.EVENT.DATA_CHANGED, this.localCachedMeta)
      }
    }]

    const meta = await this.meta()
    await this.windowManager.show({
      id,
      name: 'grid-manager-modal',
      label: __('GRID_MANAGER'),
      title: __('GRID_MANAGER'),
      icon: 'edit',
      view: <ColumnsManagerView columns={meta.columns} initialFilter={this.initialFilter} />,
      width: 45,
      widthUnit: 'rem',
      height: 450,
      buttons
    })

    this.windowManager.activate(id)
  }
}

export default GridManager
