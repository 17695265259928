/**
 * @file InjectDataProviderHOC.js
 * @ignore
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 22nd October 2021 6:53:06 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { Entity } from '../../types'
import type { ProviderMeta } from 'web-panel-essentials/types'
import type { ExtendedProps, _fieldNaming } from '../types'

import * as React from 'react'

const injectDataProvider = <T: Entity>(Wrapped: React.AbstractComponent<*>) : React.AbstractComponent<*> => {
  class WithDataProvider extends React.Component<ExtendedProps<T>, _fieldNaming<T>> {
    constructor (props) {
      super()
      this.state = {
        valueKey: 'value',
        displayValueKey: 'value'
      }
    }

    componentDidMount () {
      this.initMapping()
    }

    // DataProvider can dictate which keys must be used for value/displayValue
    // We must obey (
    async initMapping () : Promise<void> {
      if (this.props.dataProvider) {
        const meta : ProviderMeta<T> = await this.props.dataProvider.meta()
        const valueKey = meta.value || 'value'
        const displayValueKey = meta.displayValue || 'value'
        this.setState({ valueKey, displayValueKey })
      }
    }

    render () : React.Node {
      const { valueKey, displayValueKey } = this.state
      // $FlowFixMe
      const ref = this.props.forwardedRef
      return (
        <Wrapped {...this.props} ref={ref} valueKey={valueKey} displayValueKey={displayValueKey} />
      )
    }
  }

  return React.forwardRef((props, ref) => <WithDataProvider {...props} forwardedRef={ref} />)
}

export default injectDataProvider
