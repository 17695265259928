/**
 * @file About.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Monday, 8th July 2019 5:40:45 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow strict
 */

import type { iLogger, iExposedView } from '../types'
import type { ID } from 'web-panel-essentials/types'
import { Inject } from '../serviceLocator'
import { __ } from '../globals'

import { FORMAT } from 'web-panel-essentials/misc'
import * as React from 'react'
import isEmpty from 'lodash/isEmpty'
import marked from 'marked'
import autoBind from 'react-autobind'
import { Col, Row, If } from 'web-panel/components'
import { Tab, Tabs } from 'web-panel/views/forms/components/TabView'

const get = (object, key) => {
  const lang = global.LOCALE.code
  return FORMAT.String(object[key][lang || 'default'])
}

type State = {
  activeTab: ID,
  version: string,
  released: string,
  branding: {[string]: string},
  defaults: {[string]: string}
}

class About extends React.Component<{}, State> implements iExposedView<void> {
  @Inject logger : iLogger

  constructor () {
    super()
    autoBind(this)
    const released = window.SERVER.RELEASED
    const version = window.SERVER.VERSION
    const branding = window.BRANDING

    const defaults = {
      companyName: 'ООО СКАТ',
      address: __('OFFICE_ADDRESS'),
      website: 'https://scat.su',
      phone: '8 800 775-06-39',
      email: 'info@scat.su'
    }

    this.state = {
      released,
      version,
      defaults,
      branding,
      activeTab: 'changelog'
    }
  }

  async data () {

  }

  async command () {

  }

  handleTabChange (tabId: ID) : void {
    this.setState({ activeTab: tabId })
  }

  renderTitle () : React.Node {
    const { defaults, branding } = this.state

    if (!isEmpty(branding)) {
      return (
        <>
          <h2 className='no-bottom-margin'>{get(branding, 'name')}</h2>
          <p>{get(branding, 'description')}</p>
          <h4 className='full-bottom-margin'>{get(branding, 'companyName')}</h4>
        </>
      )
    } else {
      return (
        <>
          <span className='a-decoration'>A</span>
          <h4>{__('ABOUT_DEVELOPER')}</h4>
          <h3 className='full-bottom-margin'>{__('DEVELOPED_BY') + ' ' + defaults.companyName}</h3>
        </>
      )
    }
  }

  render () : React.Node {
    const { activeTab, version, released, defaults, branding } = this.state
    const href = branding.website || defaults.website
    const mail = `mailto:${branding.email || defaults.email}`
    const brandingExists = !isEmpty(branding)

    return (
      <div className='h400px half-padding'>
        <div className='about-view'>
          <div className='stats-title'>
            {this.renderTitle()}
          </div>
          <div className='about-fields'>
            <Row className='adaptive-dual'>
              <Col size={4}>{__('PRODUCT_VERSION')}</Col>
              <Col size={8}><b>{version} ({__('FROM_DATE')} {released})</b></Col>
            </Row>
            <Row className='adaptive-dual'>
              <Col size={4}>{__('OFFICIAL_SITE')}</Col>
              <Col size={8}><a href={href} target='_blank' rel='noreferrer'>{brandingExists ? branding.website : defaults.website}</a></Col>
            </Row>
            <Row className='adaptive-dual'>
              <Col size={4}>{__('ADDRESS')}</Col>
              <Col size={8}>{brandingExists ? get(branding, 'address') : defaults.address}</Col>
            </Row>
            <Row className='adaptive-dual'>
              <Col size={4}>{__('PHONE')}</Col>
              <Col size={8}>{brandingExists ? branding.phone : defaults.phone}</Col>
            </Row>
            <If condition={!brandingExists}>
              <Row className='adaptive-dual'>
                <Col size={4}>{__('DOCS')}</Col>
                <Col size={8}><a href='http://docs.scat.su' target='_blank' rel='noreferrer'>http://docs.scat.su</a></Col>
              </Row>
            </If>
            <Row className='adaptive-dual'>
              <Col size={4}>{__('SUPPORT')}</Col>
              <Col size={8}><a href={mail}>{brandingExists ? branding.email : defaults.email}</a></Col>
            </Row>
          </div>
          <If condition={!brandingExists}>
            <Tabs onActivate={this.handleTabChange} active={activeTab}>
              <Tab label={__('CHANGELOG')} id='changelog'>
                <div className='changelog' dangerouslySetInnerHTML={{ __html: marked(window.SERVER.CHANGELOG) }} />
              </Tab>
              <Tab label={__('TRANSLATIONS')} id='translations'>
                <div className='half-padding translations'>
                  <h4>Английский (English)</h4>
                  <div>Deepesh Soni - deepesh.soni@mail.ru</div>
                  <h4>Каза́хский (Kazakh, Qazaqşa)</h4>
                  <div>Нурлыбаев Дастан Кожаба улы - rasyl.kosh@gmail.com</div>
                </div>
              </Tab>
              {/* <Tab label={__('LICENSES')} id='licenses'>
                <div>Licenses</div>
              </Tab> */}
            </Tabs>
          </If>
        </div>
      </div>
    )
  }
}

export default About
