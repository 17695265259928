/**
 * @file If.js
 * @ignore
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 21st December 2021 1:12:37 pm
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow strict
 */

import * as React from 'react'

type Props = {
  condition: boolean,
  children: React.Node
}

function If ({ condition, children }: Props) : React.Node | null {
  if (condition) return children
  return null
}

export default If
