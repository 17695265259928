/**
 * @file DateTime.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Monday, 15th July 2019 12:14:41 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow
 */
import type { ISODateTime } from 'skat-js/types'

import 'react-datepicker/dist/react-datepicker.css'
import './datePickerOverrides.less'
import formatISO from 'date-fns/formatISO'
import * as React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { __, isMobileMode, getCurrentDateTimeLocaleCatalog } from '../../../globals'
import isNil from 'lodash/isNil'
import autoBind from 'react-autobind'
import { createPortal } from 'react-dom'

type Props = {
  value: ?Date | ?ISODateTime | null,
  tabIndex?: number,
  minDate?: Date,
  minTime?: Date,
  maxTime?: Date,
  disabled?: boolean,
  dateFormat?: string,
  showTimeSelect?: boolean,
  showTimeSelectOnly?: boolean,
  className?: string,
  placeholder?: string,
  onChange: (value: ISODateTime | null) => void | Promise<void>
}

registerLocale('current', getCurrentDateTimeLocaleCatalog())
class DateTimeComponent extends React.PureComponent<Props> {
  input: React.ElementRef<any>

  constructor (props: Props) {
    super(props)
    autoBind(this)
    this.input = React.createRef()
  }

  handleChange (value: Date) : void {
    this.props.onChange(isNil(value) ? null : formatISO(value))
  }

  focus () : void {
    if (this.input.focus) this.input.focus()
  }

  render () : React.Node {
    const { value, disabled, minDate, minTime, maxTime, className, placeholder, tabIndex = 0 } = this.props
    const mobileMode = isMobileMode()

    return (
      <DatePicker
        ref={this.input}
        locale='current'
        tabIndex={tabIndex}
        placeholderText={placeholder}
        withPortal={mobileMode}
        disabled={disabled}
        minDate={minDate}
        minTime={minTime}
        maxTime={maxTime}
        selected={value ? new Date(value) : null}
        onChange={this.handleChange}
        className={className}
        showTimeSelect={this.props.showTimeSelect}
        showTimeSelectOnly={this.props.showTimeSelectOnly}
        dateFormat={this.props.dateFormat || 'dd.MM HH:mm'}
        timeIntervals={10}
        timeFormat='HH:mm'
        timeCaption={__('TIME')}
        popperContainer={mobileMode ? undefined : ({ children }) => createPortal(children, document.body)}
      />
    )
  }
}

export default DateTimeComponent
