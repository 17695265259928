/**
 * @file NumericField.js
 * @ignore
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 25th November 2021 6:49:00 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow strict
 */
/* global SyntheticKeyboardEvent, HTMLInputElement */

import type { ReactRef, iEventEmitter, CombinedValue } from '../types'
import type { NumericFieldProps } from './types'

import * as React from 'react'
import autoBind from 'react-autobind'
import Field from './Field'
import If from './If'
import Action from './Action'
import { Inject } from './../serviceLocator'
import { CAST } from 'web-panel-essentials/misc'
import InlineCalculator from './InlineCalculator'

type State = {
  inlineCalculatorEnabled: boolean
}

/**
 * Field to work with numeric data. With inline calculator included
 */
class NumericField extends React.PureComponent<NumericFieldProps, State> {
  @Inject globalEventBus : iEventEmitter

  field: ReactRef<typeof Field>

  constructor (props: NumericFieldProps) {
    super(props)
    autoBind(this)
    this.field = React.createRef()
    this.state = {
      inlineCalculatorEnabled: false
    }
  }

  focus () {
    this.field.current?.focus()
  }

  handleKeyPress (event: SyntheticKeyboardEvent<HTMLInputElement>) {
    const char = String.fromCharCode(event.charCode)
    const activators = '+-*/='
    if (activators.includes(char) && !this.props.hideInlineCalculator) this.handleCalculatorToggle()
    if (this.props.onKeyPress) this.props.onKeyPress(event)
  }

  handleChange (changes: CombinedValue) : void {
    const { onChange, limit } = this.props
    if (isFinite(changes.displayValue)) {
      if (limit) {
        const numericValue = CAST.Number(changes.displayValue)
        const [min, max] = limit
        if (numericValue > max) return
        if (numericValue < min) return
      }
      onChange(changes)
    }
  }

  handleCalculatorToggle () : void {
    const inlineCalculatorEnabled = !this.state.inlineCalculatorEnabled
    this.setState({ inlineCalculatorEnabled })
  }

  handleSubmitEvaluated (evaluated: ?number) {
    if (evaluated !== null) {
      const displayValue = CAST.String(evaluated)
      this.handleChange({ value: undefined, displayValue })
      const cursor = displayValue.length
      this.field.current?.preserveCursor(0, cursor)
      this.field.current?.setSelectionRange(0, cursor)
    }
  }

  handleCalculatorDestroy () : void {
    this.setState({ inlineCalculatorEnabled: false }, () => {
      // We should setState a time before,
      // because rerender will wanish our focus otherwise
      this.focus()
    })
  }

  render () : React.Node {
    const icon = 'calculator'
    const { displayValue, disabled, readOnly, children = null } = this.props
    const { inlineCalculatorEnabled } = this.state
    const { formatterOptions, limit, hideInlineCalculator, ...fieldProps } = this.props
    return (
      <>

        <Field {...fieldProps} onKeyPress={this.handleKeyPress} disabled={disabled || inlineCalculatorEnabled} ref={this.field} onChange={this.handleChange}>
          <If condition={inlineCalculatorEnabled}>
            <InlineCalculator
              value={displayValue}
              formatterOptions={formatterOptions}
              limit={limit}
              onChange={this.handleSubmitEvaluated}
              onDestroyRequested={this.handleCalculatorDestroy}
            />
          </If>

          <If condition={!hideInlineCalculator}>
            <Action disabled={disabled || readOnly} icon={icon} onClick={this.handleCalculatorToggle} />
          </If>
          {children}
        </Field>
      </>
    )
  }
}

export default NumericField
