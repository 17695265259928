/**
 * @file Option.js
 * @ignore
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 19th October 2021 5:58:10 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow strict
 */
/* global SyntheticEvent, HTMLDivElement */
import type { ReactRef } from 'web-panel/types'

import * as React from 'react'
import autoBind from 'react-autobind'
import { CAST } from 'web-panel-essentials/misc'
import cn from 'classnames'
import debounce from 'lodash/debounce'

type Props = {
  focused: boolean,
  value: mixed,
  displayValue: string,
  focused: boolean,
  onClick: (value: mixed, displayValue: string) => void
}

function isElementVisible (el, parentHolder) :boolean {
  const holder = parentHolder || document.body
  const { top, bottom, height } = el.getBoundingClientRect()
  const holderRect = holder.getBoundingClientRect()

  return top <= holderRect.top
    ? holderRect.top - top < height
    : bottom - holderRect.bottom < height
}

function scrollAlign (element) : void {
  if (!element) return
  if (!element.parentNode) return

  if (!isElementVisible(element, element.parentNode)) {
    element.parentNode.scrollTo({ top: element.offsetTop + 1, behavior: 'smooth' })
  }
}

const scrollAlingDebounced = debounce(scrollAlign, 10)

class Option extends React.PureComponent<Props> {
  ref: ReactRef<'div'>

  constructor () {
    super()
    autoBind(this)
    this.ref = React.createRef()
  }

  handleClick (event: SyntheticEvent<HTMLDivElement>) : void {
    const { value, displayValue, onClick } = this.props
    event.preventDefault()
    event.stopPropagation()
    onClick(value, CAST.String(displayValue))
  }

  handleMouseDown (event: SyntheticEvent<HTMLDivElement>) :void {
    // Use onMouseDown to prevent the onBlur from happening
    // when an internal element is selected.
    // The only caveat is that it prevents text from being selectable.
    event.preventDefault()
  }

  componentDidUpdate () {
    if (this.props.focused) {
      const el = this.ref.current
      if (el) scrollAlingDebounced(el)
    }
  }

  render () : React.Node {
    const { focused, displayValue } = this.props
    const className = cn('item', { focused })
    return (
      <div
        ref={this.ref}
        onClick={this.handleClick}
        className={className}
        onMouseDown={this.handleMouseDown}
      >
        {displayValue}
      </div>
    )
  }
}

export default Option
