/**
 * @file NotificationManager.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 10th January 2020 10:11:20 am
 * @copyright 2015 - 2020 SKAT LLC, Delive LLC
 * @flow strict
 */
/* global TimeoutID, $Shape */

import type { iLogger, iNotificationManager, iEventEmitter, NotificationMessage, asyncVoid, iGlobalEventBus, iCRUD, UserPreferences, ReactRef } from '../../types'
import type { ID } from 'web-panel-essentials/types'

import * as React from 'react'
import { Inject, Injectable } from '../../serviceLocator'
import cn from 'classnames'
import Delegate from '../../utils/Delegate'
import { NOTIFICATION, isMobileMode } from 'web-panel/globals'
import isNil from 'lodash/isNil'
import autoBind from 'react-autobind'
import Notification from './Notification'
import ThrottledComponent from './../../utils/ThrottledComponent'

const NOTIFICATIONS_MODE_PREF_NAME = 'notifications-concealed'

export type InnerMessage = {|
  ...NotificationMessage,
  onClose: () => asyncVoid,
  onDeny: () => asyncVoid,
  wasAlreadyInvokedBefore: boolean,
|}

type State = {
  compact: boolean,
  messages: InnerMessage[]
}
type Props = {}

/**
 * @class Notifications
 * @implements iNotificationManager
 */
class Notifications extends ThrottledComponent<Props, State> implements iNotificationManager {
  @Inject logger : iLogger
  @Inject globalEventBus : iGlobalEventBus & iEventEmitter
  @Inject preferencesManager : iCRUD<UserPreferences>

  ready: Promise<void>
  timers: { [ID]: TimeoutID | null }
  container: ReactRef<'div'>
  lastNotifications: Set<ID>
  deniedNotifications: Set<ID>

  constructor (props: Props) {
    super(props)
    autoBind(this)
    this.logger.info('Initializing notification manager')

    this.container = React.createRef()
    this.timers = {}
    this.state = {
      compact: false,
      messages: []
    }

    this.lastNotifications = new Set()
    this.deniedNotifications = new Set()

    this.globalEventBus.registerEvent('new-notification')
    this.globalEventBus.registerEvent('toggle-notifications-mode')
    this.globalEventBus.on('notifications-mode-changed', this.toggleMode.bind(this))
  }

  componentDidMount () {
    this.toggleMode()
  }

  componentDidCatch (error, errorInfo) {
    // You can also log the error to an error reporting service
    this.logger.error(error, errorInfo)
  }

  /**
   * @lends NotificationManager
   */
  async show ({ id = Symbol('id'), message, type = NOTIFICATION.DEFAULT, persistent = false, unclosable = false, timeout = 5000, details, progress, buttons, testName, icon }: NotificationMessage) : Promise<ID> {
    if (this.deniedNotifications.has(id)) return id

    if (this.stateValues.messages.find(message => message.id === id)) {
      await this.update(id, { icon, message, details, type, persistent, timeout, progress, buttons })
    } else {
      const messages = [...this.stateValues.messages, {
        id,
        icon,
        testName,
        message,
        details,
        type,
        buttons,
        progress,
        unclosable: isMobileMode() ? false : unclosable, // Иначе оно очень мешает нормально работать
        wasAlreadyInvokedBefore: this.lastNotifications.has(id),
        onDeny: () => {
          this.deniedNotifications.add(id)
          this.destroy(id)
        },
        onClose: () => this.destroy(id)
      }]

      this.setState({ messages })
      this.globalEventBus.emit('new-notification', {
        notification: {
          id,
          icon,
          message,
          details,
          type,
          buttons
        }
      })
    }

    this.lastNotifications.add(id)

    if (!persistent) {
      if (this.timers[id]) clearTimeout(this.timers[id])
      this.timers[id] = setTimeout(() => this.destroy(id), timeout)
    }

    const container = this.container.current
    if (container) container.scrollTo({ top: container.scrollHeight, behaviour: 'smooth' })
    return id
  }

  /**
   * @lends NotificationManager
   */
  async update (id: ID, params: $Shape<NotificationMessage>) : Promise<void> {
    const messages = this.stateValues.messages.map((m) => {
      if (m.id === id) return { ...m, ...params }
      return m
    })
    this.setState({ messages })
  }

  /**
   * @lends NotificationManager
   */
  async destroy (id: ID) : Promise<void> {
    clearTimeout(this.timers[id])
    this.timers[id] = null
    const messages = this.stateValues.messages.filter(m => m.id !== id)
    this.setState({ messages })
  }

  /**
   * @lends NotificationManager
   */
  async toggleMode () {
    const newMode = await this.preferencesManager.read(NOTIFICATIONS_MODE_PREF_NAME)
    if (newMode && !isNil(newMode.value)) this.setState({ compact: !newMode.value })
  }

  render () : React.Node {
    const { messages, compact } = this.state
    const className = cn('all-notifications', { compact })
    return (
      <div className={className} ref={this.container}>
        {messages.map((message, index) => <Notification key={index} {...message} />)}
      </div>
    )
  }
}

/**
 * Coo
 * @alias injectable:notificationManager
 * @class NotificationManager
 * @implements iNotificationManager
 */
@Injectable('notificationManager', true)
class NotificationManager extends Delegate {
  @Inject layout: *
  ready: Promise<void>

  constructor () {
    super()
    this.ready = new Promise(async (resolve) => { //eslint-disable-line
      const view = await this.layout.renderView(<Notifications />, 'flashes-region')
      this.becomeProxyOf(view)
      resolve()
    })
  }
}

export default NotificationManager
