/**
 * @file ReferenceView.js
 * @project Web-panel
 * @ignore
 * @author Pavel Shabardin (<bigbn@mail.ru>) Wednesday, 16th December 2020 7:21:10 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow
 */
import type { WaterlineQuery, iDataProvider, iDataManager } from 'web-panel-essentials/types'
import type { Entity, iExposedView, ReactRef } from 'web-panel/types'

import * as React from 'react'
import Grid from './grid/Grid'
import autoBind from 'react-autobind'

/**
* Displays a grid with records from specified data provider
* @ignore
* @param {LiteralObject} options
* @class Tab
* @extends View
* @copyright SKAT Ltd 2015-2016
* @since 1.0.0
*/

type Props<T> = {
  dataProvider: iDataProvider<T>,
  dataManager?: ?iDataManager<T>,
  filter: ?WaterlineQuery,
  onDoubleClick: ({ record: T }) => void
}

class ReferenceView<T: Entity> extends React.PureComponent<Props<T>> implements iExposedView<T[]> {
  grid: ReactRef<typeof Grid>

  constructor () {
    super()
    autoBind(this)
    this.grid = React.createRef()
  }

  async command () {}
  async data () : Promise<T[]> {
    const grid = this.grid.current
    if (grid) return grid.getSelectedRecords()
    else return []
  }

  handleDoubleClick (...args: any) {
    if (this.props.onDoubleClick) this.props.onDoubleClick(...args)
  }

  render () : React.Node {
    const { filter, dataProvider, dataManager } = this.props
    return (
      <div className='referenceView h400px'>
        <Grid
          name='reference'
          onDoubleClick={this.handleDoubleClick}
          ref={this.grid}
          autofetch
          dataProvider={dataProvider}
          dataManager={dataManager}
          filter={filter}
        />
      </div>
    )
  }
}

export default ReferenceView
