/**
 * @file filtration.js
 * @ignore
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Monday, 29th July 2019 5:28:54 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow
 */

import type { WaterlineWhere, ExtendedWaterlineWhere } from 'web-panel-essentials/types'
import reduce from 'lodash/reduce'
import isObject from 'lodash/isObject'

const isISODate = (v) => {
  if (v) {
    v = String(v)
    if (v[4] === '-' && v[7] === '-') return true
  }
  return false
}
const isArray = (v) => Array.isArray(v)
const isNumber = (v) => Number.isFinite(v)
const str = (v) => String(v)
const bool = (v) => Boolean(v)

const bothAreDates = (a, b) => isISODate(a) && isISODate(b)

const GOEC = (a, b) => bothAreDates(a, b) ? (new Date(a) >= new Date(b)) : (a >= b)
const LOEC = (a, b) => bothAreDates(a, b) ? (new Date(a) <= new Date(b)) : (a <= b)
const GC = (a, b) => bothAreDates(a, b) ? (new Date(a) > new Date(b)) : (a > b)
const LC = (a, b) => bothAreDates(a, b) ? (new Date(a) < new Date(b)) : (a < b)

const normalize = (string: string, locale: ?string) => {
  const value = str(string).trim()
  if (isISODate(value)) return value
  if (locale) return value.toLocaleLowerCase(locale)
  return value.toLowerCase()
}

function handleExpression (row: Object, expression: ?ExtendedWaterlineWhere | ?WaterlineWhere, options?: {forceValueCast?: Function, locale?: string}) : boolean {
  return bool(reduce(expression, (rowAllowed, expression, columnName) => {
    let columnAllowed : boolean

    if (columnName === 'and') return bool(reduce(expression, (allowed, expression) => allowed && handleExpression(row, expression, options), true))
    if (columnName === 'or') return bool(reduce(expression, (allowed, expression) => allowed || handleExpression(row, expression, options), false))

    if (isObject(expression)) {
      const columnValue = row[columnName]
      let value = isNumber(columnValue) || isArray(columnValue) ? columnValue : normalize(columnValue, options?.locale)
      if (options && options.forceValueCast) value = options.forceValueCast(value)

      columnAllowed = bool(reduce(expression, (acc, filterValue, operator: string) => {
        if (!(columnName in row)) return false

        let castedValue = filterValue
        if (isArray(filterValue)) castedValue = filterValue.map(v => isNumber(v) ? v : normalize(v, options?.locale))
        else if (!isNumber(filterValue)) castedValue = normalize(filterValue, options?.locale)

        if (options && options.forceValueCast) castedValue = options.forceValueCast(castedValue)

        switch (operator) {
          case 'in':
            return acc && castedValue.length && castedValue.includes(value)
          case 'ilike':
            return acc && value.includes(castedValue)
          case '==' && 'is':
            return acc && (value === castedValue)
          case '>=':
            return acc && GOEC(value, castedValue)
          case '<=':
            return acc && LOEC(value, castedValue)
          case '!=':
            return acc && (value !== castedValue)
          case '<':
            return acc && LC(value, filterValue)
          case '>':
            return acc && GC(value, castedValue)
          case 'contains':
            return acc && value.length && value.includes(filterValue)
        }
      }, true))
    } else {
      columnAllowed = str(row[columnName]) === str(expression)
    }
    return rowAllowed && columnAllowed
  }, true))
}

/**
 * @ignore
 * @param row
 * @param expression
 * @param options `forceValueCast` is a function to use for casting values, `locale` is used to collate to specific locale(toLocaleLowerCase). Otherwise, default(toLowerCase) is used.
 * @returns {boolean}
 */
function rowAllowed (row: Object, expression: ?ExtendedWaterlineWhere | ?WaterlineWhere, options?: {forceValueCast?: Function, locale?: string}) : boolean {
  if (!expression) return true
  return handleExpression(row, expression, options)

  // if (expression.or) {
  //   return Boolean(reduce(expression.or, (allowed, expression) => allowed || handleExpression(row, expression, forceValueCast), false))
  // } else return handleExpression(row, expression, forceValueCast)
}

global.rowAllowed = rowAllowed

export { handleExpression, rowAllowed }
