/**
 * @file TabView.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Wednesday, 11th December 2019 2:21:04 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow
 */
import type { ID } from 'web-panel-essentials/types'

import * as React from 'react'
import cn from 'classnames'
import { ContextMenuTrigger } from 'react-contextmenu'

import { __, isMobileMode, genTestName } from './../../../globals'
import flattenChildren from 'react-keyed-flatten-children'

import { CAST } from 'web-panel-essentials/misc'
import autoBind from 'react-autobind'
import { Icon } from 'web-panel/components'

export type TabProps = {
  id: ID,
  label: React.Node,
  children?: React.Element<any> | null,
  freeze?: boolean,
  conceal?: boolean,
  className?: string
}

export type HeaderProps = {
  id: ID,
  contextMenuId: ?string,
  label: string,
  active: boolean,
  freeze: boolean,
  index: number,
  onActivate: (ID) => void,
  onContextMenuClick?: () => void,
  testName?: string
}

class TabHeader extends React.PureComponent<HeaderProps> {
  constructor () {
    super()
    autoBind(this)
  }

  handleActivate () : void {
    this.props.onActivate(this.props.id)
  }

  render () {
    const { id, index, label, freeze, onContextMenuClick, contextMenuId, testName, active } = this.props
    return (
      <ContextMenuTrigger
        id={contextMenuId}
        collect={(prop) => prop}
        tabId={id}
        freeze={freeze}
        onItemClick={onContextMenuClick}
      >
        <div
          {...genTestName(CAST.String(index), testName)}
          className={cn('tab-header', { active })}
          onClick={this.handleActivate}
        >
          {label}
        </div>
      </ContextMenuTrigger>
    )
  }
}

/**
 * Basic tab component
 * Allows to pass custom props to Tabs context menu
 * because with standard html div element you can't do it
 */
class Tab extends React.PureComponent<TabProps> {
  static get defaultProps () : {} {
    return {
      tabId: Symbol('id'),
      label: __('NEW_TAB')
    }
  }

  render () : React.Node {
    const { children, conceal, className } = this.props
    return (
      <div className={cn('tab', className, { conceal: conceal })}> {
        React.cloneElement(React.Children.only(children), { conceal })
      }
      </div>
    )
  }
}

export type Props = {
  active: ID,
  onActivate: (tabId:ID) => void,
  children: Array<React.Node | null>,
  rerender?: boolean,
  createTab?: Function,
  saveTabs?: Function,
  onContextMenuClick?: Function,
  contextMenuId?: string,
  className?: string,
  testName?: string
}

export type State = {
  tabRefs: {}
}

/**
 * Tab layout
 * @example
 *  <Tabs createTab={this.createTab} contextMenuId={MENU_ID} onContextMenuClick={this.onContextMenuClick}>
 *   <Tab tabId={tab.id} removalDenied={tab.removalDenied} label={tab.title}>
 *     <Grid dataProvider={this.ordersProvider} filter={tab.where} autofetch />
 *   </Tab>
 * </Tabs>
 */
class Tabs extends React.PureComponent<Props> {
  constructor () {
    super()
    autoBind(this)
  }

  static get defaultProps () : {} {
    return {
      contextMenuId: '#'
    }
  }

  handleActivate (id: ID) {
    this.props.onActivate(id)
  }

  renderCreateButton () : React.Node {
    const { createTab } = this.props
    if (!createTab) return null
    if (isMobileMode()) return null
    else {
      return (
        <div {...genTestName('create-tab-button')} className='action' onClick={() => createTab()}>
          <Icon className='icon' icon='plus' />
        </div>
      )
    }
  }

  renderSaveButton () : React.Node {
    const { saveTabs } = this.props
    if (!saveTabs) return null
    if (isMobileMode()) return null

    else {
      return (
        <div className='action' onClick={() => saveTabs()}>
          <Icon className='icon' icon='save' />
        </div>
      )
    }
  }

  render () : React.Node {
    const { active, className, rerender, children, contextMenuId, onContextMenuClick, testName } = this.props
    const childrens = flattenChildren(children).filter(o => Boolean(o))
    const joinedClassName = cn('tab-view', className)
    return (
      <div className={joinedClassName}>
        <div className='headers'>
          <div className='labels'>
            {childrens.map((child, index) => {
              const active = this.props.active === child.props.id
              return (
                <TabHeader
                  key={index}
                  index={index}
                  id={child.props.id}
                  label={child.props.label}
                  freeze={child.props.freeze}
                  testName={testName}
                  contextMenuId={contextMenuId}
                  onContextMenuClick={onContextMenuClick}
                  active={active}
                  onActivate={this.handleActivate}
                />
              )
            })}
          </div>
          <div className='actions'>
            {this.renderCreateButton()}
            {this.renderSaveButton()}
          </div>
        </div>
        <div className='dynamic-tab-body'>
          {
            rerender
              ? childrens.find(child => child.props.id === active)
              : childrens.map((child, index) => React.cloneElement(child, { conceal: child.props.id !== active }))
          }
        </div>
      </div>
    )
  }
}

export { Tab, Tabs }
