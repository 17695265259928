/**
 * @file Select.js
 * @ignore
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Wednesday, 13th October 2021 10:25:11 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow strict
 */
/* global SyntheticEvent, SyntheticKeyboardEvent, HTMLElement, HTMLInputElement, TimeoutID */

import type { ReactRef, Entity } from '../types'
import type { _fieldNaming, SelectProps } from './types'

import * as React from 'react'
import classnames from 'classnames'

import autoBind from 'react-autobind'
import Field from './Field'
import Action from './Action'
import DropdownMenu from './DropdownMenu'
import injectDataProvider from './HOCs/injectDataProvider'
import { CAST } from 'web-panel-essentials/misc'
import { getCurrentLocale } from 'web-panel/globals'

type Props<T> = {|
  ...SelectProps<T>,
  ..._fieldNaming<T>
|}

type State<T> = {
  items: T[],
  focusedItemIndex: number,
  pending: boolean
}

/**
 * Select is used if you want to allow the user to pick one from multiple options.
 * Options must be provided by {@link iDataProvider} instance
 *
 * @function
 * @example <caption>Import</caption>
 * import { Select } from 'web-panel/components'
 * @example <caption>Usage</caption>
 * import type { Node } from 'react'
 * import type { CombinedValue } from 'web-panel/types'
 * import type { ID, iDataProvider, iNullObjectFactory } from 'web-panel-essentials/types'
 *
 * import React from 'react'
 * import { Row, Col, Select, Required } from 'web-panel/components'
 * import { __ } from 'web-panel/globals'
 *
 * type Cat = {|
 *     id: ID,
 *     name: string
 * |}
 *
 * type Props = {}
 * type State = {|
 *     cat: Cat
 * |}
 *
 *
 * class CatsProvider implements iDataProvider<Cat>, iNullObjectFactory<Cat> {
 *     get nullObject () {
 *         return {
 *             id: null,
 *             name: ''
 *         }
 *     }
 *
 *     async get() : Promise<Cat[]> {
 *         return [
 *             { id: 1, name: 'Barsik' },
 *             { id: 2, name: 'Vasiliy' }
 *         ]
 *     }
 * }
 *
 * class CatChoosingForm extends React.Component<Props, State> {
 *     dataProvider: iDataProvider<Cat>
 *
 *     constructor(props) {
 *         super(props)
 *         this.dataProvider = new CatsProvider()
 *         this.state = {
 *             cat: this.dataProvider.nullObject
 *         }
 *     }
 *
 *     async handleCatChange (changes: CombinedValue) : Promise<void> {
 *         const cat = await this.dataProvider.getOne(changes.value)
 *         this.setState({ cat })
 *     }
 *
 *     render() : Node {
 *         const { cat } = this.state
 *         return (
 *             <Row>
 *                 <Col size={6}>
 *                     <Required>{__('SELECT_A_CAT')}</Required>
 *                 </Col>
 *                 <Col size={6}>
 *                     <Select
 *                         value={cat.id}
 *                         displayValue={cat.name}
 *                         onChange={this.handleCatChange}
 *                         dataProvider={this.dataProvider}
 *                     />
 *                 </Col>
 *             </Row>
 *         )
 *     }
 * }
 *
 */
class Select<T: Entity> extends React.PureComponent<Props<T>, State<T>> {
  field: ReactRef<typeof Field>
  bufferedSearchTerm: string
  bufferTimeout: TimeoutID

  constructor (props: Props<T>) {
    super(props)
    autoBind(this)
    this.state = {
      pending: false,
      items: [],
      focusedItemIndex: 0
    }
    this.bufferedSearchTerm = ''
    this.field = React.createRef()
  }

  focus () {
    this.field.current?.focus()
  }

  async handleOpenMenu () : Promise<void> {
    this.setState({ pending: true })
    const { dataProvider, filter, comparator, value, valueKey } = this.props
    let { focusedItemIndex } = this.state

    let items = await dataProvider.get(filter)
    if (comparator) items = items.sort(comparator)
    if (value) focusedItemIndex = items.findIndex((item) => item[valueKey] === value)

    this.setState({ items, focusedItemIndex, pending: false })
  }

  handleKeyPress (event: SyntheticKeyboardEvent<HTMLInputElement>) {
    const { displayValueKey, onKeyPress } = this.props
    const { items } = this.state

    const char = String.fromCharCode(event.charCode)
    this.bufferedSearchTerm += char.toLocaleLowerCase(getCurrentLocale())

    clearTimeout(this.bufferTimeout)
    if (this.bufferedSearchTerm && items.length) {
      const index = items.findIndex((item) => CAST.String(item[displayValueKey]).toLocaleLowerCase(getCurrentLocale()).startsWith(this.bufferedSearchTerm))
      if (index !== -1) this.setState({ focusedItemIndex: index })
    }

    this.bufferTimeout = setTimeout(() => {
      this.bufferedSearchTerm = ''
    }, 500)

    if (onKeyPress) onKeyPress(event)
  }

  handleMenuOutClick () : void {
    this.resetState()
  }

  handleClick (event: SyntheticEvent<HTMLElement>) {
    const { disabled, readOnly, onClick } = this.props
    if (disabled || readOnly) return

    this.handleOpenMenu()
    if (onClick) onClick(event)
  }

  handleDownKey () : void {
    let { items, focusedItemIndex } = this.state
    if (!items.length) {
      this.handleOpenMenu()
      return
    }

    focusedItemIndex++
    focusedItemIndex = Math.min(focusedItemIndex, items.length - 1)
    this.setState({ focusedItemIndex })
  }

  handleUpKey () : void {
    let { focusedItemIndex } = this.state
    focusedItemIndex--
    focusedItemIndex = Math.max(focusedItemIndex, 0)
    this.setState({ focusedItemIndex })
  }

  handleEnterKey (event) : void {
    const { items, focusedItemIndex } = this.state
    const { displayValueKey, valueKey, onEnterKey } = this.props
    const item = items[focusedItemIndex]
    if (item) {
      this.props.onChange({ value: item[valueKey], displayValue: CAST.String(item[displayValueKey]) })
      this.resetState()
      event.preventDefault()
      event.stopPropagation()
    } else if (onEnterKey) onEnterKey(event)
  }

  handleEscapeKey (event) : void {
    if (this.state.items.length) event.stopPropagation()
    if (this.props.onEscapeKey) this.props.onEscapeKey(event)

    this.resetState()
    event.preventDefault()
  }

  handleTabKey (event) : void {
    if (this.state.items.length) this.resetState()
  }

  resetState () : void {
    this.setState({ items: [], focusedItemIndex: 0 })
  }

  handleActivateMenuItem ({ value, displayValue }) : void {
    this.props.onChange({ value, displayValue })
    this.resetState()
  }

  getPositionTuneClasses () : ?string {
    const container = this.field.current?.container.current
    if (container) {
      const [cx, cy] = [window.innerWidth / 2, window.innerHeight / 2]
      const { top, left } = container.getBoundingClientRect()

      if (top > cy && left > cx) return 'in-right-bottom'
      else if (left > cx) return 'in-right'
      else if (top > cy) return 'in-bottom'
    }
    return null
  }

  render () : React.Node {
    const { items, focusedItemIndex, pending } = this.state
    let {
      value, displayValue, disabled, valueKey, displayValueKey, onChange,
      containerClassName, inputClassName, placeholder, catchFocusOnMount,
      invalid, tabIndex, testName, onBlur, onFocus, onKeyDown, onEraceKey,
      readOnly, title
    } = this.props

    containerClassName = classnames('ref-select', { pending, disabled: readOnly }, containerClassName)
    const fieldProps = {
      title,
      value,
      displayValue,
      onChange,
      containerClassName,
      inputClassName,
      disabled,
      placeholder,
      catchFocusOnMount,
      invalid,
      tabIndex,
      testName,
      onBlur,
      onFocus,
      onKeyDown,
      onEraceKey
    }

    const dropDownClass = this.getPositionTuneClasses()
    const dropDownProps = { valueKey, displayValueKey, items, focusedItemIndex, disabled, className: dropDownClass }

    return (
      <>
        <Field
          ref={this.field}
          {...fieldProps}
          onClick={this.handleClick}
          onKeyPress={this.handleKeyPress}
          onDownKey={this.handleDownKey}
          onUpKey={this.handleUpKey}
          onEnterKey={this.handleEnterKey}
          onEscapeKey={this.handleEscapeKey}
          onTabKey={this.handleTabKey}
          readOnly
          noResetControl
        >
          <DropdownMenu
            {...dropDownProps}
            onOutClick={this.handleMenuOutClick}
            onChange={this.handleActivateMenuItem}
          />
          <Action
            icon='angle-down'
            disabled={disabled || readOnly}
            onClick={this.handleOpenMenu}
          />
        </Field>
      </>
    )
  }
}

const withDataProviderInjected: React.AbstractComponent<SelectProps<Entity>> = injectDataProvider(Select)

export default withDataProviderInjected
