/**
 * @file Required.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 10th March 2022 7:03:28 am
 * @copyright 2015 - 2022 SKAT LLC, Delive LLC
 * @ignore
 * @flow strict
 */
import * as React from 'react'

export default function Required (props: { children: string }) : React.Node {
  return (
    <>
      <span className='required-mark'>*</span>
      {props.children}
    </>
  )
}
