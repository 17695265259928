/**
 * @file Footer.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 29th October 2019 1:16:56 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow strict
 */

import type { WindowButton, asyncVoid, iExposedView, ReactRef } from './../../types'

import * as React from 'react'
import { Button } from 'web-panel/components'
import autoBind from 'react-autobind'

type CB = (p: iExposedView<*>) => asyncVoid

type Props = {
  children: React.Node,
  buttonsDisabled: boolean,
  buttons: WindowButton[],
  onButtonClick: (name: ?string, callback: CB) => Promise<void>,
  primaryButtonRef: ReactRef<typeof Button>
}

class Footer extends React.PureComponent<Props> {
  constructor (props: Props) {
    super(props)
    autoBind(this)
  }

  withContext (buttonName: ?string, cb: CB) : CB {
    return () => this.props.onButtonClick(buttonName, cb)
  }

  render () : React.Node {
    const { buttons, primaryButtonRef, children } = this.props
    return (
      <div className='footer'>
        <div className='extra' role='region'>{children}</div>
        <div className='buttons' role='region'>
          {
            buttons.map((button: WindowButton, index: number) => {
              const action = this.withContext(button.name, button.action)

              return (
                <Button
                  {...button}
                  ref={button.isPrimary ? primaryButtonRef : undefined}
                  disabled={button.disabled || this.props.buttonsDisabled}
                  key={index}
                  action={action}
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default Footer
