/**
 * @file ErrorNotifications.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 23rd March 2021 6:52:23 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow
 */
import type { iApplication, iStockExtension, iNotificationManager } from '../types'

import { __, NOTIFICATION } from '../globals'
import { Inject } from '../serviceLocator'
import { CAST } from 'web-panel-essentials/misc'
import shortHash from 'short-hash'
class ErrorNotifications implements iStockExtension {
  @Inject notificationManager : iNotificationManager

  hash (message: string) : string {
    return shortHash(message)
  }

  async activate (application: iApplication) {
    window.onerror = (msg: string, url: string, lineNo: number, columnNo: number, error) => {
      let message : string
      let details : string

      // Ignoring this message, because we can't fix it
      // And this is not our error
      if (msg === 'ResizeObserver loop limit exceeded') return

      if (error && error.name) {
        message = [__('INTERNAL_ERROR'), error.name].join(' : ')
        details = CAST.String(error.message)
      } else {
        message = __('UNEXPECTED_ERROR')
        details = [
          'Message: ' + msg,
          'URL: ' + url,
          'Line: ' + lineNo,
          'Column: ' + columnNo,
          'Error object: ' + (error ? error.stack : '--')
        ].join('\n')
      }

      this.notificationManager.show({
        id: this.hash(message),
        message,
        details,
        type: NOTIFICATION.ERROR,
        persistent: true
      })
    }
  }
}

export default ErrorNotifications
