/**
 * @file Field.js
 * @ignore
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 19th October 2021 8:00:02 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow strict
 */
/* global SyntheticEvent, SyntheticKeyboardEvent, SyntheticFocusEvent, HTMLElement, HTMLInputElement */
import type { ReactRef, CombinedValue } from '../types'
import type { FieldProps } from './types'

import * as React from 'react'
import cn from 'classnames'
import autoBind from 'react-autobind'
import { genTestName } from '../globals'
import Reset from './Reset'
import { KEY } from 'web-panel-essentials/misc'
import { RunOnNextTick } from 'web-panel-essentials/decorators'

type State = {|
  focused: boolean,
  cursor: [number, number]
|}

type ExCombinedValue = {
  value: mixed,
  displayValue: string | number
}

/**
 * Base input widget.
 * @function
 * @example <caption>Import</caption>
 * import { Field } from 'web-panel/components'
 * @example <caption>Usage</caption>
 *
 * import type { Node } from 'react'
 * import type { CombinedValue } from 'web-panel/types'
 *
 * import React from 'react'
 * import { Row, Col, Field, Required } from 'web-panel/components'
 * import { __ } from 'web-panel/globals'
 *
 * type Props = {}
 * type State = {|
 *     name: string
 * |}
 *
 * class Form extends React.Component<Props, State> {
 *     constructor(props) {
 *         super(props)
 *         this.state = {
 *             name: ''
 *         }
 *     }
 *
 *     handleNameChange (changes: CombinedValue) {
 *         this.setState({name: changes.displayValue })
 *     }
 *
 *     render() : Node {
 *         const { name } = this.state
 *         return (
 *             <Row>
 *                 <Col size={6}>
 *                     <Required>{__('NAME')}</Required>
 *                 </Col>
 *                 <Col size={6}>
 *                     <Field displayValue={name} onChange={this.handleNameChange} />
 *                 </Col>
 *             </Row>
 *         )
 *     }
 * }
 *
 */
class Field extends React.PureComponent<FieldProps, State> {
  input: ReactRef<'input'>
  container: ReactRef<'div'>
  lastChanges: ExCombinedValue
  lastCommitedChanges: ExCombinedValue

  constructor (props: FieldProps) {
    super(props)
    autoBind(this)

    this.state = {
      focused: false,
      cursor: [0, 0]
    }

    this.input = React.createRef()
    this.container = React.createRef()

    const { value, displayValue } = props
    this.lastChanges = { value, displayValue }
    this.lastCommitedChanges = this.lastChanges // { value: null, displayValue: '' }
  }

  componentDidMount () {
    if (this.props.catchFocusOnMount) this.focusOnMount()
  }

  @RunOnNextTick()
  focusOnMount () {
    this.focus()
  }

  handleKeyDown (event: SyntheticKeyboardEvent<HTMLInputElement>) : void {
    if (this.props.disabled) return
    if (this.props.onKeyDown) this.props.onKeyDown(event)

    const { keyCode } = event
    if (keyCode === KEY.BACKSPACE || keyCode === 46) this.props.onEraceKey?.call(null, event)
    if (keyCode === KEY.DOWN) this.props.onDownKey?.call(null, event)
    if (keyCode === KEY.UP) this.props.onUpKey?.call(null, event)
    if (keyCode === KEY.ENTER) this.props.onEnterKey?.call(null, event)
    if (keyCode === KEY.ESCAPE) this.props.onEscapeKey?.call(null, event)
    if (keyCode === KEY.TAB) this.props.onTabKey?.call(null, event)
  }

  handleKeyPress (event: SyntheticKeyboardEvent<HTMLInputElement>) : void {
    if (this.props.disabled) return
    if (this.props.onKeyPress) this.props.onKeyPress(event)
  }

  handleContainerClick (event: SyntheticEvent<HTMLElement>) {
    if (this.props.disabled) return
    this.focus()
    if (this.props.onClick) this.props.onClick(event)
  }

  handleFocus (event: SyntheticEvent<HTMLElement>) {
    if (this.props.disabled) return
    if (!this.state.focused) {
      const { value, displayValue } = this.props
      this.lastChanges = { value, displayValue }
      this.lastCommitedChanges = this.lastChanges
    }

    this.setState({ focused: true })
    if (this.props.onFocus) this.props.onFocus(event)
  }

  focus () {
    if (this.props.disabled) return
    this.input.current?.focus()
    this.setState({ focused: true })
  }

  setSelectionRange (start: number, end: number) {
    if (this.props.inputType === 'number') return // numberInput does not support selectionRanges
    this.input.current?.setSelectionRange(start, end)
  }

  handleBlur (event: SyntheticFocusEvent<HTMLElement>) {
    if (this.state.focused) {
      const relatedTarget = event.relatedTarget
      // $FlowFixMe[incompatible-call]
      if (!this.container.current?.contains(relatedTarget)) {
        if (this.input === document.activeElement) return
        this.setState({ focused: false, cursor: [0, 0] })
        const { valuesBasedBlur, onBlur } = this.props
        if (onBlur) {
          if (valuesBasedBlur) {
            if (this.lastCommitedChanges.value !== this.lastChanges.value || this.lastCommitedChanges.displayValue !== this.lastChanges.displayValue) {
              this.lastCommitedChanges = this.lastChanges
              onBlur(event)
            }
          } else onBlur(event)
        }
      }
    }
  }

  triggerChange ({ value, displayValue }: CombinedValue) {
    this.props.onChange({ value, displayValue })
  }

  handleReset (event: SyntheticEvent<HTMLElement>) {
    if (this.props.disabled || this.props.readOnly) return
    this.triggerChange({ value: undefined, displayValue: '' })
  }

  handleChange (event: SyntheticEvent<HTMLInputElement>) {
    const { value } = this.props
    const displayValue = event.currentTarget.value
    this.preserveCursor()
    this.triggerChange({ value, displayValue })
  }

  preserveCursor (start?: number, end?: number) {
    const input = this.input.current
    let cursor = [0, 0]
    if (input) cursor = [input.selectionStart, input.selectionEnd]
    if (typeof start !== 'undefined') cursor[0] = start
    if (typeof end !== 'undefined') cursor[1] = end
    this.setState({ cursor })
  }

  componentDidUpdate () {
    const { value, displayValue } = this.props
    this.lastChanges = { value, displayValue }
    const [start, end] = this.state.cursor
    if (start || end) this.setSelectionRange(start, end)
  }

  render () : React.Node {
    const { focused } = this.state
    const { displayValue, disabled, readOnly, invalid, containerClassName, inputClassName, tabIndex, placeholder, title, testName, children = [], inputType, noResetControl } = this.props
    const resetActive = Boolean(displayValue) && !readOnly && !disabled
    return (
      <div
        className={cn(containerClassName, 'field', 'field-autocomplete', { focused, disabled, invalid })}
        ref={this.container}
        data-title={title || placeholder}
        onClick={this.handleContainerClick}
      >
        <div className='field-data'>
          <input
            {...genTestName(testName)}
            tabIndex={tabIndex}
            readOnly={readOnly}
            disabled={disabled}
            ref={this.input}
            placeholder={placeholder}
            className={cn('field-input', inputClassName)}
            value={displayValue}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            onKeyPress={this.handleKeyPress}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            type={inputType || 'text'}
          />
          {noResetControl ? null : <Reset active={resetActive} onClick={this.handleReset} />}
        </div>
        {React.Children.map(children, child => child ? React.cloneElement(child, { onFocusTransfer: this.focus, focused }) : null)}
      </div>
    )
  }
}

export default Field
