/**
 * Не всё можно вынести в воркер, потому что не все можно сериализовать.
 * Но воркеры и не всегда актуальны, для малых и не динамичных таблиц
 * они даже лишние.
 * Поэтому grid по умолчанию не будет использовать worker-ы, а
 * будет делать это только для тех таблиц где это имеет смысл. Например в таблице
 * заказов, водителей, чатах.
 * В остальных же местах будет обертка, работающая в основном потоке.
 * @file sharedMethods.js
 * @ignore
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 22nd September 2020 10:49:41 am
 * @copyright 2015 - 2020 SKAT LLC, Delive LLC
 */
import { CAST } from 'web-panel-essentials/misc'
import { rowAllowed } from 'web-panel/views/grid/filtration'
import pick from 'lodash/pick'

let locale = 'en'

const COMPARATOR = {
  ASC: (a, b) => a > b,
  DESC: (a, b) => a < b
}

export function sortColumn (payload, target, mode) {
  const merge = (left, right) => {
    let result = []
    while ((left.length > 0) && (right.length > 0)) {
      if (COMPARATOR[mode](left[0][target], right[0][target])) result.push(left.shift())
      else result.push(right.shift())
    }
    result = result.concat(left, right)
    return result
  }

  const sort = (array) => {
    const len = array.length
    if (len < 2) return array
    const pivot = Math.ceil(len / 2)
    return merge(sort(array.slice(0, pivot)), sort(array.slice(pivot)))
  }

  const sorted = sort(payload)
  const index = {}
  sorted.forEach((v, i) => { index[v.id] = i })
  return [sorted, index]
}

export function filterData (payload, where, whereLocal, select, limit) {
  const filtered = []
  const index = {} // We must rebuild index everytime to fast get cursor position based on selected element

  let i = 0
  const allow = (row) => {
    filtered.push(select ? pick(row, select) : row)
    index[row.id] = i
    i++
  }

  payload.forEach((row) => {
    if (filtered.length > limit) return

    if (where && whereLocal) {
      if (rowAllowed(row, where, { locale }) && rowAllowed(row, whereLocal, { forceValueCast: CAST.String, locale })) {
        allow(row)
      }
    } else if (where) {
      if (rowAllowed(row, where, { locale })) allow(row)
    } else if (whereLocal) {
      if (rowAllowed(row, whereLocal, { forceValueCast: CAST.String, locale })) allow(row)
    } else allow(row)
  })

  return [filtered, index]
}

export function setLocale (value) {
  locale = value
}

export function getLocale () {
  return locale
}

export default function simulatedWorker () {
  return {
    terminate: () => {},
    setLocale,
    getLocale,
    sortColumn,
    filterData
  }
}
