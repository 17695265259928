/**
 * @file Col.js
 * @ignore
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Wednesday, 7th October 2020 8:09:29 am
 * @copyright 2015 - 2020 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { ColProps } from './types'

import * as React from 'react'
import cn from 'classnames'
import { genTestName } from 'web-panel/globals'

/**
 * Column widget.
 * The size propery is required and must be set with value between 1-12
 * @function
 * @example <caption>Import</caption>
 * import { Col } from 'web-panel/components'
 *
 * @example <caption>Usage</caption>
 * function BoxView(props: {}) : React.Node {
 *   return (
 *     <Row>
 *        <Col size={8} className='left-label'>Hellow</Col>
 *        <Col size={4}>world</Col>
 *     </Row>
 *   )
 * }
 */
const Col = (props: ColProps) : React.Node => {
  let { className, size, noMargin, noPadding, children, style, testName } = props
  className = cn('col', `col-${size}`, className, {
    'no-margin': noMargin,
    'no-padding': noPadding
  })

  return (<div className={className} style={style} {...genTestName(testName)}>{children}</div>)
}

export default Col
