/**
 * @file DataProvider.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 17th November 2020 9:14:27 am
 * @copyright 2015 - 2020 SKAT LLC, Delive LLC
 * @flow
 */

import type { iREST, iCRUD, iLogger, iRemoteDataCache, EmitterEvents } from '../types'
import type { iDataProvider, ID, ProviderMeta, WaterlineQuery, WaterlineWhere } from 'web-panel-essentials/types'

import EventEmitter from 'web-panel/utils/EventEmitter'
import { Inject, Injectable } from '../serviceLocator'
import { rowAllowed } from './../views/grid/filtration'
import { extractErrorMessage, __, getCurrentLocale, assert } from '../globals'
import { stringify } from 'query-string'
import shortid from 'shortid'
import isEmpty from 'lodash/isEmpty'
import { EndpointLookupError, RequestFailed } from '../errors'
import { NotImplementedError } from 'web-panel-essentials/errors'
import urlJoin from '@lvchengbin/url-join'
import omit from 'lodash/omit'

const DEFAULT_META : ProviderMeta<{}> = {
  value: 'id',
  displayValue: 'name',
  columns: []
}

class DataProvider<T> extends EventEmitter implements iDataProvider<T> {
  @Inject logger : iLogger

  static get EVENT () : EmitterEvents {
    return {
      DATA_CHANGED: 'change'
    }
  }

  get EVENT () : EmitterEvents {
    return DataProvider.EVENT
  }

  id: string
  _meta: ProviderMeta<T>
  _payload: Array<T>

  constructor ({ meta = DEFAULT_META, payload = [] }: { meta?: ProviderMeta<T>, payload: Array<T>} = {}) {
    super()
    this.setMaxListeners(10)
    this.logger.namespace = 'data-provider'
    this.id = shortid.generate()
    this._meta = meta
    this._payload = payload
  }

  async meta () : Promise<ProviderMeta<T>> {
    return this._meta
  }

  async get (query: ?WaterlineQuery) : Promise<Array<T>> {
    if (query) assert(query.where, { query }, 'Query has where condition')

    const where : ?WaterlineWhere = query && query.where ? query.where : null
    const payload = where ? (this._payload).filter((record: T) => rowAllowed(record, where, { locale: getCurrentLocale() })) : this._payload
    return payload || []
  }

  async getOne (id: ID) : Promise<?T> {
    assert(typeof id !== 'object', { id }, 'Target id has a basic type')
    const [record] = await this.get({ where: { id } })
    return record
  }
}

@Injectable('HTTPTransport', true)
class HTTPTransport implements iREST {
  @Inject logger : iLogger

  host: string
  headers: {}

  constructor (host:string = '', headers:{} = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }) {
    this.host = host
    this.headers = headers
  }

  async get (url: string, query: mixed) : Promise<mixed> {
    url = urlJoin(this.host, url)

    try {
      const where = (query && query.where && !isEmpty(query.where)) ? query.where : null
      if (where) url = [url, stringify({ where: JSON.stringify(where) })].join('?')
      const response = await global.fetch(url, {
        headers: this.headers
      })

      if (response.ok) {
        const responseData = await response.json()
        return responseData || []
      }
    } catch (e) {
      this.logger.error(e)
      throw new RequestFailed(extractErrorMessage(e))
    }
  }

  async post (url: string, data: mixed) : Promise<mixed> {
    url = urlJoin(this.host, url)
    const response = await global.fetch(url, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(data)
    })

    if (response.ok) {
      const responseData = await response.json()
      return responseData
    }
    throw new RequestFailed(response.statusText)
  }

  async put (url: string, data: mixed) : Promise<mixed> {
    url = urlJoin(this.host, url)
    const response = await global.fetch(url, {
      method: 'PUT',
      headers: this.headers,
      body: JSON.stringify(data)
    })

    if (response.ok) {
      const responseData = await response.json()
      return responseData
    }
    throw new RequestFailed(response.statusText)
  }

  async delete (url: string, { id }: { id: ID }) :Promise<ID> {
    url = urlJoin(this.host, url)
    const response = await global.fetch(url, {
      method: 'DELETE',
      headers: this.headers,
      body: JSON.stringify({ id })
    })

    if (response.ok) {
      const responseData = await response.json()
      return responseData.id
    }
    throw new RequestFailed(response.statusText)
  }
}

class RemoteDataProvider<T> extends DataProvider<T> {
  @Inject dataConnection: iREST

  get ENDPOINT () : string {
    throw new EndpointLookupError(__('ENDPOINT_NOT_SET'))
  }

  async transport () : Promise<iREST> {
    return this.dataConnection
  }

  async postProcess (data: mixed) : Promise<T[]> {
    // $FlowFixMe[incompatible-return]
    return data
  }

  async get (query?: ?WaterlineQuery) : Promise<T[]> {
    const where : ?WaterlineWhere = (query && query.where && !isEmpty(query.where)) ? query.where : null
    const limit : ?number = (query && query.limit) ? query.limit : null
    const skip : ?number = (query && query.skip) ? query.skip : null

    let requestQuery = {}
    if (where) requestQuery.where = where
    if (limit) requestQuery.limit = limit
    if (skip) requestQuery.skip = skip
    if (!where && !limit) requestQuery = null

    const transport = await this.transport()
    const payload : mixed = await transport.get(this.ENDPOINT, requestQuery)
    const transformed = await this.postProcess(payload)
    return transformed || []
  }
}
class AJAXDataProvider<T> extends RemoteDataProvider<T> {
  @Inject HTTPTransport : iREST

  async transport () : Promise<iREST> {
    return this.HTTPTransport
  }
}

class CachedRemoteDataProvider<T> extends RemoteDataProvider<T> {
  @Inject remoteDataCache : iRemoteDataCache<T>

  get CACHE_MARK () : string | null {
    return null
  }

  async get (query: ?WaterlineQuery) : Promise<Array<T>> {
    if (!this.CACHE_MARK) return super.get(query)

    const where : ?WaterlineWhere = query && query.where ? query.where : null
    this._payload = await this.remoteDataCache.pull(this.ENDPOINT, this.CACHE_MARK)
    const payload = where ? (this._payload).filter((record: T) => rowAllowed(record, where, { locale: getCurrentLocale() })) : this._payload
    return payload || []
  }
}

class CRUDRemoteDataProvider<T> extends RemoteDataProvider<T> implements iCRUD<T> {
  static get EVENT () : EmitterEvents {
    return {
      ...RemoteDataProvider.EVENT,
      RECORD_DELETED: 'record-deleted',
      RECORD_CREATED: 'record-created',
      RECORD_UPDATED: 'record-updated'
    }
  }

  get EVENT () : EmitterEvents {
    return CRUDRemoteDataProvider.EVENT
  }

  read (...args: any) : Promise<T> {
    throw new NotImplementedError()
  }

  async create (record: T) : Promise<T> {
    const transport = await this.transport()
    let createdRecord = await transport.post(this.ENDPOINT, record)
    createdRecord = omit(createdRecord, ['meta', '$schema'])
    this.emit(this.EVENT.RECORD_CREATED, { record: createdRecord })
    this.emit(this.EVENT.DATA_CHANGED)
    return createdRecord
  }

  async update (record: T) : Promise<T> {
    const transport = await this.transport()
    let updatedRecord = await transport.put(this.ENDPOINT, record)
    updatedRecord = omit(updatedRecord, ['meta', '$schema'])

    this.emit(this.EVENT.RECORD_UPDATED, { record: updatedRecord })
    this.emit(this.EVENT.DATA_CHANGED)
    return updatedRecord
  }

  async delete (id: ID) : Promise<ID> {
    const transport = await this.transport()
    const deletedId = await transport.delete(this.ENDPOINT, { id })
    this.emit(this.EVENT.RECORD_DELETED, { id: deletedId })
    this.emit(this.EVENT.DATA_CHANGED)
    return deletedId
  }
}

function FSimpleDataProvider<T> (...args: Array<Object>) : DataProvider<T> {
  return new DataProvider<T>(...args)
}

const STUBProvider : iDataProvider<*> = Object.freeze({
  meta: async () => {
    return Object.freeze({
      value: 'id',
      displayValue: 'name',
      columns: Object.freeze([])
    })
  },
  get: async () => Object.freeze([]),
  getOne: async () => null
})

export { DataProvider, RemoteDataProvider, AJAXDataProvider, FSimpleDataProvider, CRUDRemoteDataProvider, STUBProvider, CachedRemoteDataProvider, HTTPTransport }
