/**
 * @file Canvas.js
 * @ignore
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 17th March 2022 12:10:59 pm
 * @copyright 2015 - 2022 SKAT LLC, Delive LLC
 * @flow strict
 */
/* global CanvasRenderingContext2D */
import type { ReactRef } from 'web-panel/types'
import * as React from 'react'

type Props = {
  width: number,
  height: number
}

type State = {}

const ABSOLUTE_TOP_LEFT = { position: 'absolute', top: 0, left: 0 }

export default class Canvas<T: Props, S: State> extends React.PureComponent<T, S> {
  #context2D: CanvasRenderingContext2D
  internalRef: ReactRef<'canvas'>

  constructor (props: T) {
    super(props)
    // $FlowFixMe[incompatible-type]
    this.state = {}
    this.internalRef = React.createRef()
  }

  get context2D () : CanvasRenderingContext2D | null {
    if (this.#context2D) return this.#context2D
    const canvas = this.internalRef.current
    if (canvas) {
      const context = canvas.getContext('2d')
      this.#context2D = context
      return this.#context2D
    }
    return null
  }

  render () : React.Node {
    const { width, height } = this.props
    return (
      <canvas
        ref={this.internalRef}
        width={width}
        height={height}
        style={ABSOLUTE_TOP_LEFT}
      />
    )
  }
}
