/**
 * @file StateStreamsManager.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 9th November 2021 1:46:21 pm
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { Resolver, UpdateQuery, iLogger, iNotificationManager } from '../types'
import type { ID } from 'web-panel-essentials/types'

import { queue } from 'async'
import { Inject } from 'web-panel/serviceLocator'
import { extractErrorMessage, NOTIFICATION } from 'web-panel/globals'
import update from 'immutability-helper'
import shortHash from 'short-hash'

const CONCURRENCY = 1

// #region TYPES
type IncomingChangesMeta = {|
  key: ID,
  collection?: string,
  id: ?ID
|}

type InnerMeta = {|
  ...IncomingChangesMeta,
  type: 'commit' | 'changes'
|}

type QueuePayload<T> = {|
  meta: InnerMeta,
  value?: UpdateQuery<T>,
  resolver?: Resolver<T>
|}

type Commands<T> = {|
  getState: () => T,
  setState: (T) => Promise<void>,
  applyUpdate: (UpdateQuery<T>) => Promise<void>,
  lock: (ID[]) => void,
  unlock: (ID[]) => void
|}
// #endregion

const now = () => global.performance.now()

/**
 * Выстраивает цепочку изменений и коммитов в очередь,
 * управляет блокировками.
 * Очередь может выглядеть так:
 * Direct changes -> Direct changes -> Lock -> Long life resolver -> Unlock -> Direct changes
 * Для каждого поля создается своя очередь изменений.
 * Почему? Потому-что, если очередь будет всего одна, то любая длительная операция будет
 * блокировать все изменения, в том числе пользовательский ввод во всех полях.
 */
class StateManager<State: {}> {
  @Inject logger : iLogger
  @Inject notificationManager : iNotificationManager

  fieldQueue: { [ID]: typeof queue } | null

  resolvers: Array<Resolver<State>>
  commands: Commands<State>

  constructor (commands: Commands<State>) {
    this.logger.namespace = 'state-manager'
    this.resolvers = []
    this.fieldQueue = {}
    this.commands = commands
  }

  addResolver (resolver: Resolver<State>) {
    this.resolvers.push(resolver)
  }

  hash (message: string) : string {
    return shortHash(message)
  }

  enqueue (payload: QueuePayload<State>) : void {
    if (this.fieldQueue === null) return
    const queues = this.fieldQueue

    const { key } = payload.meta
    const { getState, setState, applyUpdate, lock, unlock } = this.commands

    if (!this.fieldQueue[key]) {
      queues[key] = queue(async (payload: QueuePayload<State>, next) => {
        const { meta, resolver, value } = payload

        if (meta.type === 'commit') {
          if (resolver) {
            try {
              lock(resolver.affects)
              this.logger.info(`Resolver *${resolver.handle.name}*...`)
              const start = now()
              let lastState = getState()
              const changes = await resolver.handle({
                key: meta.key,
                lastState,
                collection: meta.collection,
                id: meta.id
              })
              this.logger.info('Handler execution time was', now() - start, 'ms')

              lastState = update(lastState, changes)
              for (const handler of resolver.postHooks || []) {
                this.logger.info(`Post-hook *${handler.name}*...`)
                const start = global.performance.now()
                const changes = await handler({ key: meta.key, lastState, collection: meta.collection, id: meta.id })
                lastState = update(lastState, changes)
                this.logger.info('Post-hook execution time was', now() - start, 'ms')
              }

              await setState(lastState)
              this.logger.info('Total execution time was', now() - start, 'ms')
            } catch (e) {
              this.logger.error(e)
              const message = extractErrorMessage(e)
              this.notificationManager.show({
                id: this.hash(message),
                type: NOTIFICATION.ERROR,
                message
              })
            } finally {
              unlock(resolver.affects)
            }
          }
        }

        if (meta.type === 'changes' && value) await applyUpdate(value)
        next()
      }, CONCURRENCY)
    }

    this.fieldQueue[key].push(payload)
  }

  enqueueChanges ({ key, collection, id }: IncomingChangesMeta, value: UpdateQuery<State>) {
    const meta = { type: 'changes', id, key, collection }
    this.enqueue({ meta, value })
  }

  enqueueCommits ({ key, collection, id }: IncomingChangesMeta) {
    const resolver = this.findResolver(key)
    const meta = { type: 'commit', id, key, collection }
    if (resolver) this.enqueue({ meta, resolver })
  }

  findResolver (key: ID) : ?Resolver<State> {
    const resolver = this.resolvers.find(({ triggeredBy }) => triggeredBy.includes(key))
    return resolver
  }

  dispose () : void {
    if (this.fieldQueue === null) return

    const queues = this.fieldQueue
    const queueIDs = Reflect.ownKeys(queues)

    for (const fieldName of queueIDs) {
      const existingQueue = queues[fieldName]
      existingQueue.pause()
      existingQueue.kill()
    }

    this.fieldQueue = null
  }
}

export default StateManager
