/**
 * @file Header.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 16th May 2019 3:12:43 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow
 */
import * as React from 'react'
import cn from 'classnames'
import { genTestName } from 'web-panel/globals'
import autoBind from 'react-autobind'
import { Icon, If } from 'web-panel/components'

type Props = {|
  children: React.Node,
  testName?: string,
  isMaximized: boolean,
  expandable: boolean,
  helpAvaialable: boolean,
  onMinimizeClick: () => void,
  onExpandClick: () => void,
  onCloseClick: () => void,
  onHelpClick: () => void,
  closeDisabled: boolean
|}

class Header extends React.PureComponent<Props> {
  constructor (props: Props) {
    super(props)
    autoBind(this)
  }

  handleClose () : void {
    if (!this.props.closeDisabled) this.props.onCloseClick()
  }

  handleHelpClick () : void {
    if (this.props.onHelpClick) this.props.onHelpClick()
  }

  handleDoubleClick () : void {
    if (this.props.expandable) this.props.onExpandClick()
  }

  handleMouseDown (e: any) : void {
    e.stopPropagation()
  }

  render () : React.Node {
    const { children, helpAvaialable, closeDisabled, onMinimizeClick, onExpandClick, testName, expandable, isMaximized } = this.props
    return (
      <div className='title' onDoubleClick={this.handleDoubleClick} {...genTestName('title-modal', testName)}>
        {children}
        <If condition={helpAvaialable}>
          <span {...genTestName('help-modal', testName)} className='action help' onClick={this.handleHelpClick} onMouseDown={this.handleMouseDown}>
            <Icon icon='question' />
          </span>
        </If>
        <span {...genTestName('collapse-modal', testName)} className='action collapse' onClick={onMinimizeClick} onMouseDown={this.handleMouseDown}>
          <Icon icon='window-minimize' />
        </span>
        <If condition={expandable}>
          <span {...genTestName('expand-modal', testName)} className='action expand' onClick={onExpandClick} onMouseDown={this.handleMouseDown}>
            {
              isMaximized
                ? <Icon icon='window-restore' />
                : <Icon icon='window-maximize' />
            }
          </span>
        </If>
        <span {...genTestName('close-modal', testName)} className={cn('action', 'cross', { disabled: closeDisabled })} onClick={this.handleClose} onMouseDown={this.handleMouseDown}>
          <Icon icon='times' />
        </span>
      </div>
    )
  }
}

export default Header
