/**
 * @file WindowsData.js
 * @ignore
 * @project web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Wednesday, 1st March 2023 9:16:24 am
 * @copyright 2015 - 2023 SKAT LLC, Delive LLC
 * @flow strict
 */
/* global Iterator */

import type { ID } from 'web-panel-essentials/types'
import type { Window, ReactRef } from 'web-panel/types'

import { ValidationError } from 'web-panel-essentials/errors'
import { assert } from 'web-panel/globals'

export type WindowModel = {|
  ...Window,
  key: number,
  primaryButtonRef: ReactRef<*>
|}

class WindowLink {
  #id: ID
  #parent: ?ID

  get id () : ID {
    return this.#id
  }

  get parent () : ?ID {
    return this.#parent
  }

  constructor (id: ID, parent?: ID) {
    if (!id) throw new ValidationError('Link ID is required')
    this.#id = id
    this.#parent = parent
  }
}

/**
 * Using Weak Stores to help the browser:
 *
 * TO
 *    efficiently collect garbage
 * AND
 *    to reduce react operational state tree
 * @ignore
 */
class WindowRegistry {
  #links: Map<ID, WindowLink>
  #models : WeakMap<WindowLink, WindowModel>
  #flashing: WeakSet<WindowLink>
  #minimized: WeakSet<WindowLink>
  #maximized: WeakSet<WindowLink>
  #helpVisible : WeakSet<WindowLink>

  get linkList () : WindowLink[] {
    // TODO: This array could be memoized
    return Array.from(this.#links.values())
  }

  get linksIterator () : Iterator<[ID, WindowLink]> {
    // $FlowFixMe[incompatible-use]
    return this.#links[Symbol.iterator]()
  }

  constructor () {
    this.#links = new Map()
    this.#models = new WeakMap()
    this.#flashing = new WeakSet()
    this.#minimized = new WeakSet()
    this.#maximized = new WeakSet()
    this.#helpVisible = new WeakSet()
  }

  makeLink (id: ID, parent?: ID) : WindowLink {
    const link = new WindowLink(id, parent)
    return link
  }

  setLink (id: ID, link: WindowLink) : void {
    this.#links.set(id, link)
  }

  getLink (id: ID) : ?WindowLink {
    assert(Boolean(id), id, 'Window ID is specified')

    const link = this.#links.get(id)
    assert(Boolean(link), id, 'Link exists')

    return link
  }

  hasLink (id: ID) : boolean {
    return this.#links.has(id)
  }

  deleteLink (id: ID) {
    this.#links.delete(id)
  }

  getLastLink () : ?WindowLink {
    const links = this.linkList
    if (links.length) return links[links.length - 1]
  }

  bindModel (link: WindowLink, model: WindowModel) : void {
    this.#models.set(link, model)
  }

  getModel (link: WindowLink) : ?WindowModel {
    return this.#models.get(link)
  }

  findModel (id: ID | WindowLink) : ?WindowModel {
    let link

    if (id instanceof WindowLink) link = id
    else link = this.getLink(id)

    if (link) {
      const modal = this.#models.get(link)
      return modal
    }
  }

  setMinimized (id: ID) : void {
    const link = this.getLink(id)
    if (link) this.#minimized.add(link)
  }

  setFlashing (id: ID) : void {
    const link = this.getLink(id)
    if (link) this.#flashing.add(link)
  }

  setUnMinimized (id: ID) : void {
    const link = this.getLink(id)
    if (link) this.#minimized.delete(link)
  }

  setUnflashing (id: ID) : void {
    const link = this.getLink(id)
    if (link) this.#flashing.delete(link)
  }

  isFlashing (id: ID) : boolean {
    const link = this.getLink(id)
    if (link) return this.#flashing.has(link)
    return false
  }

  isMinimized (id: ID) : boolean {
    const link = this.getLink(id)
    if (link) return this.#minimized.has(link)
    return false
  }

  isMaximized (id: ID) : boolean {
    const link = this.getLink(id)
    if (!link) return false

    return this.#maximized.has(link)
  }

  setMaximized (id: ID) : void {
    const link = this.getLink(id)
    if (!link) return

    this.#maximized.add(link)
  }

  setUnMaximized (id: ID) : void {
    const link = this.getLink(id)
    if (!link) return
    this.#maximized.delete(link)
  }

  isHelpVisible (id: ID) : boolean {
    const link = this.getLink(id)
    if (!link) return false
    return this.#helpVisible.has(link)
  }

  setHelpVisible (id: ID) : void {
    const link = this.getLink(id)
    if (!link) return
    this.#helpVisible.add(link)
  }

  setHelpInvisible (id: ID) : void {
    const link = this.getLink(id)
    if (!link) return
    this.#helpVisible.delete(link)
  }
}

const windowRegistry : WindowRegistry = new WindowRegistry()

export type { WindowLink }
export default windowRegistry
