/**
 * @file NotificationsLog.js
 * @project web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Wednesday, 29th November 2023 11:02:25 am
 * @copyright 2015 - 2023 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { iGlobalEventBus, NotificationMessage } from 'web-panel/types'

import { DataProvider } from 'web-panel/connections/DataProvider'
import { Injectable, Inject } from 'web-panel/serviceLocator'

@Injectable('notificationLog', true)
class NotificationLog extends DataProvider<NotificationMessage> {
  @Inject globalEventBus: iGlobalEventBus
  constructor () {
    super()
    this.globalEventBus.on('new-notification', ({ notification }) => {
      this._payload.push({ ...notification, time: new Date() })
      this.emit(this.EVENT.DATA_CHANGED)
    })
  }

  reset () {
    this._payload = []
    this.emit(this.EVENT.DATA_CHANGED)
  }
}

export default NotificationLog
