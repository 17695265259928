/**
 * @file PasswordField.js
 * @project Web-panel
 * @ignore
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 5th July 2019 1:15:14 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { ReactRef } from '../types'
import type { FieldProps } from './types'

import * as React from 'react'
import autoBind from 'react-autobind'
import Field from './Field'
import Action from './Action'

type State = {|
  passwordVisible: boolean
|}

class PasswordField extends React.PureComponent<FieldProps, State> {
  field: ReactRef<typeof Field>

  constructor (props: FieldProps) {
    super(props)
    autoBind(this)
    this.state = {
      passwordVisible: false
    }
    this.field = React.createRef()
  }

  focus () {
    this.field.current?.focus()
  }

  handlePasswordVisibilityToggle () {
    const passwordVisible = !this.state.passwordVisible
    this.setState({ passwordVisible })
  }

  render () : React.Node {
    const { passwordVisible } = this.state
    const icon = passwordVisible ? 'eye' : 'eye-slash'
    const inputType = passwordVisible ? 'text' : 'password'
    return (
      <Field {...this.props} ref={this.field} inputType={inputType}>
        <Action icon={icon} onClick={this.handlePasswordVisibilityToggle} />
      </Field>
    )
  }
}

export default PasswordField
