/**
 * @module EventBus
 * @ignore
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 30th July 2019 12:46:58 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow
 */
import { Injectable } from '../serviceLocator'
import EventEmitter from 'web-panel/utils/EventEmitter'

/**
 * Simple event emitter instance
 * @class EventBus
 * @memberof Injectable
 * @alias eventBus
 */
@Injectable('eventBus')
class EventBus extends EventEmitter {}

export default EventBus
