/**
* @namespace web-panel
*/

/**
 * @module application
 * @memberof web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Monday, 8th July 2019 1:33:47 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow strict
 */
import './../styles/importer.less'

import type { iDataProvider } from 'web-panel-essentials/types'
import type {
  iLogger, iTooltipManager, iReportManager, ProvidableFeatures, iUserPreferencesDefaultsStrategy,
  iDataConnection, iWebPanelHook, iApplication, EmitterEvents, iStockExtension, iPreferencesManager, iShortcutsManager,
  iProtocolConnection, iWindowManager, iNotificationManager, iPageManager, iEventEmitter, iServiceLocator,
  NotificationMessage
} from './types'

import * as Sentry from '@sentry/browser'
import { ExtraErrorData } from '@sentry/integrations'

import sortBy from 'lodash/sortBy'
import { __, NOTIFICATION, extractErrorMessage, localStorageAvailable, callLater } from './globals'
import serviceLocator, { Inject } from './serviceLocator'

import HookResolver from './utils/hookResolver'
import MemoryStore from './managers/preferences/MemoryStore'
import LocalStoreWrapped from './managers/preferences/LocalStoreWrapped'

import './providers/locales'
import './cache/RemoteDataCache'
import './managers/preferences/PreferencesManager'

import './utils/GlobalEventBus'
import './utils/Logger'
import './utils/EventBus'
import './utils/StateStreamsManager'

import './connections/IOTransport'
import './connections/WSTransport'
import './connections/ProtocolConnection'
import './connections/DataConnection'
import './views/Layout'

import './managers/tooltip/TooltipManager'
import './managers/report/ReportManager'
import './managers/notifications/NotificationManager'
import './managers/notifications/NotificationLog'

import './managers/window/WindowManager'
import './managers/file_store/FileStoreManager'
import './managers/pages/PageManager'
import './utils/ExtensionsManager'

import './managers/shortcuts/ShortcutsManager'
import Sandbox from './utils/Sandbox'

import ShortcutsExtension from './stockExtensions/Shortcuts'
import AboutExtension from './stockExtensions/About'
import EasterEggsExtension from './stockExtensions/EasterEggs'
import UpdatesObserverExtension from './stockExtensions/UpdatesObserver'
import WidgetFactoryExtension from './stockExtensions/WidgetFactory'
import ErrorNotificationsExtension from './stockExtensions/ErrorNotifications'

import EventEmitter from 'web-panel/utils/EventEmitter'

declare var PRODUCTION: Boolean
declare var SERVER: { VERSION: String }

const release = ['web-panel', SERVER.VERSION].join('@')

let mode = ''
if (PRODUCTION) {
  mode = 'Running in production mode'
  Sentry.init({
    release: ['web-panel', SERVER.VERSION].join('@'),
    environment: 'testing',
    dsn: 'https://35f63899be4f4028d0819225700dcbe6@sentry.cloudtaxi.ru/6',
    integrations: [new ExtraErrorData()],
    tracesSampleRate: 1
  })
} else mode = 'Sentry disabled due to development mode'

console.log(
  `%c${release}\n%c${mode}`,
  'padding-top: 1em; font-size: 2em;',
  'margin-bottom: 1rem; color: green'
)

/**
 * Main entry point
 * @class Application
 * @memberof web-panel.application
 * @extends EventEmitter
 * @implements iEventEmitter
 * @author Pavel Shabardin <bigbn@mail.ru>
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 */
class Application extends EventEmitter implements iApplication {
  @Inject logger : iLogger
  @Inject windowManager : iWindowManager
  @Inject dataConnection : iDataConnection
  @Inject protocolConnection : iProtocolConnection
  @Inject shortcutsManager : iShortcutsManager
  @Inject eventBus : iEventEmitter
  @Inject globalEventBus : iEventEmitter
  @Inject pageManager : iPageManager
  @Inject notificationManager : iNotificationManager
  @Inject notificationLog: iDataProvider<NotificationMessage>
  @Inject hookResolver : HookResolver
  @Inject preferencesManager : iPreferencesManager
  @Inject reportManager : iReportManager
  @Inject tooltipManager : iTooltipManager

  hooks: iWebPanelHook[]
  features: ProvidableFeatures

  /**
   * Application specific events (static propery)
   * @static
   * @property
   */
  static get EVENT () : EmitterEvents {
    return {
      START: 'start',
      BEFORE_START: 'before-start',
      BEFORE_ROUTER_INIT: 'before-router-init',
      SIDEBAR_BUTTON_ACTIVATED: 'sidebar-button-activated'
    }
  }

  get Sandbox () {
    return Sandbox(this)
  }

  /**
   * Application specific events (instance property)
   * @property
   */
  get EVENT () : EmitterEvents {
    return Application.EVENT
  }

  async initializeDefaultsStrategy (features: ProvidableFeatures) {
    let strategy
    if (features.userPreferencesDefaultsStrategy) {
      strategy = await features.userPreferencesDefaultsStrategy
      this.logger.info('Found custom userPrefs strategy. Using it')
    } else {
      strategy = ({ execute: async () => ([]) })
      this.logger.info('Custom defaults strategy not found. Using defaults')
    }
    this.initializePreferencesStore(strategy)
  }

  async initializePreferencesStore (defaultsStrategy: iUserPreferencesDefaultsStrategy) : Promise<void> {
    this.logger.info('Initializing store...')

    if (localStorageAvailable()) {
      this.preferencesManager.store = new LocalStoreWrapped()
      this.logger.info('Using LocalStoreWrapped')
    } else {
      this.preferencesManager.store = new MemoryStore()
      this.logger.info('Using MemoryStore')
    }

    this.preferencesManager.defaultsStrategy = defaultsStrategy

    this.logger.info('Store initialized')
    await this.initializeExtensions()
  }

  async initializeHooks (enabledHooks: Array<string>) : Promise<void> {
    this.logger.info('Initializing hooks...')
    const id = Symbol('id')

    const cancelSpinner = callLater(async () => {
      await this.notificationManager.show({
        id,
        icon: 'hourglass-start',
        message: __('LOADING'),
        details: __('LOADING_PLEASE_WAIT'),
        persistent: true
      })
    }, 1000)

    const defaultFeatures = {
      dataConnection: this.dataConnection.connect()
    }

    try {
      await this.hookResolver.resolve(
        { enabledHooks, defaultFeatures },
        (hooks, features) => {
          this.hooks = hooks
          this.features = features
          this.initializeDefaultsStrategy(features)
        }
      )
    } catch (e) {
      this.logger.error(e)
      await this.notificationManager.show({
        message: __('MODULE_ERROR'),
        type: NOTIFICATION.ERROR,
        persistent: true,
        unclosable: true,
        details: extractErrorMessage(e)
      })
    }

    cancelSpinner()
    await this.notificationManager.destroy(id)
    this.logger.info('Hooks initialized')
  }

  /**
   * The target object, access its dependencies (services) from a locator (registry),
   * that helps to search for the dependencies requested, and provide it to the
   * target object.
   * @property
   * @readonly
   */
  get serviceLocator () : iServiceLocator {
    return serviceLocator
  }

  async initializeExtensions () : Promise<void> {
    const extensions : Array<iStockExtension> = [
      new ErrorNotificationsExtension(),
      new ShortcutsExtension(),
      new AboutExtension(),
      new EasterEggsExtension(),
      new UpdatesObserverExtension(),
      new WidgetFactoryExtension()
    ]

    for (const extension : iStockExtension of extensions) {
      try {
        await extension.activate(this)
      } catch (error) {
        this.logger.error('Unable to activate one or more stock extensions', error)
      }
    }
  }

  /**
   * Application can be started only once.
   * Do not use this method directly
   * @method
   */
  async start () : Promise<void> {
    this.emit(Application.EVENT.BEFORE_START)
    this.logger.info('Starting application')

    const currentPresetHooks = sortBy(window.PRESET.hooks, 'id').map(({ name }) => name)
    await this.initializeHooks(currentPresetHooks)

    this.logger.info('Application started')
    this.emit(Application.EVENT.START)
    Object.freeze(this)
  }
}

const application = global.application = new Application()

Promise.all([
  application.windowManager.ready,
  application.notificationManager.ready,
  application.tooltipManager.ready
]).then(() => {
  application.start()
})
