/**
 * @file Spinner.js
 * @ignore
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 11th November 2022 11:07:52 am
 * @copyright 2015 - 2022 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { SpinnerProps } from './types'

import Icon from './Icon'
import { __ } from 'web-panel/globals'
import * as React from 'react'
import cn from 'classnames'

function Spinner (props: SpinnerProps) : React.Element<'div'> {
  const { className } = props
  return (
    <div className={cn('spinner-component', className)}>
      <Icon className='tip-icon spin' icon='sun' />
      <span className='resolving'>{__('LOADING')}</span>
    </div>
  )
}

export default Spinner
