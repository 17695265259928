/**
 * @file ReferenceSelect.js
 * @project Web-panel
 * @ignore
 * @author Pavel Shabardin (<bigbn@mail.ru>) Wednesday, 13th October 2021 10:25:11 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { _fieldNaming, ReferenceSelectProps } from './types'
import type { Entity, iWindowManager, iExposedView, WindowButton, ReactRef } from '../types'
import type { ID, iDataProvider, iDataManager, WaterlineQuery } from 'web-panel-essentials/types'

import * as React from 'react'
import { AutocompleteField } from './AutocompleteField'
import Action from './Action'
import { __ } from '../globals'
import { OperationCancelled } from 'web-panel-essentials/errors'
import ReferenceView from '../views/ReferenceView'
import { CAST } from 'web-panel-essentials/misc'
import autoBind from 'react-autobind'
import injectDataProvider from './HOCs/injectDataProvider'

type Props<T> = {|
  ...ReferenceSelectProps<T>,
  ..._fieldNaming<T>
|}

type RefSelectWindowOptions<T> = {|
  dataProvider: iDataProvider<T>,
  dataManager?: iDataManager<T>,
  filter?: WaterlineQuery,
  dialogOptions?: {| actions?: WindowButton[], parent?: ID |}
|}

/**
 * Text input with ability to select value from provided dataset.
 * Autocomplete supported.
 */
class ReferenceSelect<T:Entity> extends React.PureComponent<Props<T>> {
  autocomplete: ReactRef<typeof AutocompleteField>
  constructor (props: Props<T>) {
    super(props)
    autoBind(this)
    this.autocomplete = React.createRef()
  }

  focus () {
    this.autocomplete.current?.focus()
  }

  invokeReferenceSelect ({ dataProvider, dataManager, dialogOptions = {}, filter }: RefSelectWindowOptions<T>) : Promise<Array<T>> {
    const windowManager: iWindowManager = this.props.windowManager

    return new Promise(async (resolve, reject) => { // eslint-disable-line
      const id = Symbol('ref-view')
      const { actions = [], ...options } = dialogOptions

      const dialogDefaults = {
        id,
        title: __('ITEMS'),
        label: __('SELECT_ITEM'),
        icon: 'list',
        width: 50,
        widthUnit: 'rem',
        expandable: true,
        buttons: [{
          name: 'cancel',
          label: __('CANCEL'),
          title: __('CANCEL'),
          action: async () => {
            windowManager.close(id)
            reject(new OperationCancelled(__('CANCELLED')))
          }
        },
        ...actions,
        {
          name: 'save',
          isPrimary: true,
          label: __('SELECT'),
          title: __('SELECT'),
          action: async (referenceView: iExposedView<T[]>) => {
            const selectedRecords: T[] = await referenceView.data()
            resolve(selectedRecords)
            windowManager.close(id)
          }
        }]
      }

      await windowManager.show({
        ...{ ...dialogDefaults, ...options },
        view: (
          <ReferenceView
            filter={filter}
            dataProvider={dataProvider}
            dataManager={dataManager}
            onDoubleClick={({ record }) => {
              windowManager.close(id)
              resolve([record])
            }}
          />
        )
      })
      await windowManager.activate(id)
    })
  }

  async handleOpenReference () : Promise<void> {
    const { disabled, readOnly, onChange, valueKey, displayValueKey, onRecordsChange } = this.props
    if (disabled || readOnly) return

    try {
      const [record] = await this.invokeReferenceSelect({
        dataProvider: this.props.dataProvider,
        dataManager: this.props.dataManager,
        filter: this.props.filter,
        dialogOptions: {
          parent: this.props.parentWindowId,
          actions: this.props.actions
        }
      })

      this.focus()
      if (this.props.disabled || this.props.readOnly) return

      if (record) {
        const changes = {
          value: record[valueKey],
          displayValue: CAST.String(record[displayValueKey])
        }
        onChange(changes)
        if (onRecordsChange) onRecordsChange([record])
      } else {
        onChange({ value: undefined, displayValue: '' })
        if (onRecordsChange) onRecordsChange([])
      }
    } catch (e) {
      console.log(e)
    }
  }

  render () : React.Node {
    const {
      dataProvider, value, displayValue, valueKey, displayValueKey, onChange,
      containerClassName, inputClassName, onFocus, onBlur, disabled, readOnly,
      tabIndex, placeholder, catchFocusOnMount, noResetControl, invalid, testName,
      onClick, onKeyDown, onKeyPress, onEraceKey, onUpKey, onDownKey, onEnterKey,
      onEscapeKey, onTabKey, title, filter, valuesBasedBlur
    } = this.props
    const autocompleteProps = {
      valuesBasedBlur,
      filter,
      dataProvider,
      value,
      displayValue,
      valueKey,
      displayValueKey,
      onChange,
      containerClassName,
      inputClassName,
      onFocus,
      onBlur,
      disabled,
      readOnly,
      tabIndex,
      placeholder,
      catchFocusOnMount,
      noResetControl,
      invalid,
      testName,
      onClick,
      onKeyDown,
      onKeyPress,
      onEraceKey,
      onUpKey,
      onDownKey,
      onEnterKey,
      onEscapeKey,
      onTabKey,
      title
    }

    return (
      <AutocompleteField ref={this.autocomplete} {...autocompleteProps}>
        <Action
          disabled={disabled || readOnly}
          onClick={this.handleOpenReference}
          icon='ellipsis-h'
        />
      </AutocompleteField>
    )
  }
}

const withDataProviderInjected: React.AbstractComponent<ReferenceSelectProps<Entity>> = injectDataProvider(ReferenceSelect)

export default withDataProviderInjected
