/**
 * @file Icon.js
 * @ignore
 * @author Pavel Shabardin (<bigbn@mail.ru>) Wednesday, 20th October 2021 12:22:31 pm
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow strict
 */

import type { IconProps } from './types'

import * as React from 'react'
import cn from 'classnames'

/**
 * Common icon widget.
 * Current implementation uses FontAwesome v6 free solid icons set.
 *
 * @function
 * @example <caption>Import</caption>
 * import { Icon } from 'web-panel/components'
 * @example <caption>Usage</caption>
 * function AlarmWidget (props: {}) : React.Node {
 *   return (
 *      <Icon name='alarm' className='interactive' onClick={() => logger.info('Alarm clicked')} />
 *   )
 * }
 *
 */
function Icon (props: IconProps) : React.Node {
  let { title, icon, style, className, containerClassName, tabIndex, onClick, onDoubleClick, onMouseOver, onMouseOut } = props
  className = cn('icon', 'fa-solid ', 'fa-' + icon, className)
  containerClassName = cn('icon-wrapper', containerClassName)
  return React.useMemo(() => (
    <span
      className={containerClassName}
      data-title={title}
      tabIndex={tabIndex}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <i className={className} style={style} />
    </span>
  ),
  [icon, className, containerClassName])
}

export default Icon
