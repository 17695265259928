// $FIXME: Нелегальный иммигрант, надо его депортировать из ядра
/**
 * @file LocationView.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Monday, 1st June 2020 12:33:10 pm
 * @copyright 2019 - 2020 SKAT LLC, Delive LLC
 * @flow
 */
import type { iLogger, iExposedView, iSettingsManager, CombinedValue, ReactRef } from '../../types'
import type { Point } from 'web-panel-essentials/types'
import type { LocationPoint } from 'skat-js/types'
import type { MapSpecification, PanStrategyArgs } from 'web-panel/components/types'

import * as React from 'react'
import { Row, Col, Map, PointMarker, NumericField } from 'web-panel/components'
import { Inject } from '../../serviceLocator'
import { __ } from 'web-panel/globals'
import { SETTINGS } from 'skat-js/constants'
import autoBind from 'react-autobind'
import { CAST } from 'web-panel-essentials/misc'
import debounce from 'lodash/debounce'
import omit from 'lodash/omit'

export type Props = {
  center: [number, number],
  point?: LocationPoint,
  cityId: number,
  serviceId: number
}

export type State = {
  point: [string, string],
  pointData: $Shape<LocationPoint>
}

const DEFAULT_FRACTION = 6
const LATITUDE_LIMITS = [-180, 180]
const LONGITUDE_LIMITS = [-90, 90]

class LocationView extends React.PureComponent<Props, State> implements iExposedView<?LocationPoint> {
  @Inject addressProvider : {reverseGeocode: Function}
  @Inject logger: iLogger
  @Inject settingsManager : iSettingsManager

  latitudeInput: ReactRef<typeof NumericField>

  constructor (props: Props) {
    super(props)
    autoBind(this)
    this.latitudeInput = React.createRef()

    const point = props.point || {}
    this.state = {
      point: [
        point.latitude ? point.latitude.toFixed(DEFAULT_FRACTION) : '',
        point.longitude ? point.longitude.toFixed(DEFAULT_FRACTION) : ''
      ],
      pointData: omit(props.point, ['latitude', 'longitude'])
    }
  }

  componentDidMount () {
    this.activate()
  }

  activate () {
    this.latitudeInput.current?.focus()
  }

  async data () : Promise<?LocationPoint> {
    const { point, pointData } = this.state
    const [lat, lon] = point
    const payload = {
      ...pointData,
      latitude: CAST.Number(lat),
      longitude: CAST.Number(lon)
    }
    this.logger.info('Returning location point', payload)
    return payload
  }

  async command () {}

  handleClick (point: Point) {
    if (!this.addressProvider) { // Web-panel can't provide addressProvider by itself
      this.logger.warn('This component requires "addressProvider" with "reverseGeocode" method')
      return
    }
    const [latitude, longitude] = point
    this.setState({
      point: [
        latitude.toFixed(6),
        longitude.toFixed(6)
      ]
    })
  }

  async componentDidUpdate (prevProps: Props, prevState: State) {
    if (!this.addressProvider) { // Web-panel can't provide addressProvider by itself
      this.logger.warn('This component requires "addressProvider" with "reverseGeocode" method')
      return
    }

    const point = this.state.point
    const prevPoint = prevState.point

    if (point && (!prevPoint || prevPoint[0] !== point[0] || prevPoint[1] !== point[1])) {
      const cityId = this.props.cityId
      const serviceId = this.props.serviceId
      this.geocode(CAST.Number(point[0]), CAST.Number(point[1]), cityId, serviceId)
    }
  }

  geocode : (number, number, number, number) => void = debounce(async (latitude, longitude, cityId, serviceId) => {
    const [response] = await this.addressProvider.reverseGeocode({ where: { latitude, longitude, cityId, serviceId } })
    if (response) {
      const { street, house, regionId, markupFixed, markupPercent } = response
      // $FlowFixMe[incompatible-call]
      this.setState({
        pointData: {
          street,
          house: house || null, // undefined will be ignored
          regionId,
          markupFixed,
          markupPercent
        }
      })
    }
  }, 100)

  handleLatitudeChange ({ displayValue }: CombinedValue) {
    const { point } = this.state
    this.setState({
      point: [displayValue, point[1]]
    })
  }

  handleLongitudeChange ({ displayValue }: CombinedValue) {
    const { point } = this.state
    this.setState({
      point: [point[0], displayValue]
    })
  }

  async mapSpecification () : Promise<MapSpecification> {
    const settings = await this.settingsManager.getValues([SETTINGS.GEO_TILES, SETTINGS.GEO_API_KEY])
    return {
      type: settings[SETTINGS.GEO_TILES],
      key: settings[SETTINGS.GEO_API_KEY]
    }
  }

  panStrategy ({ width, height, zoom, center }: PanStrategyArgs) : { zoom: ?number, center: Point } {
    const { point } = this.state
    if (!point) return { zoom, center }
    if (!point[0] && !point[1]) return { zoom, center }
    return {
      zoom: 17,
      center: [CAST.Number(point[0]), CAST.Number(point[1])]
    }
  }

  render () : React.Node {
    const { center } = this.props
    const { point, pointData } = this.state
    const markerPosition = [CAST.Number(point[0]), CAST.Number(point[1])]

    const { street, house } = pointData || {}
    const details = [street, house].join(' ').trim() || __('UNKNOWN_ADDRESS')
    return (
      <div className='h100 vertical-flex'>
        <Row className='h100'>
          <Col className='h400px' size={12}>
            <Map specification={this.mapSpecification} center={center} onClick={this.handleClick} panStrategy={this.panStrategy}>
              {(props) => (
                <>
                  {
                  markerPosition
                    ? (<PointMarker {...props} point={markerPosition} name='⇣' details={details} />)
                    : null
                  }
                </>
              )}
            </Map>
          </Col>
        </Row>
        <Row className='half-padding'>
          <Col size={3} className='left-label a-v'>
            {__('LATITUDE')}
          </Col>
          <Col size={3} className='a-v'>
            <NumericField
              ref={this.latitudeInput}
              hideInlineCalculator
              limit={LATITUDE_LIMITS}
              displayValue={point[0]}
              onChange={this.handleLatitudeChange}
            />
          </Col>
          <Col size={3} className='left-label a-v'>
            {__('LONGITUDE')}
          </Col>
          <Col size={3} className='a-v'>
            <NumericField
              hideInlineCalculator
              limit={LONGITUDE_LIMITS}
              displayValue={point[1]}
              onChange={this.handleLongitudeChange}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

export default LocationView
