/**
 * @file ExtensionsManager.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 13th December 2019 3:58:04 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow
 */
import type { iLogger, iExtensionsManager, iExtension } from '../types'
import type { ID } from 'web-panel-essentials/types'

import * as React from 'react'
import { Injectable, Inject } from '../serviceLocator'
import { CAST } from 'web-panel-essentials/misc'

type Extendable = {|
  point: React.Node,
  handleExtensionsChange: Function
|}

@Injectable('extensionsManager', true)
class ExtensionsManager implements iExtensionsManager {
  @Inject logger : iLogger

  extendables: Map<ID, Extendable>
  extensions: Array<any>

  constructor () {
    this.extendables = new Map()
    this.extensions = []
    this.logger.namespace = 'extensions'
  }

  getExtensions (extendableName: ID) : Array<iExtension> {
    return this.extensions.filter(({ targetName }) => targetName === extendableName).map(({ extension }) => extension) || []
  }

  registerExtendable (extendableName: ID, point: Object, handleExtensionsChange: Function) {
    this.logger.info(`*** Registered new extension point "${CAST.String(extendableName)}" ***`)
    this.extendables.set(extendableName, { point, handleExtensionsChange })
  }

  registerExtension (targetName: ID, extension: iExtension) {
    this.extensions.push({ targetName, extension })
    const extendable = this.extendables.get(targetName)
    if (extendable) {
      extendable.handleExtensionsChange(this.getExtensions(targetName))
    } else {
      this.logger.warn('Extension was registered before extendable point appeared')
    }
  }

  getWrapper (extendableName: ID, parent: any) : (wrapped: React.Node) => Array<React.Node> {
    return (Wrapped: React.Node) => {
      const extensions : iExtension[] = this.getExtensions(extendableName)
      return [
        ...extensions.map(extension => extension.before ? extension.before(parent) : null),
        Wrapped, // TODO: Replace
        ...extensions.map(extension => extension.after ? extension.after(parent) : null)
      ]
    }
  }
}

export default ExtensionsManager
