/**
 * @file ButtonListView.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Wednesday, 2nd December 2020 6:48:47 am
 * @copyright 2015 - 2020 SKAT LLC, Delive LLC
 * @flow
 */
import type { iToolbarButton } from 'web-panel/types'

import cn from 'classnames'
import * as React from 'react'
import { genTestName } from 'web-panel/globals'
import { Icon } from 'web-panel/components'

export type ButtonProps = {
  type: 'local' | 'global',
  button: iToolbarButton,
  onClick: (button: iToolbarButton) => void | Promise<void>
}

export type ButtonState = {
  pending: boolean
}

export type ListProps = {
  type: 'local' | 'global',
  buttons: Array<iToolbarButton>,
  onClick: (button: iToolbarButton) => void | Promise<void>
}

class Button extends React.Component<ButtonProps, ButtonState> {
  constructor () {
    super()
    this.state = {
      pending: false
    }
  }

  handleClick = async () => {
    this.setState({ pending: true }, async () => {
      await this.props.onClick(this.props.button)
      this.setState({ pending: false })
    })
  }

  render () : React.Node {
    const { pending } = this.state
    const { type } = this.props
    const { title, disabled, needShowSeparator, icon = 'circle', testName } = this.props.button
    return (
      <>
        <div
          data-title={title}
          onMouseDown={this.handleClick}
          className={cn('toolbar-button', type, { disabled: disabled })}
          {...genTestName(testName)}
        >
          <Icon className='icon' icon={icon} />
          {
            pending
              ? (
                <>
                  <div className='circle' style={{ animationDelay: 0 }} />
                  <div className='circle' style={{ animationDelay: '1s' }} />
                  <div className='circle' style={{ animationDelay: '2s' }} />
                </>
                )
              : null
          }
        </div>
        {needShowSeparator ? <div className='separator' /> : null}
      </>
    )
  }
}

class ButtonList extends React.PureComponent<ListProps> {
  render () : React.Node {
    const { buttons, type } = this.props
    return (
      <div className='buttons-container'>
        {buttons.map((button, idx) => <Button key={idx} button={button} type={type} onClick={this.props.onClick} />)}
      </div>
    )
  }
}

export default ButtonList
