/**
 * @file Button.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 29th October 2019 1:15:44 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @ignore
 * @flow strict
 */
/* global SyntheticKeyboardEvent, HTMLElement */
import type { ReactRef, Button, iLogger } from '../types'

import * as React from 'react'
import cn from 'classnames'
import { Inject } from '../serviceLocator'
import { genTestName } from 'web-panel/globals'
import autoBind from 'react-autobind'
import { KEY, CAST } from 'web-panel-essentials/misc'
import { If, Icon } from 'web-panel/components'

export type State = {|
  isPending: boolean,
  isActive: boolean
|}

export type Props = {|
  ...Button
|}

export default class ButtonView extends React.Component<Props, State> {
  @Inject logger : iLogger

  componentMounted: boolean
  button: ReactRef<'div'>

  constructor (props: Props) {
    super(props)
    autoBind(this)
    this.state = {
      isPending: false,
      isActive: this.props.activeOnInit || false
    }
    this.button = React.createRef()
  }

  toggle (state: boolean) : void {
    this.setState({ isActive: state })
  }

  simulateClick () : void {
    this.button.current?.click()
  }

  componentDidMount () : void {
    this.componentMounted = true
    if (this.props.isPrimary) {
      this.button.current?.focus()
    }
  }

  componentWillUnmount () : void {
    this.componentMounted = false
  }

  async handleKeyDown (event: SyntheticKeyboardEvent<HTMLElement>) : Promise<void | boolean> {
    const { keyCode } = event
    if (keyCode === KEY.ENTER || keyCode === KEY.SPACE) {
      event.preventDefault()
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()
      this.handleActivate()
      return false
    }
  }

  handleActivate () : Promise<void> {
    return new Promise((resolve, reject) => {
      const { disabled, action } = this.props
      const { isPending } = this.state

      if (isPending) return
      if (disabled) return

      this.setState({ isPending: true }, async () => {
        try {
          await action()
        } catch (e) {
          reject(e)
          this.logger.error(e)
        }

        if (this.componentMounted) this.setState({ isPending: false })
        resolve()
      })
    })
  }

  render () : React.Node {
    const { title, disabled, active, label, icon, isPrimary, className, testName, tabIndex = 0 } = this.props
    const { isPending, isActive } = this.state

    return (
      <div
        type='button'
        tabIndex={disabled ? -1 : tabIndex}
        data-title={title}
        ref={this.button}
        {...genTestName(testName)}
        className={cn('button', 'iconized', className, { isPrimary, isPending, disabled })}
        onClick={this.handleActivate}
        onKeyDown={this.handleKeyDown}
      >

        <If condition={Boolean(icon)}>
          <div className={cn('icon-box', { active: active || isActive })}>
            <Icon className='icon' icon={CAST.String(icon)} />
          </div>
        </If>

        <If condition={Boolean(label)}>
          <span className='button-label'>{label}</span>
        </If>
      </div>
    )
  }
}
