/**
 * @file NotificationPreferences.js
 * @project web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 28th November 2023 10:23:44 am
 * @copyright 2015 - 2023 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { ReactRef, NotificationMessage } from 'web-panel/types'
import type { iDataProvider } from 'web-panel-essentials/types'

/* globals SyntheticEvent, HTMLDivElement */
import * as React from 'react'
import autoBind from 'react-autobind'
import { Row, Col, Icon, If /* Select */ } from 'web-panel/components'
import { Inject } from 'web-panel/serviceLocator'
import VirtualList from 'web-panel/components/VirtualList'
import { __, formatDistanceToNow } from 'web-panel/globals'
import CheckBox from 'web-panel/views/forms/components/CheckBox'
import InlineAction from 'web-panel/components/InlineAction'
import cn from 'classnames'
import { CAST } from 'web-panel-essentials/misc'

type Props = {|
  notificationsVisible: bool,
  onNotificationsVisibleToggle: () => void,
  onDestroyRequested: () => void
|}

type State = {|
  journal: NotificationMessage[]
|}

class NotificationPreferences extends React.PureComponent<Props, State> {
  @Inject notificationLog: iDataProvider<NotificationMessage> & { reset: () => void }
  container: ReactRef<'div'>

  constructor (props: Props) {
    super(props)
    autoBind(this)
    this.container = React.createRef()
    this.state = {
      journal: []
    }
  }

  async componentDidMount () {
    this.container.current?.focus()
    const journal = await this.notificationLog.get()
    this.setState({ journal })
  }

  handleGlobalMuteChanged () {
    this.props.onNotificationsVisibleToggle()
  }

  renderMessage (index: number) : React.Node {
    const { journal } = this.state
    const notification = journal[journal.length - 1 - index]

    const typeClass = ['type', notification.type].join('-')
    const className = cn('notifications-journal-item', typeClass)

    return (
      <Row key={index} className={className}>
        <Col size={1}>
          <If condition={Boolean(notification.icon)}>
            <Icon className='mini-padding' icon={CAST.String(notification.icon)} />
          </If>
        </Col>
        <Col size={11}>
          <Row className='header'>
            <Col size={12}>
              <b>{notification.message}</b>
            </Col>
          </Row>
          <Row>
            <Col className='details' size={12}>
              <span data-title={notification.details}>
                {notification.details || ''}
              </span>
            </Col>
          </Row>
          <Row className='meta'>
            <Col size={12} className='right-aligned'>
              {formatDistanceToNow(notification.time || new Date(), {})} {__('AGO')}
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  renderEmptyItem () : React.Node {
    return <div />
  }

  handleBlur (event: SyntheticEvent<HTMLDivElement>) : void {
    const { currentTarget } = event
    global.requestAnimationFrame(() => {
      if (!currentTarget.contains(document.activeElement)) {
        this.props.onDestroyRequested()
      }
    })
  }

  handleClear () : void {
    this.setState({ journal: [] })
    this.notificationLog.reset()
  }

  render () : React.Node {
    const { journal } = this.state
    const { notificationsVisible } = this.props

    return (
      <div className='notification-prefs' tabIndex={1} onBlur={this.handleBlur} ref={this.container}>
        <Row>
          <Col size={12} className='right-aligned'>
            <CheckBox
              reversed
              value={!notificationsVisible}
              label={__('VISUAL_MUTE_NOTIFICATIONS')}
              onChange={this.handleGlobalMuteChanged}
            />
          </Col>
        </Row>
        {/* <Row>
          <Col size={12}>
            <h4 className='full-top-margin'>{__('NOTIFICATIONS_GROUPS_ENABLED')}</h4>
          </Col>
        </Row>

        <Row>
          <Col size={1}>
            <Icon icon='star' />
          </Col>
          <Col size={11}>
            <CheckBox value label={__('Operation Status')} />
          </Col>
        </Row>

        <Row>
          <Col size={1}>
            <Icon icon='star' />
          </Col>
          <Col size={11}>
            <CheckBox value label={__('Critical Errors')} />
          </Col>
        </Row>

        <Row>
          <Col size={1}>
            <Icon icon='star' />
          </Col>
          <Col size={11}>
            <CheckBox value label={__('System Status')} />
          </Col>
        </Row>

        <Row>
          <Col size={1}>
            <Icon icon='star' />
          </Col>
          <Col size={11}>
            <CheckBox value label={__('Restricted Access')} />
          </Col>
        </Row> */}

        <Row>
          <Col size={12}>
            <h4 className='full-top-margin'>{__('LOG')}</h4>
          </Col>
        </Row>
        <Row>
          <Col size={12}>
            <If condition={journal.length > 0}>
              <VirtualList
                itemsCount={journal.length}
                pageSize={7}
                itemRenderer={this.renderMessage}
                emptyItemRenderer={this.renderEmptyItem}
              />
            </If>
            <If condition={journal.length === 0}>
              <div className='muted center full-padding full-margin'>
                {__('NO_ITEMS_TO_DISPLAY')}
              </div>
            </If>
          </Col>
        </Row>
        <If condition={journal.length > 0}>
          <Row>
            <Col size={12} className='right-aligned'>
              <InlineAction onClick={this.handleClear}>{__('CLEAR')}</InlineAction>
            </Col>
          </Row>
        </If>
        {/* <Row>
          <Col size={3}>
            {__('POSITION')}
          </Col>
          <Col size={9}>
            <Select containerClassName='inverse-colors' dataProvider={this.autoProvider} label={__('VISUAL_MUTE_NOTIFICATIONS')} />
          </Col>
        </Row> */}
      </div>
    )
  }
}

export default NotificationPreferences
