/**
 * @file RadiusOverlay.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 8th July 2022 10:11:40 am
 * @copyright 2015 - 2022 SKAT LLC, Delive LLC
 * @flow strict
 * @ignore
 */
import type { Node } from 'react'
import type { MapChildrenProps } from '../types'

import React from 'react'
import { computeDestinationPoint } from 'geolib'

type Props = {|
  ...MapChildrenProps,
  radius: number
|}

function RadiusOverlay (props: Props) : Node {
  const [latitude, longitude] = props.center
  const [left, top] = props.latLngToPixel([latitude, longitude])

  const edgePoint = computeDestinationPoint({ latitude, longitude }, props.radius, 180)
  const [, edgeTop] = props.latLngToPixel([edgePoint.latitude, edgePoint.longitude])
  const radiusSize = edgeTop - top
  const style = { left: left - radiusSize, top: top - radiusSize, width: radiusSize * 2, height: radiusSize * 2 }
  return (
    <div
      className='radius-overlay'
      style={style}
    />
  )
}

export default RadiusOverlay
