/**
 * @file HeatOverlay.js
 * @ignore
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 9th September 2022 11:40:45 am
 * @copyright 2015 - 2022 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { MapChildrenProps } from '../types'
import type { Point } from 'web-panel-essentials/types'

import Canvas from './Canvas'

export type Props = {|
  points: Point[],
  ...MapChildrenProps
|}

export type State = {}

class HeatOverlay extends Canvas<Props, State> {
  componentDidUpdate (lastProps: Props, lastState: State) {
    let { points, zoom, width, height } = this.props
    zoom = Math.ceil(zoom)
    width = Math.ceil(width)
    height = Math.ceil(height)
    this.renderGrid(points, width, height, zoom)
  }

  rgba (r: number, g: number, b: number, a: number) : string {
    return `rgba(${r}, ${g}, ${b}, ${a})`
  }

  renderGrid (points: Point[], width: number, height: number, zoom: number) {
    global.requestAnimationFrame(() => {
      const context = this.context2D
      if (!context) return

      context.globalCompositeOperation = 'multiply'
      context.clearRect(0, 0, width, height)
      const grouped = {}
      const regionSize = 0.01
      const precision = 4

      let size = 0
      let maxValue = 0

      for (const point of points) {
        const [lat, lng] = [+(point[0]).toPrecision(precision), +(point[1]).toPrecision(precision)]
        const coords = this.props.latLngToPixel([lat, lng])
        const end = this.props.latLngToPixel([lat + regionSize, lng + regionSize])

        const x = Math.ceil(coords[0])
        const y = Math.ceil(coords[1])
        size = Math.abs(x - Math.ceil(end[0]))

        const key = [x, y].join(':')
        grouped[key] = grouped[key] + 1 || 1
        maxValue = Math.max(grouped[key], maxValue)
      }

      context.filter = `blur(${Math.ceil(size / 2)}px)`

      for (const key in grouped) {
        const count = grouped[key]
        if (count <= 1) continue
        const [x, y] = key.split(':').map(Number)
        const rangedValue = 150 / maxValue * count
        const color = this.rgba(255 - rangedValue, 255 - rangedValue, 255, 1)
        context.fillStyle = color
        context.fillRect(x, y, size, size)
      }
    })
  }
}

export default HeatOverlay
