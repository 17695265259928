/**
 * @file CheckBox.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 22nd October 2019 4:16:44 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow strict
 */
/* global SyntheticKeyboardEvent, HTMLInputElement, SyntheticEvent, HTMLElement */
import type { CombinedValue, asyncVoid } from '../../../types'

import * as React from 'react'
import cn from 'classnames'
import shortid from 'shortid'
import { genTestName } from 'web-panel/globals'
import autoBind from 'react-autobind'

export type Props = {|
  label: string,
  value: boolean,
  title?: string,
  testName?: string,
  reversed?: boolean,
  onChange?: (changes: CombinedValue) => asyncVoid,
  onFocus?: () => asyncVoid,
  onBlur?: () => asyncVoid,
  className?: string,
  disabled?: boolean,
  readOnly?: boolean
|}

export type State = {|
  id: string,
  focused: boolean
|}

/**
 * You set it with value; it will return CombinedValue with the same values in
 * onchange callback
 */
class CheckBox extends React.PureComponent<Props, State> {
  constructor (props: Props) {
    super(props)
    this.props = props
    autoBind(this)

    this.state = {
      id: shortid(),
      focused: false
    }
  }

  triggerChange (changes: CombinedValue) {
    this.props.onChange && this.props.onChange(changes)
  }

  async handleClick (event: SyntheticKeyboardEvent<HTMLInputElement>) {
    const { value, readOnly, disabled } = this.props
    if (readOnly || disabled) return

    this.triggerChange({
      value: !value,
      displayValue: String(!value)
    })
  }

  handleFocus (event: SyntheticEvent<HTMLElement>) {
    this.setState({ focused: true })
    this.props.onFocus && this.props.onFocus()
  }

  handleDoubleClick (event: SyntheticEvent<HTMLElement>) {
    event.stopPropagation()
    event.preventDefault()
  }

  handleBlur () {
    this.setState({ focused: false })
    this.props.onBlur && this.props.onBlur()
  }

  render () : React.Node {
    const { focused, id } = this.state
    const { className, disabled, title, value, label, reversed, testName, readOnly } = this.props

    return (
      <div
        onDoubleClick={this.handleDoubleClick}
        className={cn('field-checkbox', className, { focused, readOnly, disabled, reversed })}
        data-title={title}
        onClick={this.handleFocus}
      >
        <label className='for-checkbox' htmlFor={id}>
          <input
            {...genTestName(testName)}
            id={id}
            type='checkbox'
            checked={Boolean(value)}
            onChange={this.handleClick}
            readOnly={readOnly}
            disabled={disabled}
          />
          <span className='checkbox' />
          <span className='checkbox-label'>{label}</span>
        </label>
      </div>
    )
  }
}

export default CheckBox
