/**
 * @module ProtocolConnection
 * @memberof web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 15th August 2019 5:48:12 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow
 */
import type { iLogger, TCPConnectionTarget, EmitterEvents, iProtocolConnection, JSONValue, ServerSessionDetails } from '../types'
import type { ID, Credentials } from 'web-panel-essentials/types'
import type { CommandPayload, HandlerPayload, RequestPayload } from 'skat-js/types'

import EventEmitter from 'web-panel/utils/EventEmitter'
import { __ } from 'web-panel/globals'
import { Inject, Injectable } from '../serviceLocator'
import { Deprecated } from 'web-panel-essentials/decorators'
import { COMMAND } from 'skat-js/constants'
import { PROTOCOL_CONNECTION_EVENT } from './events'
import { spawn, Worker, registerSerializer } from 'threads'
import { ProtocolError, ServerUnreachableError, UnresolvedDependencyError } from '../errors'

// $FlowFixMe
import workerURL from 'threads-plugin/dist/loader?name=protocol!./protocol.async-worker.js' // eslint-disable-line import/no-webpack-loader-syntax

type RPCEventPayload = {
  data: {
    type: 'RPC' | 'PROXY_EVENT',
    name: string,
    payload: JSONValue
  }
}

registerSerializer({
  deserialize (message, defaultHandler) {
    if (message && message.__type === 'TransferableProtocolError') {
      return ProtocolError.deserialize(message.payload)
    } else return defaultHandler(message)
  },
  serialize: (thing, defaultHandler) => defaultHandler(thing)
})

/**
* @classdesc Full protocol description, see at {@link https://npm.cloudtaxi.ru/-/web/detail/skat-js}
* @kind class
* @constructorComment blavla
* @extends EventEmitter
* @implements iProtocolConnection
* @copyright SKAT LLC, Delive LLC 2015-2019
* @author Pavel Shabardin <bigbn@mail.ru>
* @since 1.0.0
* @memberof Injectable
* @example
* import { Inject } from 'web-panel/serviceLocator'
* import { PROTOCOL } from 'skat-js/contants'
*
* class AbstractOrderCreator {
*   \@Inject protocolConnection : iProtocolConnection
*   constructor() {
*     this.protocolConnection.command(PROTOCOL.COMMAND.ORDER_CREATED(1235))
*   }
* }
*/
@Injectable('protocolConnection', true)
class ProtocolConnection extends EventEmitter implements iProtocolConnection {
  @Inject logger : iLogger

  name: string
  worker: any

  _authComplete: Promise<void>
  _timeDifference: number
  _sessionId: ?string

  /**
   * Protocol specific events (static propery)
   * @static
   * @property
   */
  static get EVENT () : EmitterEvents {
    return PROTOCOL_CONNECTION_EVENT
  }

  /**
   * Protocol specific events (instance property)
   * @property
   */
  get EVENT () : EmitterEvents {
    return ProtocolConnection.EVENT
  }

  /**
   * Get current connection status
   * @property
   */
  get connected () : boolean {
    return this.worker.isConnected()
  }

  /**
   * @hideconstructor
   */
  constructor ({ logger }: { logger?: iLogger } = {}) {
    super()
    this.name = 'ProtocolConnection'
    this.logger = logger || this.logger
    if (!this.logger) throw new UnresolvedDependencyError(__('NO_LOGGER_PROVIDED'))

    this.logger.namespace = 'protocol'
    this.logger.info('New connection class initialized')
  }

  getLogLevel () : 'error' | 'debug' {
    let level = 'error'
    try {
      const debug : string | null = global.localStorage.getItem('debug')
      if (debug) {
        const debuggers = debug.split(',')
        if (debuggers.includes('WP:protocol') || debuggers.includes('*') || debuggers.includes('WP:*') || debuggers.includes('WP*')) {
          level = 'debug'
        }
      }
    } catch (ignored) {}
    return level
  }

  async getWorker () : any {
    if (this.worker) return this.worker
    else {
      const worker = new Worker(workerURL)
      this.worker = await spawn(worker, { timeout: 30000 })
      worker.onmessage = this.proxyEvents.bind(this)
      if (this.getLogLevel() === 'debug') this.worker.enableDebug()
      return this.worker
    }
  }

  /**
   * Re-emit every worker event outside
   * @private
   */
  proxyEvents ({ data }: RPCEventPayload) {
    if (data.type === 'PROXY_EVENT') {
      if (data.name === PROTOCOL_CONNECTION_EVENT.COMMAND) {
        const [commandName, ...args] = data.payload
        this.emit(commandName, ...args)
        this.emit(PROTOCOL_CONNECTION_EVENT.COMMAND, data.payload) // TODO: Надо избавиться
      } else this.emit(data.name, data.payload)
    }
  }

  /**
  * Connecting using default transport to server
  * @method
  */
  async connect (target?: TCPConnectionTarget) : Promise<void> {
    this.logger.info('Connecting to protocol')
    const worker = await this.getWorker()
    try {
      await worker.connect(target)
    } catch (e) {
      this.logger.error(e)
      throw new ServerUnreachableError(__('SERVER_IS_UNREACHABLE'))
    }
  }

  /**
  * Close connection
  * @method
  */
  async disconnect () {
    this.removeAllListeners()
    const worker = await this.getWorker()
    await worker.disconnect()
  }

  /**
   * Retrieves time difference between server time(considered correct)
   * @method
   */
  getServerTimeDifference (): number {
    return this._timeDifference
  }

  getCurrentSessionId (): ?string {
    return this._sessionId
  }

  /**
  * Use provided credentials to obtain session from server
  * @method
  */
  async authorize (credentials: Credentials | ID, extraOptions: Object) : Promise<ServerSessionDetails> {
    this.logger.info('Protocol: authorizing')

    const worker = await this.getWorker()
    this._authComplete = worker
      .authorize(credentials, { ...extraOptions, serverVersion: window.SERVER.VERSION })
      .then(({ sessionId, delta, userId }) => {
        this._timeDifference = delta
        this._sessionId = sessionId
        return { sessionId, delta, userId }
      })

    return this._authComplete
  }

  /**
   * Returns Promise that resolves after server auth complete
   * @method
   */
  authorizationComplete () : Promise<void> {
    return this._authComplete
  }

  invokeSyntethicMessage (message: String) : void {
    this.emit('syntheticMessage', { data: message })
  }

  /**
   * Attach handler function to process specific server event
   * @see https://npm.cloudtaxi.ru/-/web/detail/skat-js
   * @method
   * @example
   * this.protocolConnection.addHandler(PROTOCOL.HANDLER.ALERT(
   *   async (code: string, longitude: number, latitude: number) => {
   *     this.soundPlayer.play(this.soundPlayer.SOUND.GAME_OVER_LOSER)
   *   })
   * )
   */
  addHandler (payload: HandlerPayload) {
    this.logger.info('Registered a new handler for', payload.command)
    if (payload.handler) this.on(payload.command, payload.handler)
  }

  /**
   * Remove handler function
   * @see https://npm.cloudtaxi.ru/-/web/detail/skat-js
   * @method
   */
  removeHandler (payload: HandlerPayload) {
    this.logger.info('Removint handler for', payload.command)
    if (payload.handler) this.off(payload.command, payload.handler)
  }

  /**
   * Send command with payload to server
   * @see https://npm.cloudtaxi.ru/-/web/detail/skat-js
   * @method
   * @example
   * this.protocolConnection.command(PROTOCOL.COMMAND.ORDER_CREATED(id))
   */
  command (payload: CommandPayload) {
    this.getWorker().then((worker) => {
      worker.sendCommand(payload.command, ...(payload.args || []))
    })
  }

  /**
   * Send AJAX-like request to a server
   * @method
   * @example
   * const request = PROTOCOL.REQUEST.GET_CLIENT_PRIORITY(clientPhone, source, CAST.Number(lastState.serviceId))
   * const priority = await this.protocolConnection.query(request)
   */
  async query (payload: RequestPayload, options?: { timeout: ?number | null }) : Promise<mixed> {
    const worker = await this.getWorker()
    return worker.request({
      command: payload.command,
      timeout: options ? options.timeout : null
    }, ...(payload.args || []))
  }

  /**
   * @inner
   * @deprecated
   */
  @Deprecated('Method "sendCommand" is deprecated, use "addHandler" and "command" instead')
  sendCommand (command: $Values<typeof COMMAND>, ...args: Array<mixed>) : void {
    this.getWorker().then((worker) => {
      worker.sendCommand(command, ...args)
    })
  }

  /**
   * @inner
   * @deprecated
   */
  @Deprecated('Method "commandToArray" is internal and will be deprecated soon')
  commandToArray (message /*: string */) /*: Array<string> */ {
    if (!message) return []
    let command = message.startsWith('$;') ? message.substring(2) : message.substring(1)
    if (command.substring(command.length - 3, command.length - 2) === '*') {
      command = command.substring(0, command.length - 3)
    }
    return command.split(';')
  }
}

export default ProtocolConnection
