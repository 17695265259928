/**
 * @file ModalList.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 5th July 2019 5:32:27 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow strict
 */
/* global SyntheticWheelEvent, HTMLDivElement, TimeoutID */
import type { asyncVoid, ReactRef } from 'web-panel/types'
import type { ID } from 'web-panel-essentials/types'
import type { WindowLink } from '../../managers/window/WindowRegistry'

import * as React from 'react'
import Indicator from './Indicator'
import autoBind from 'react-autobind'
import windowRegistry from '../../managers/window/WindowRegistry'

type Props = {|
  syncId?: ?ID,
  active: ?ID,
  links: WindowLink[],
  onIndicatorClick: (id: ID) => asyncVoid
|}

class ModalList extends React.PureComponent<Props> {
  container: ReactRef<'div'>
  task: TimeoutID
  deltaY: number

  constructor (props: Props) {
    super(props)
    autoBind(this)
    this.deltaY = 0
    this.container = React.createRef()
  }

  handleClick (id: ID) {
    this.props.onIndicatorClick(id)
  }

  handleWheel (event: SyntheticWheelEvent<HTMLDivElement>) {
    this.deltaY += event.deltaY

    clearTimeout(this.task)
    this.task = setTimeout(() => {
      this.wheel(this.deltaY)
      this.deltaY = 0
    }, 50)
  }

  wheel (offset: number) : void {
    global.requestAnimationFrame(() => {
      if (this.container.current) this.container.current.scrollLeft += offset
    })
  }

  render () : React.Node {
    const { active, links } = this.props
    return (
      <div className='modal-list' ref={this.container} onWheel={this.handleWheel}>{
        links.map((link, idx) => {
          const model = windowRegistry.getModel(link)
          if (!model) return null

          const isCollapsed = windowRegistry.isMinimized(link.id)
          const isFlashing = windowRegistry.isFlashing(link.id)

          return (
            <Indicator
              key={model.key}
              id={link.id}
              label={model.label}
              title={model.title}
              icon={model.icon}
              testName={model.testName}
              isFlashing={isFlashing}
              isCollapsed={isCollapsed}
              onClick={this.handleClick}
              isActive={link.id === active}
            />
          )
        })
      }
      </div>
    )
  }
}

export default ModalList
