/**
 * @file Indicator.js
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 18th April 2019 2:28:50 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { ID } from 'web-panel-essentials/types'
import type { asyncVoid } from 'web-panel/types'

import * as React from 'react'
import cn from 'classnames'
import { genTestName } from 'web-panel/globals'
import autoBind from 'react-autobind'
import { Icon } from 'web-panel/components'

type Props = {|
  id: ID,
  icon: string,
  label: string,
  testName?: string,
  title: string,
  isActive: boolean,
  isFlashing: boolean,
  isCollapsed: boolean,
  onClick: (id: ID) => asyncVoid
|}

class Indicator extends React.PureComponent<Props> {
  constructor () {
    super()
    autoBind(this)
  }

  handleClick () {
    const { id } = this.props
    this.props.onClick(id)
  }

  render () : React.Node {
    const { icon, label, title, isCollapsed, isFlashing, isActive, testName } = this.props
    return (
      <div
        data-title={title}
        onClick={this.handleClick}
        className={cn('modal-indicator', { isCollapsed, isActive, isFlashed: isFlashing && isCollapsed })}
        {...genTestName('footer-indicator', testName)}
      >
        <div className='icon-box'>
          <Icon className='half-right-margin' icon={icon || 'circle'} />
        </div>
        <div className='info-box'>
          <label>{label}</label>
          <span>{title || '>>>'}</span>
        </div>
      </div>
    )
  }
}

export default Indicator
