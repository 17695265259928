/**
 * @file InlineCalculator.js
 * @ignore
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Thursday, 25th November 2021 8:42:46 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow strict
 */
/* global SyntheticEvent, SyntheticKeyboardEvent, HTMLInputElement */
import type { ReactRef } from '../types'
import type { InlineCalculatorProps } from './types'

import * as React from 'react'
import { Row, Col } from '.'

import Portal from 'react-relative-portal'
import autoBind from 'react-autobind'
import { KEY } from 'web-panel-essentials/misc'
import Icon from './Icon'
import Action from './Action'
import { Parser } from 'expr-eval'
import { getCurrentLocale, __ } from 'web-panel/globals'
import isEmpty from 'lodash/isEmpty'

type State = {
  evaluated: ?number,
  formatted: string
}

class InlineCalculator extends React.PureComponent<InlineCalculatorProps, State> {
  formatter: Intl.NumberFormat
  input: ReactRef<'input'>

  constructor (props: InlineCalculatorProps) {
    super(props)
    autoBind(this)

    const { localeCode = getCurrentLocale(), ...formatterOptions } = props.formatterOptions || {}
    this.formatter = new Intl.NumberFormat(localeCode, formatterOptions)

    this.input = React.createRef()

    const [evaluated, formatted] = this.process(props.value)
    this.state = {
      evaluated,
      formatted
    }
  }

  componentDidMount () {
    this.input.current?.focus()
  }

  handleExpressionChange (event: SyntheticEvent<HTMLInputElement>) : void {
    const value = event.currentTarget.value
    const [evaluated, formatted] = this.process(value)
    this.setState({ evaluated, formatted })
  }

  process (value: string | number) : [?number, string] {
    const { limit } = this.props
    try {
      if (isEmpty(value)) return [null, '']

      const evaluated = Parser.evaluate(value)
      const formatted = this.formatter.format(evaluated)

      if (limit) {
        const [min, max] = limit
        if (evaluated > max) return [null, __('OUT_OF_RANGE_ERROR')]
        if (evaluated < min) return [null, __('OUT_OF_RANGE_ERROR')]
      }

      return [evaluated, formatted]
    } catch (e) {
      return [null, __('ERROR')]
    }
  }

  handleSubmit () : void {
    if (this.state.evaluated === null) return
    this.props.onDestroyRequested()
    this.props.onChange(this.state.evaluated)
  }

  handleBlur (event: SyntheticEvent<HTMLInputElement>) : void {
    const { currentTarget } = event
    // Give browser time to focus the next element
    // Check if the new focused
    // element is a child of the original container
    global.requestAnimationFrame(() => {
      if (!currentTarget.contains(document.activeElement)) {
        // Do blur logic here
        this.props.onDestroyRequested()
      }
    })
  }

  handleKeyDown (event: SyntheticKeyboardEvent<HTMLInputElement>) : void {
    const { keyCode } = event
    if (keyCode === KEY.ENTER) {
      this.handleSubmit()
      event.preventDefault()
      event.stopPropagation()
    }

    if (keyCode === KEY.ESCAPE) {
      this.props.onDestroyRequested()
      event.preventDefault()
      event.stopPropagation()
    }
  }

  render () : React.Node {
    const { value } = this.props
    const { formatted, evaluated } = this.state
    return (
      <Portal top={0} left={0} component='s'>
        <div className='inline-calculator' tabIndex={1} onBlur={this.handleBlur}>
          <Row className='v-a'>
            <div className='fake-display'>{formatted}</div>
          </Row>
          <Row className='v-a'>
            <Col size={1}>
              <Icon icon='equals' />
            </Col>
            <Col size={9}>
              <input
                type='text'
                ref={this.input}
                className='full-width'
                defaultValue={value}
                onKeyDown={this.handleKeyDown}
                onChange={this.handleExpressionChange}
              />
            </Col>
            <Col size={2}>
              <Action disabled={evaluated === null} icon='level-up-alt' onClick={this.handleSubmit} />
            </Col>
          </Row>
        </div>
      </Portal>
    )
  }
}

export default InlineCalculator
