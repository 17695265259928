/**
 * @file Action.js
 * @project Web-panel
 * @ignore
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 19th October 2021 8:54:01 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { ActionProps } from './types'

import * as React from 'react'
import Icon from './Icon'
import classnames from 'classnames'

/**
 * {@link Action} could be embeded into {@link Field} or used as standalone interactive component
 * @function
 * @example <caption>Import</caption>
 * import { Action } from 'web-panel/components'
 * @example <caption>Usage</caption>
 *
 * function (props: *) : React.Node {
 *    return (
 *      <Field displayValue={props.displayValue} onChange={props.handleChange}>
 *        <Action icon='reset' onClick={props.handleReset} />
 *      </Field>
 *    )
 * }
 */
export default function Action (props: ActionProps) : React.Node {
  const handleClick = () => {
    if (!props.disabled) props.onClick()
  }

  const classname = classnames('field-button', { disabled: props.disabled }, props.className)
  return (
    <div data-title={props.title} className={classname} onClick={handleClick}>
      <Icon tabIndex={-1} className='icon' icon={props.icon} />
    </div>
  )
}
