/**
 * @file Row.js
 * @ignore
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Wednesday, 7th October 2020 8:09:29 am
 * @copyright 2015 - 2020 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { RowProps } from './types'

import * as React from 'react'
import cn from 'classnames'

/**
 * {@link Row} is a base placement for line of {@link Col} items.
 * Has default margins and paddings
 *
 * @function
 * @example <caption>Import</caption>
 * import { Row } from 'web-panel/components'
 * @example <caption>Usage</caption>
 *
 * function Form(props: {}) : React.Node {
 *   return (
 *     <Row className='containter' noMargin>
 *        <Col size={8}>Hellow</Col>
 *        <Col size={4}>world</Col>
 *     </Row>
 *   )
 * }
 */
function Row (props: RowProps) : React.Node {
  let { className, noMargin, noPadding, children, style } = props
  className = cn('row', className, { 'no-margin': noMargin, 'no-padding': noPadding })
  return (<div className={className} style={style}>{children}</div>)
}

export default Row
