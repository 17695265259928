/**
 * @file Controls.js
 * @ignore
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Friday, 13th January 2023 9:06:32 am
 * @copyright 2015 - 2023 SKAT LLC, Delive LLC
 * @flow strict
 */
import type { Node } from 'react'
import type { MapChildrenProps } from 'web-panel/components/types'
import React from 'react'

type Props = {|
  ...MapChildrenProps,
  children: Node
|}

function MapControls (props: Props) : Node {
  return (
    <div className='map-controls' onMouseDown={(e) => e.stopPropagation()}>
      {props.children}
    </div>
  )
}

export default MapControls
