/**
 * @module serviceLocator
 * @ignore
 * @author Pavel Shabardin (<bigbn@mail.ru>) Wednesday, 30th September 2020 4:34:03 pm
 * @copyright 2015 - 2020 SKAT LLC, Delive LLC
 * @flow
 */
import type { ClassDescriptor, FieldDescriptor, iServiceLocator, ServiceLocatorBinding, ServiceLocatorMiddleware, ServiceLocatorInstance, Constructor } from './types'

import * as React from 'react'
import { __ } from './globals'
import { UnresolvedDependencyError } from './errors'
import { NotFoundError } from 'web-panel-essentials/errors'

class ProxyElementCreator {
  element: React.Element<any>
  constructor (element: React.ComponentType<any>, props: {}) {
    this.element = React.createElement(element, props)
  }
}

/**
 * The target object, access its dependencies (services) from a locator (registry),
 * that helps to search for the dependencies requested, and provide it to the
 * target object.
 * @ignore
 */
class ServiceLocator implements iServiceLocator {
  registry: {[string]: ServiceLocatorBinding}
  middlewareRegistry: {[string]: ServiceLocatorMiddleware<any>}

  constructor () {
    this.reset()
  }

  reset () : void {
    this.registry = {}
    this.middlewareRegistry = {}
  }

  register (serviceName: string, constructor: Constructor<any>, isSingleton: boolean, constructorProps: Array<mixed> | null = null) {
    this.registry[serviceName] = {
      constructor,
      constructorProps,
      isSingleton
    }
  }

  middleware (serviceName: string, handler: ServiceLocatorMiddleware<any>) {
    this.middlewareRegistry[serviceName] = handler
  }

  provide (serviceName: string) : ServiceLocatorInstance {
    const middleware : ServiceLocatorMiddleware<any> = this.middlewareRegistry[serviceName]
    const binding : ServiceLocatorBinding = this.registry[serviceName]
    const constructorProps = binding && binding.constructorProps ? binding.constructorProps : []
    if (binding) {
      if (binding.isSingleton && binding.instance) return binding.instance
      const rawInstance : ServiceLocatorInstance = new binding.constructor(...constructorProps)
      if (middleware) this.registry[serviceName].instance = middleware(rawInstance)
      else this.registry[serviceName].instance = rawInstance
      return this.registry[serviceName].instance
    } else {
      throw new UnresolvedDependencyError([
        __('UNRESOLVED_DEPENDENCY'),
        serviceName
      ].join(' '))
    }
  }
}

/**
 * @instance
 * @implements iServiceLocator
 */
const serviceLocator : ServiceLocator = new ServiceLocator()

/**
 * Mark class as injectable
 * @see https://github.com/tc39/proposal-decorators/blob/master/METAPROGRAMMING.md
 * @function
 * @example <caption>Import</caption>
 *
 * import { Injectable } from 'web-panel/serviceLocator'
 *
 * @example <caption>Usage</caption>
 *
 * `@Injectable('orderProcessor', true)`
 * class OrderProcessor {
 *    constructor () {
 *    }
 * }
 */
function Injectable (key: string, isSingleton:boolean = false) : (classDescriptor: ClassDescriptor) => ClassDescriptor {
  return function (classDescriptor: ClassDescriptor) : ClassDescriptor {
    const { kind, elements } = classDescriptor
    return {
      kind,
      elements,
      finisher (Class: Constructor<any>) {
        serviceLocator.register(key, Class, isSingleton)
      }
    }
  }
}

/**
 * Injects a dependency by speciefied name as property
 * @function
 * @example <caption>Import</caption>
 *
 * import { Inject } from 'web-panel/serviceLocator'
 *
 * @example <caption>Usage</caption>
 *
 * class Foo {
 *    `@Inject logger: iLogger`
 *    `@Inject windowManager : iWindowManager`
 *
 *    constructor() {
 *      this.logger.info('Bar')
 *    }
 * }
 */
function Inject (descriptor: FieldDescriptor) : FieldDescriptor {
  return Object.assign({}, descriptor, {
    initializer: () => serviceLocator.provide(descriptor.key)
  })
}

/**
 * Injects an optional dependency, it may be `undefined`
 * and will no throw any error when dependency is not satisfied
 * @function
 * @example <caption>Import</caption>
 *
 * import { Optional } from 'web-panel/serviceLocator'
 *
 * @example <caption>Usage</caption>
 *
 * class Foo {
 *    `@Optional logger: ?iLogger`
 *
 *    constructor() {
 *      if (this.logger) this.logger.info('Bar')
 *    }
 * }
 */
function Optional (descriptor: FieldDescriptor) : FieldDescriptor {
  return Object.assign({}, descriptor, {
    initializer: () => {
      try {
        const service = serviceLocator.provide(descriptor.key)
        return service
      } catch (e) {
        console.warn(e)
        return null
      }
    }
  })
}

function InjectableView (key: string, isSingleton:boolean = false) : Function {
  return function (classDescriptor: ClassDescriptor) {
    const { kind, elements } = classDescriptor
    return {
      kind,
      elements,
      finisher (Class: Constructor<ProxyElementCreator>) {
        const middleware : ServiceLocatorMiddleware<{element: React.Element<any>}> = (instance: ServiceLocatorInstance) => {
          if (instance.element) return instance.element
          throw new NotFoundError()
        }
        serviceLocator.middleware(key, middleware)
        serviceLocator.register(key, ProxyElementCreator, isSingleton, [Class])
      }
    }
  }
}

export { Injectable, InjectableView, Inject, Optional, UnresolvedDependencyError, ServiceLocator }
export default serviceLocator
